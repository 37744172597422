import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { _IdNamePair, OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type WorkoutOptions = { categoryOptions: OptionData<string>[] };

const resourceLoader: ResourceLoaderFn<WorkoutOptions> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/workout/init', {
      signal: args?.abortSignal,
    });
    const { workout_group } = data?.data!;
    const categoryOptions = workout_group.map((option: _IdNamePair) => ({
      id: option._id,
      data: option.name,
    }));
    return {
      success: data.success,
      data: {
        categoryOptions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useWorkoutDropDownOptions = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource } = useRemoteResource<WorkoutOptions>({
    resourceName: 'workoutOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });
  return {
    isOptionsLoading: status === 'IN_PROGRESS',
    categoryOptions: resource?.categoryOptions || EMPTY_ARRAY,
  };
};
export default useWorkoutDropDownOptions;
