import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import ForgetPassword from 'modules/auth/forgetpassword/ForgetPassword';
import LoginPage from 'modules/auth/LoginPage';

function PublicRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/reset-password-success" element={<LoginPage />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
      </Routes>
    </Router>
  );
}
export default PublicRoutes;
