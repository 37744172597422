import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as CaretDownIcon } from 'assets/icons/arrowCaretDown.svg';
import clsx from 'clsx';
import Popup from 'common/ui/Popup';

function LibraryMenu() {
  const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLElement>();
  const closeMenu = () => {
    setAnchorEl(undefined);
  };
  return (
    <>
      <button
        className={clsx('flex items-center linkButton font-normal', anchorEl && 'text-teal-normal')}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <p>Library</p>
        <div className="h-4 w-4 ml-1">
          <CaretDownIcon />
        </div>
      </button>
      <Popup anchorEl={anchorEl} onClose={closeMenu} placement="bottom">
        <ul className="menu py-3 w-40">
          <li>
            <NavLink to="/library/streams" className="item" onClick={closeMenu}>
              <p className="ml-3">Programs</p>
            </NavLink>
          </li>
          <li>
            <NavLink to="/library/workouts" className="item" onClick={closeMenu}>
              <p className="ml-3">Workouts</p>
            </NavLink>
          </li>
          <li>
            <NavLink to="/library/exercises" className="item" onClick={closeMenu}>
              <p className="ml-3">Exercises</p>
            </NavLink>
          </li>
        </ul>
      </Popup>
    </>
  );
}

export default LibraryMenu;
