import { _IdNamePair } from 'common/types';
import * as Z from 'zod';

export const StreamFormSchema = Z.object({
  stream_data: Z.object({
    athelete_type: Z.string().trim().min(1, { message: 'Select stream type' }),
    workout_group: Z.string().trim().min(1, { message: 'Select workout type' }),
    description: Z.string().optional(),
    difficulty_level: Z.enum(['novice', 'intermediate', 'advanced', 'N/A'], {
      required_error: 'Select difficulty level.',
    }),
    image: Z.union([Z.string(), Z.instanceof(File)]).nullable(),
  }),
});
export type StreamFormData = Z.infer<typeof StreamFormSchema>;

export interface StreamModel {
  _id: string;
  is_draft: boolean;
  athelete_type: _IdNamePair;
  workout_group: _IdNamePair;
  description: string;
  difficulty_level: StreamFormData['stream_data']['difficulty_level'];
  programs: number;
  image: string;
  units: number;
  updated_at: string;
}
