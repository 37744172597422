import { ReactComponent as CloseRoundedFilled } from 'assets/icons/closeRoundedFilled.svg';
import { videoMimeTypes } from 'common/constants';

import DropTarget from './DropTarget';

export interface VideoFileChangeEvent {
  target: {
    name: string;
    value: File | undefined;
  };
}

interface VideoFileControlProps {
  name: string;
  file?: File;
  videoURL?: string | null;
  onChange?: (event: VideoFileChangeEvent) => void;
}

function VideoFileControl(props: VideoFileControlProps) {
  const { name, file, videoURL, onChange } = props;
  const handleOnChange = (files: File[]) => {
    if (onChange) {
      onChange({ target: { name, value: files.length > 0 ? files[0] : undefined } });
    }
  };
  const removeFile = () => {
    if (onChange) {
      onChange({ target: { name, value: undefined } });
    }
  };

  if (!file && !videoURL) {
    return (
      <DropTarget
        className="px-4 py-3"
        onChange={handleOnChange}
        allowedMimetypes={videoMimeTypes}
      />
    );
  }
  const videoSource = file ? URL.createObjectURL(file) : videoURL || '';
  return (
    <div className="relative bg-black h-44">
      <video controls className="h-full w-full">
        <source src={videoSource} />
      </video>
      <button
        type="button"
        className="iconButton absolute -top-1 -right-1 bg-white rounded-full"
        onClick={removeFile}
      >
        <CloseRoundedFilled />
      </button>
    </div>
  );
}

export default VideoFileControl;
