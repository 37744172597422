import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { _IdNamePair, OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type ExerciseOptions = {
  focusOptions: OptionData<string>[];
  muscleGroupOptions: OptionData<string>[];
  equipmentRequiredOptions: OptionData<string>[];
};

const resourceLoader: ResourceLoaderFn<ExerciseOptions> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/exercise/init', {
      signal: args?.abortSignal,
    });
    const { focus, primary_muscle_group, equipment } = data?.data!;
    const focusOptions = focus.map((option: _IdNamePair) => ({
      id: option._id,
      data: option.name,
    }));
    const muscleGroupOptions = primary_muscle_group?.map((option: _IdNamePair) => ({
      id: option._id,
      data: option.name,
    }));
    const equipmentRequiredOptions = equipment.map((option: _IdNamePair) => ({
      id: option._id,
      data: option.name,
    }));
    return {
      success: data.success,
      data: {
        focusOptions,
        muscleGroupOptions,
        equipmentRequiredOptions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useExerciseDropDownOptions = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource } = useRemoteResource<ExerciseOptions>({
    resourceName: 'exerciseOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });
  return {
    isOptionsLoading: status === 'IN_PROGRESS',
    status,
    focusOptions: resource?.focusOptions || EMPTY_ARRAY,
    muscleGroupOptions: resource?.muscleGroupOptions || EMPTY_ARRAY,
    equipmentRequiredOptions: resource?.equipmentRequiredOptions || EMPTY_ARRAY,
  };
};

export default useExerciseDropDownOptions;
