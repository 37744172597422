import { athleteDifficultyLevelLabel } from 'common/constants';
import { AthleteLevel } from 'common/types';
import { range } from 'common/utils/general';

export const difficultyMultiplierOptions = [
  { id: 0, data: 'N/A' },
  ...range(0.05, 2, 0.05).map((val) => ({ id: val, data: `${val}` })),
];

export const athleteLevelOptions: { id: AthleteLevel; data: string }[] = [
  { id: 'novice', data: athleteDifficultyLevelLabel.novice },
  { id: 'intermediate', data: athleteDifficultyLevelLabel.intermediate },
  { id: 'advanced', data: athleteDifficultyLevelLabel.advanced },
];
