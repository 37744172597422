import './Dashboard.css';

import Throbber from 'common/ui/Throbber';

import DashBoardMetricCard from './DashBoardMetricCard';
import PopularWorkouts from './PopularWorkouts';
import Statistics from './Statistics';
import Streams from './Streams';
import useDashboardMetrics from './useDashboardMetrics';

function DashboardPage() {
  const { isMetricsLoading, workoutCompleted, newUsersCount, subscribedUsers } =
    useDashboardMetrics({
      autoLoad: true,
      autoRemove: true,
    });
  return (
    <div className="h-appContent overflow-y-auto relative">
      <div className="contentArea">
        <div className="flex items-center h-pageHeader">
          <h1 className="flex-1 font-semibold text-2xl">Dashboard</h1>
        </div>
        <div className="dashboardContent p-6">
          <div className="flex gap-6">
            <DashBoardMetricCard metricName="Total workouts completed" value={workoutCompleted} />
            <DashBoardMetricCard metricName="Active users" value={newUsersCount} />
            <DashBoardMetricCard metricName="New users" value={subscribedUsers} />
          </div>
          <div className="flex px-8 py-5 bg-white mt-6">
            <Statistics />
            <PopularWorkouts />
          </div>
          <div className="flex px-8 py-5 bg-white mt-6">
            <Streams />
          </div>
        </div>
      </div>
      {isMetricsLoading && <Throbber withMask />}
    </div>
  );
}

export default DashboardPage;
