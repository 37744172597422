import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevronLeft.svg';
import { APICallState } from 'common/types';
import Throbber from 'common/ui/Throbber';
import { EMAxios } from 'common/utils/EMService';

import LoginPageLayout from '../LoginPageLayout';

import { ForgetPasswordData } from './forgetpasswordTypes';

interface EnterCodeFormProps {
  emailId: string;
  onEnterCode: (params: Pick<ForgetPasswordData, 'code'>) => void;
}

interface CodeFormData {
  codeDigit1: number;
  codeDigit2: number;
  codeDigit3: number;
  codeDigit4: number;
}

function EnterCodeForm(props: EnterCodeFormProps) {
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm<CodeFormData>({ mode: 'onChange' });

  const [formState, setFormState] = React.useState<APICallState>();

  const naviagte = useNavigate();
  const maskedEmailId = ['******', props.emailId.split('@')[1]].join('@');

  const handleCodeSubmit = async (data: CodeFormData) => {
    setFormState({ status: 'IN_PROGRESS' });
    try {
      const code = [data.codeDigit1, data.codeDigit2, data.codeDigit3, data.codeDigit4].join('');
      await EMAxios.post('/api/verify-code', { email: props.emailId, code });
      props.onEnterCode({ code });
    } catch (e) {
      setFormState({ status: 'ERROR' });
    }
  };

  return (
    <LoginPageLayout>
      <div className="w-[450px] lg:ml-32 relative">
        <button
          className="linkButton text-lg font-medium flex items-center mb-4"
          tabIndex={-1}
          onClick={() => naviagte(-1)}
        >
          <ChevronLeftIcon className="mr-2" />
          <span>Back</span>
        </button>
        <div className="bg-white px-8 py-11">
          <form noValidate autoComplete="off" onSubmit={handleSubmit(handleCodeSubmit)}>
            <h1 className="text-3xl mb-6 font-semibold">Verify Email</h1>
            <h3 className="font-normal mb-6">
              Please enter the confirmation code we sent to {maskedEmailId}
            </h3>
            <div className="flex justify-evenly mb-10">
              <input
                className="w-16 text-center"
                type="text"
                maxLength={1}
                {...register('codeDigit1', { required: true })}
              />
              <input
                className="w-16 text-center"
                type="text"
                maxLength={1}
                {...register('codeDigit2', { required: true })}
              />
              <input
                className="w-16 text-center"
                type="text"
                maxLength={1}
                {...register('codeDigit3', { required: true })}
              />
              <input
                className="w-16 text-center"
                type="text"
                maxLength={1}
                {...register('codeDigit4', { required: true })}
              />
            </div>
            <button className="primary w-full uppercase mb-8" type="submit" disabled={!isValid}>
              SUBMIT
            </button>
            <div className="flex items-center justify-center">
              <p>
                Didn't receive the code?{' '}
                <button className="font-semibold linkButton">Resent</button>
              </p>
            </div>
            {formState?.status === 'IN_PROGRESS' && <Throbber withMask />}
          </form>
        </div>
      </div>
    </LoginPageLayout>
  );
}

export default EnterCodeForm;
