import { useRef, useState } from 'react';
import { APICallState } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

const initialState: APICallState = { status: 'INIT' };

export function useSaveResource<Payload>(urcArgs: { url?: string; updateURL?: string }) {
  const { url, updateURL } = urcArgs;
  const [saveState, setSaveState] = useState<APICallState>(initialState);
  const saveSuccessCountRef = useRef(0);

  const getSaveURL = (resourceId?: string) => {
    if (resourceId) {
      if (updateURL) {
        return updateURL.replaceAll(':resourceId', resourceId);
      } else if (url) {
        return `${url}/${resourceId}`;
      }
    }
    return url;
  };

  const saveResource = async (saveArgs: {
    payload: Payload;
    resourceId?: string;
    method?: string;
  }) => {
    const { payload, method, resourceId } = saveArgs;
    const requestURL = getSaveURL(resourceId);
    if (!requestURL) {
      throw new Error('NO_URL');
    }
    const requestMethod = method || resourceId ? 'PUT' : 'POST';
    try {
      const { data } = await EMAxios.request({
        url: requestURL,
        method: requestMethod,
        data: payload,
      });
      return data;
    } catch (e) {
      return false;
    }
  };

  const resetSaveState = (duration: number) => {
    if (duration > 0) {
      setTimeout(() => {
        setSaveState({ status: 'INIT' });
      }, duration);
    } else {
      setSaveState({ status: 'INIT' });
    }
  };

  const setSaveInProgress = () => {
    setSaveState({
      status: 'IN_PROGRESS',
    });
  };

  const setSaveSuccess = (message?: string, duration = 5000) => {
    setSaveState({
      status: 'SUCCESS',
      message,
    });
    saveSuccessCountRef.current += 1;
    resetSaveState(duration);
  };

  const setSaveError = (message?: string, duration = 5000) => {
    setSaveState({
      status: 'ERROR',
      message,
    });
    resetSaveState(duration);
  };

  const resetSuccessCount = () => {
    saveSuccessCountRef.current = 0;
  };
  const getSuccessCount = () => {
    return saveSuccessCountRef.current;
  };

  return {
    isSaveIP: saveState.status === 'IN_PROGRESS',
    isSaveError: saveState.status === 'ERROR',
    saveMessage: saveState.message,
    saveResource,
    setSaveInProgress,
    setSaveSuccess,
    setSaveError,
    getSuccessCount,
    resetSuccessCount,
  };
}

export function useDeleteResource(config: { url?: string; deleteURL?: string }) {
  const { url, deleteURL } = config;
  const [deleteState, setDeleteState] = useState<APICallState>(initialState);

  const getDeleteURL = (resourceId: string) => {
    if (deleteURL) {
      return deleteURL.replaceAll(':resourceId', resourceId);
    } else if (url) {
      return `${url}/${resourceId}`;
    }
    return '';
  };

  const deleteResource = async (resourceId: string, method?: string) => {
    const requestURL = getDeleteURL(resourceId);
    if (requestURL.length === 0) {
      throw new Error('NO_URL');
    }

    try {
      const { data } = await EMAxios.request({
        url: requestURL,
        method: method || 'DELETE',
      });
      return data;
    } catch (e) {
      return false;
    }
  };

  const resetDeleteState = (duration: number) => {
    if (duration > 0) {
      setTimeout(() => {
        setDeleteState({ status: 'INIT' });
      }, duration);
    } else {
      setDeleteState({ status: 'INIT' });
    }
  };

  const setDeleteInProgress = () => {
    setDeleteState({
      status: 'IN_PROGRESS',
    });
  };

  const setDeleteSuccess = (message?: string, duration = 5000) => {
    setDeleteState({
      status: 'SUCCESS',
      message,
    });
    resetDeleteState(duration);
  };

  const setDeleteError = (message?: string, duration = 5000) => {
    setDeleteState({
      status: 'ERROR',
      message,
    });
    resetDeleteState(duration);
  };

  return {
    isDeleteIP: deleteState.status === 'IN_PROGRESS',
    isDeleteError: deleteState.status === 'ERROR',
    deleteResource,
    setDeleteInProgress,
    setDeleteSuccess,
    setDeleteError,
  };
}
