import { ReactComponent as EyeOnOutlineIcon } from 'assets/icons/eyeOnOutline.svg';
import { ReactComponent as MoreHorizontalIcon } from 'assets/icons/moreHorizontal.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { useDeleteResource } from 'common/remoteResource/resourceCUD';
import {
  ActionColumn,
  Column,
  Content,
  DataTable,
  DataTableHeader,
  DataTableHelperInfo,
  DataTableRow,
  HeaderColumn,
  Pager,
} from 'common/ui/DataTable';
import Menu, { MenuProps } from 'common/ui/Menu';
import Throbber from 'common/ui/Throbber';

import useAthleteList from './DAL/useAthleteList';
import { AthleteModel } from './types';

interface AthleteListProps {
  onClickDataRow: (exercise: AthleteModel) => void;
}

const RowActions: MenuProps['items'] = [
  { name: 'editDetails', display: 'Edit Details', iconLeft: <EyeOnOutlineIcon /> },
  {
    name: 'remove',
    display: 'Remove',
    iconLeft: <TrashIcon />,
    confirm: 'Are you sure to delete this Athlete?',
  },
];

function AthleteList(props: AthleteListProps) {
  const {
    isLoadingAth,
    status,
    items,
    filters: { page, perPage, sortByColumn, sortOrder },
    totalCount,
    loadAthletes,
    refreshData,
    moveToPage,
  } = useAthleteList({ autoLoad: true });
  const { deleteResource, setDeleteInProgress, setDeleteSuccess, setDeleteError, isDeleteIP } =
    useDeleteResource({ url: '/api/admin/athelete' });

  const handleColumnSort = (name: string, currentSort = 'desc') => {
    loadAthletes({ sortByColumn: name, sortOrder: currentSort === 'desc' ? 'asc' : 'desc' });
  };
  const handleClickDataRow = (athlete: AthleteModel) => {
    props.onClickDataRow(athlete);
  };

  const handleDelete = async (athleteId: string) => {
    setDeleteInProgress();
    const result = await deleteResource(athleteId);
    if (result) {
      await loadAthletes();
      setDeleteSuccess();
    } else {
      setDeleteError('Unable to delete athlete');
    }
  };
  const handleMenuAction = (actionName: string, menuData: AthleteModel) => {
    switch (actionName) {
      case 'editDetails':
        props.onClickDataRow(menuData);
        break;
      case 'remove':
        handleDelete(menuData._id);
        break;
      default:
        break;
    }
  };
  return (
    <DataTable className="bg-gray-50 relative">
      <Content>
        <DataTableHeader className="bg-gray-normal text-white text-sm font-medium ">
          <HeaderColumn
            name="first_name"
            sortOrder={sortByColumn === 'first_name' ? sortOrder : undefined}
            onClick={handleColumnSort}
          >
            First Name
          </HeaderColumn>
          <HeaderColumn
            name="last_name"
            sortOrder={sortByColumn === 'last_name' ? sortOrder : undefined}
            onClick={handleColumnSort}
          >
            Last Name
          </HeaderColumn>
          <HeaderColumn>Email</HeaderColumn>
          <HeaderColumn>Athlete Type</HeaderColumn>
          <HeaderColumn>Workout Completed</HeaderColumn>
          <HeaderColumn>Active Program</HeaderColumn>
          <ActionColumn />
        </DataTableHeader>
        <DataTableHelperInfo status={status} items={items} refreshData={refreshData} />
        {items.map((athlete) => (
          <DataTableRow
            key={athlete._id}
            rowData={athlete}
            className="py-2 border-b border-b-gray-light"
            onClick={handleClickDataRow}
          >
            <Column>{athlete.first_name}</Column>
            <Column>{athlete.last_name}</Column>
            <Column className="break-all">{athlete.email}</Column>
            <Column>{athlete.athelete_type?.name}</Column>
            <Column className="justify-center">{athlete.workout_completed}</Column>
            <Column>{athlete.active_program || '-'}</Column>
            <ActionColumn>
              <Menu
                triggerElement={<MoreHorizontalIcon />}
                items={RowActions}
                menuData={athlete}
                onItemClick={handleMenuAction}
              />
            </ActionColumn>
          </DataTableRow>
        ))}
      </Content>
      <Pager
        curPage={page}
        pageSize={perPage}
        totalCount={totalCount}
        isLoading={isLoadingAth}
        moveToPage={moveToPage}
      />
      {isLoadingAth || isDeleteIP ? <Throbber withMask /> : null}
    </DataTable>
  );
}

export default AthleteList;
