import { NavLink } from 'react-router-dom';
import { GetNavLinkClassName } from 'common/types';

interface SettingsNavProps {
  basePath: string;
}

function SettingsNav(props: SettingsNavProps) {
  const { basePath } = props;

  const getNavlinkClassNames: GetNavLinkClassName = ({ isActive }) => {
    const baseClasses = 'flex items-start pb-2 justify-center w-48 border-b-2 ';
    return isActive
      ? `${baseClasses} border-b-teal-normal font-semibold text-teal-normal`
      : baseClasses;
  };
  return (
    <ul className="tab mt-6 mb-8 text-gray-normal text-base">
      <li>
        <NavLink
          to={`${basePath}/privacy-policy`}
          className={getNavlinkClassNames}
        >
          Privacy Policy
        </NavLink>
      </li>
      <li>
        <NavLink
          to={`${basePath}/terms-conditions`}
          className={getNavlinkClassNames}
        >
          Terms &amp; Conditions
        </NavLink>
      </li>
    </ul>
  );
}

export default SettingsNav;
