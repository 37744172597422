import React from 'react';

import { UserData } from '../../../../modules/programs/createupdate/useUsersDropDownOptions';
import { OptionData } from '../types';

import MultiSelect, { MultiSelectProps } from './MultiSelect';

export function SimpleMultiUserSelect(
  props: Omit<MultiSelectProps<UserData>, 'renderItem' | 'renderSelected'>
) {
  const renderItem = (value: UserData, checked: boolean) => (
    <div className="flex items-center py-4 px-3">
      <input type="checkbox" readOnly checked={checked} tabIndex={-1} className="mr-3 w-5 h-5" />
      <p>
        {value.firstName} {value.lastName} ({value.email})
      </p>
    </div>
  );
  const renderSelected = (selectedValues: OptionData<UserData>[]) => (
    <>
      <p className="whitespace-nowrap text-ellipsis overflow-hidden">
        {selectedValues
          .map(
            (option) => `${option.data.firstName} ${option.data.lastName} (${option.data.email})`
          )
          .join(', ')}
      </p>
    </>
  );
  return (
    <div className="relative">
      <MultiSelect {...props} renderItem={renderItem} renderSelected={renderSelected} />
    </div>
  );
}
