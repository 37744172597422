import { useParams } from 'react-router-dom';
import { EMPTY_ARRAY } from 'common/constants';
import useAppNaviagte from 'common/hooks/useAppNaviagate';
import useMultiPageForm from 'common/hooks/useMultiPageForm';
import { useSaveResource } from 'common/remoteResource/resourceCUD';
import Throbber from 'common/ui/Throbber';
import { uploadFile } from 'common/utils/general';
import useExerciseDropDownOptions from 'modules/exercises/DAL/useExerciseDropDownOptions';

import {
  ProgramBuildFormData,
  ProgramDetailsFormData,
  ProgramFormData,
  SaveProgramPayload,
} from '../types';
import usePrograms from '../usePrograms';

import FormHeader from './FormHeader';
import ProgramBuildForm from './ProgramBuildForm';
import ProgramDetailsForm from './ProgramDetailsForm';
import useDisplayTagsDropDownOptions from './useDisplayTagsDropDownOptions';
import useProgramDropDownOptions from './useProgramDropDownOptions';
import { useUsersDropDownOptions } from './useUsersDropDownOptions';
import useAddOnDropDownOptions from "./useAddOnPurchaseIDDropDownOptions";

const blankFormData: ProgramFormData = {
  details: {
    image_overlay: null,
    title_hidden: false,
    image: null,
    name: '',
    description: '',
    equipments: EMPTY_ARRAY,
    next_program_id: '',
    display_tags: EMPTY_ARRAY,
    user_access: EMPTY_ARRAY,
    addon_purchase_id: null,
    type: 'quick',
  },
  build: {
    name: '',
    workouts: EMPTY_ARRAY,
  },
};

interface ProgramFormProps {
  defaultFormData?: ProgramFormData;
  programId?: string;
}

function ProgramForm(props: ProgramFormProps) {
  const { streamId = '' } = useParams();
  const { defaultFormData = blankFormData, programId } = props;
  const { navigate } = useAppNaviagte();
  const { currentSection, formData, setCurrentSection, setFormData } =
    useMultiPageForm<ProgramFormData>(defaultFormData, 'details');
  const { loadPrograms } = usePrograms({ streamId });
  const { isOptionsLoading, equipmentRequiredOptions } = useExerciseDropDownOptions({
    autoLoad: true,
  });
  const { isAddOnLoading, addonPurchaseIdOptions } = useAddOnDropDownOptions({
    autoLoad: true,
  });
  const { isProgramOptionsLoading, programs } = useProgramDropDownOptions({
    autoLoad: true,
  });
  const { isDisplayTagsOptionsLoading, tags, loadData } = useDisplayTagsDropDownOptions({
    autoLoad: true,
  });
  const { isUsersOptionsLoading, users } = useUsersDropDownOptions({
    autoLoad: true,
  });
  let filteredUsers = users;
  const {
    isSaveIP,
    isSaveError,
    saveMessage,
    setSaveInProgress,
    setSaveSuccess,
    setSaveError,
    saveResource,
  } = useSaveResource({
    url: `/api/admin/stream/${streamId}/programs`,
    updateURL: '/api/admin/program/:resourceId',
  });
  const { saveResource: saveDisplayTagsResource } = useSaveResource({
    url: `/api/admin/program/tags`,
  });

  const gotoBuildSection = (detailsFormData: ProgramDetailsFormData) => {
    setFormData('details', detailsFormData);
    setFormData('build', { name: detailsFormData.name });
    setCurrentSection('build');
  };

  const gotoDetailsSection = (buildFormdata: ProgramBuildFormData) => {
    setFormData('build', buildFormdata);
    setFormData('details', { name: buildFormdata.name });
    setCurrentSection('details');
  };

  const handleSaveProgram = async (programFormData: ProgramFormData, is_draft: boolean = false) => {
    setSaveInProgress();
    const {
      details: {
        user_access,
        display_tags,
        name,
        description,
        equipments,
        image,
        image_overlay,
        title_hidden,
        type,
        next_program_id,
        addon_purchase_id,
      },
      build,
    } = programFormData;
    const imageURL = await uploadFile(image);
    const imageOverlayURL = await uploadFile(image_overlay);
    const programPayload: SaveProgramPayload = {
      program_data: {
        name,
        description,
        equipments,
        next_program_id,
        user_access,
        addon_purchase_id,
        display_tags,
        image: imageURL,
        image_overlay: imageOverlayURL,
        title_hidden,
        is_draft,
        workouts: build.workouts.map((wo) => wo._id),
        type,
      },
    };
    const result = await saveResource({ payload: programPayload, resourceId: programId });
    if (result) {
      setSaveSuccess('Program saved successfully.');
      loadPrograms({ page: 1 });
      navigate(`/library/streams/${streamId}/programs`);
    } else {
      setSaveError('Unable to save Program.');
      if (imageURL !== null) {
        setFormData('details', { image: imageURL });
      }
      if (imageOverlayURL !== null) {
        setFormData('details', { image_overlay: imageOverlayURL });
      }
    }
  };

  const onSaveDraftFromDetails = (detailsFromData: ProgramDetailsFormData) => {
    setFormData('details', detailsFromData);
    const programFormData = {
      details: detailsFromData,
      build: formData.build,
    };
    return handleSaveProgram(programFormData, true);
  };
  const onSaveDraftFromBuild = (buildFormdata: ProgramBuildFormData) => {
    setFormData('build', buildFormdata);
    const programFormData = {
      details: formData.details,
      build: buildFormdata,
    };
    return handleSaveProgram(programFormData, true);
  };

  const onPublishProgram = async (buildFormdata: ProgramBuildFormData) => {
    setFormData('build', buildFormdata);
    const programFormData = {
      details: formData.details,
      build: buildFormdata,
    };
    return handleSaveProgram(programFormData);
  };
  const addItemToDisplayOptions = async (item: string) => {
    await saveDisplayTagsResource({ payload: { name: item }, method: 'PUT' });
    await loadData();
  };
  const filterUsersOptions = (searchString: string) => {};

  if (!streamId) {
    return null;
  }

  return (
    <div className="overflow-hidden h-appContent relative">
      <div className="contentArea">
        <FormHeader
          streamId={streamId}
          currentSection={currentSection}
          isError={isSaveError}
          message={saveMessage}
        />
        {currentSection === 'details' ? (
          <ProgramDetailsForm
            equipmentRequiredOptions={equipmentRequiredOptions}
            addonPurchaseIdOptions={addonPurchaseIdOptions}
            nextPrograms={programs}
            displayTagsOptions={tags}
            userOptions={filteredUsers}
            onSaveDraft={onSaveDraftFromDetails}
            goNextSection={gotoBuildSection}
            defaultValues={formData.details}
            addItemToDisplayOptions={addItemToDisplayOptions}
            filterUsersOptions={filterUsersOptions}
          />
        ) : (
          <ProgramBuildForm
            goPreviousSection={gotoDetailsSection}
            onPublishProgram={onPublishProgram}
            onSaveDraft={onSaveDraftFromBuild}
            defaultValues={formData.build}
          />
        )}
        {isSaveIP ||
        isOptionsLoading ||
        isProgramOptionsLoading ||
        isDisplayTagsOptionsLoading ||
        isAddOnLoading ||
        isUsersOptionsLoading ? (
          <Throbber withMask />
        ) : null}
      </div>
    </div>
  );
}

export default ProgramForm;
