import { format, parseISO } from 'date-fns';

export const formatISODate = (isoDateString: string, dateFormat: string = 'dd/MM/yyyy') => {
  const date = parseISO(isoDateString);
  if (date) {
    return format(date, dateFormat);
  } else {
    return '-';
  }
};
