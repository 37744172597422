import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { _IdNamePair, OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

export type StreamOptions = {
  athleteTypeOptions: OptionData<string>[];
  workoutGroupOptions: OptionData<string>[];
};

const resourceLoader: ResourceLoaderFn<StreamOptions> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/stream/init', {
      signal: args?.abortSignal,
    });
    const { athelete_type, workout_group } = data?.data!;
    const athleteTypeOptions = athelete_type.map((option: _IdNamePair) => ({
      id: option._id,
      data: option.name,
    }));
    const workoutGroupOptions = workout_group?.map((option: _IdNamePair) => ({
      id: option._id,
      data: option.name,
    }));
    return {
      success: data.success,
      data: {
        athleteTypeOptions,
        workoutGroupOptions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useStreamOptions = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource } = useRemoteResource<StreamOptions>({
    resourceName: 'streamOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });
  return {
    isOptionsLoading: status === 'IN_PROGRESS',
    athleteTypeOptions: resource?.athleteTypeOptions || EMPTY_ARRAY,
    workoutGroupOptions: resource?.workoutGroupOptions || EMPTY_ARRAY,
  };
};
export default useStreamOptions;
