import React from 'react';
import { ReactComponent as CaretDownIcon } from 'assets/icons/caretDown.svg';
import clsx from 'clsx';

import Popup, { usePopupConfig } from '../../Popup';
import { FormCompEvent, OptionData } from '../types';

import { DropDownOption } from './utils';

export interface SingleSelectProps<T> {
  containerProps?: React.HTMLProps<HTMLDivElement>;
  className?: string;
  placeHolder?: string;
  options: OptionData<T>[];
  name: string;
  value: string | number | null;
  onChange: (event: FormCompEvent) => void;
  renderItem: (param: T, isSelected: boolean) => React.ReactNode;
  renderSelected: (param: OptionData<T>) => React.ReactNode;
}

export function SingleSelect<T>(props: SingleSelectProps<T>) {
  const {
    options,
    name,
    value,
    placeHolder = 'Select option...',
    containerProps,
    className,
    onChange,
    renderItem,
    renderSelected,
  } = props;

  const { anchorEl, showOptions, closeOptions } = usePopupConfig<HTMLDivElement>();
  const [selectedValue, setSelectedValue] = React.useState<OptionData<T>>();

  React.useEffect(() => {
    setSelectedValue(options.find((option) => option.id === value));
  }, [value, options]);

  const openDropDownMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (options.length > 0) {
      showOptions(event);
    }
  };
  const handleOptionClick = (nextSelectedValue: OptionData<T>, isSelected: boolean) => {
    setSelectedValue(nextSelectedValue);
    if (onChange) {
      onChange({ target: { name, value: nextSelectedValue.id } });
    }
    closeOptions();
  };

  return (
    <>
      <div
        className={clsx('selectContainer', className)}
        onClick={openDropDownMenu}
        {...containerProps}
      >
        <div className="mr-6 overflow-hidden">
          {selectedValue ? (
            renderSelected(selectedValue)
          ) : (
            <span className="text-gray-500">{placeHolder}</span>
          )}
        </div>
        <div className="absolute right-1 top-1/2 -translate-y-1/2">
          <CaretDownIcon />
        </div>
      </div>

      <Popup
        anchorEl={anchorEl}
        onClose={closeOptions}
        className="border border-gray-light_active"
        placement="bottom-start"
      >
        <ul className="bg-white shadow-lg selectMenu">
          {options.map((option) => {
            const isSelected = selectedValue?.id === option.id;
            return (
              <DropDownOption
                key={option.id}
                handleOptionClick={handleOptionClick}
                option={option}
                isSelected={isSelected}
              >
                {renderItem(option.data, isSelected)}
              </DropDownOption>
            );
          })}
        </ul>
      </Popup>
    </>
  );
}

export default SingleSelect;

export function SimpleSingleSelect(
  props: Omit<SingleSelectProps<string | number>, 'renderItem' | 'renderSelected'>
) {
  const renderItem = (value: string | number, checked: boolean) => (
    <div className={clsx('flex items-center py-4 px-3 w-full', checked && 'bg-teal-light_active')}>
      <p>{value}</p>
    </div>
  );
  const renderSelected = (selectedValue: OptionData<string | number>) => (
    <p className="whitespace-nowrap text-ellipsis overflow-hidden">{selectedValue.data}</p>
  );
  return <SingleSelect {...props} renderItem={renderItem} renderSelected={renderSelected} />;
}
