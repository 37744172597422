import Modal, { ModalProps } from 'common/ui/Modal';

import { WorkoutExerciseFormData } from '../types';

interface ExerciseDetailModalProps {
  isModalOpen: boolean;
  exercise?: WorkoutExerciseFormData;
  onClose: ModalProps['onClose'];
}

function ExerciseDetailModal(props: ExerciseDetailModalProps) {
  const { isModalOpen, exercise, onClose } = props;
  if (!exercise) {
    return null;
  }
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onClose}
      className="w-modal-md shadow-lg"
      closeButtonGutter="-right-2 -top-2 rounded-full bg-black text-white"
    >
      <video controls className="w-full bg-black">
        {exercise.exercise_video ? <source src={exercise.exercise_video} /> : 'No video'}
      </video>
      <div className="p-4">
        <div className="flex mb-2">
          <h3 className="flex-1 font-semibold text-lg">{exercise.exercise_name}</h3>
        </div>
      </div>
    </Modal>
  );
}

export default ExerciseDetailModal;
