import React from 'react';
import { ReactComponent as ChevronRight } from 'assets/icons/chevronRight.svg';

interface DashBoardCardProps {
  value: string | number;
  metricName: string;
}

function DashBoardMetricCard(props: DashBoardCardProps) {
  const { metricName, value } = props;

  return (
    <div className="DashBoardMetricCard">
      <div className="flex-1">
        <h1 className="metricValue">{value}</h1>
        <p className="metricName">{metricName}</p>
      </div>
      <button className="iconButton text-gray-3">
        <ChevronRight />
      </button>
    </div>
  );
}

export default DashBoardMetricCard;
