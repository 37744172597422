import React from 'react';
import clsx from 'clsx';

interface DropTargetProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  multiple?: boolean;
  allowedMimetypes?: string[];
  onChange?: (files: File[]) => void;
}

export const useDropTargetConfig = ({
  onChange,
  allowedMimetypes = ['*'],
  multiple,
}: Pick<DropTargetProps, 'onChange' | 'multiple' | 'allowedMimetypes'>) => {
  const callOnChange = (fileList: FileList | null) => {
    if (onChange && fileList !== null) {
      const files: File[] = [];
      const maxAllowedItems = multiple ? fileList.length : 1;
      for (let index = 0; index < maxAllowedItems; index++) {
        const curFile = fileList.item(index);
        if (
          curFile !== null &&
          (allowedMimetypes.includes('*') || allowedMimetypes.includes(curFile.type))
        ) {
          files.push(curFile);
        }
      }
      onChange(files);
    }
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    callOnChange(event.dataTransfer.files);
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    callOnChange(event.target.files);
  };
  return {
    handleDrop,
    handleDragOver,
    handleChange,
  };
};

interface SimpleDropTargetProps extends DropTargetProps {
  children: React.ReactNode;
  closeControl?: JSX.Element;
}
export function SimpleDropTarget(props: SimpleDropTargetProps) {
  const { onChange, allowedMimetypes = ['*'], multiple, className, children, closeControl } = props;
  const { handleDrop, handleDragOver, handleChange } = useDropTargetConfig({
    onChange,
    multiple,
    allowedMimetypes,
  });

  return (
    <div
      className={clsx(className, 'border border-dashed border-gray-normal flexCenter')}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {children}

      <input
        type="file"
        accept={allowedMimetypes.join(',')}
        multiple={multiple}
        onChange={handleChange}
        className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer text-zero border border-teal-normal"
      />
      {closeControl}
    </div>
  );
}

function DropTarget(props: DropTargetProps) {
  const { onChange, allowedMimetypes = ['*'], className, multiple = false } = props;
  const { handleDrop, handleDragOver, handleChange } = useDropTargetConfig({
    onChange,
    multiple,
    allowedMimetypes,
  });

  return (
    <div
      className={clsx(className, 'border border-dashed border-gray-normal flexCenter')}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div>
        <span className="mr-1">Drag and drop files here or </span>
        <div className={clsx('relative inline-block')}>
          <p className="text-purple-normal">Choose File</p>
          <input
            type="file"
            accept={allowedMimetypes.join(',')}
            multiple={multiple}
            onChange={handleChange}
            className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer text-zero"
          />
        </div>
      </div>
    </div>
  );
}
export default DropTarget;
