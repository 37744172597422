import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { EMPTY_ARRAY } from 'common/constants';
import useFileUploadService from 'common/hooks/useFileUploadService';
import MultiImageFileControl, {
  MultiImageChangeEvent,
} from 'common/ui/form/dndFileInput/MultiImageFileControl';
import VideoFileControl, {
  VideoFileChangeEvent,
} from 'common/ui/form/dndFileInput/VideFileControl';
import SimpleMultiSelect from 'common/ui/form/dropdown/SimpleMultiSelect';
import { SimpleSingleSelect } from 'common/ui/form/dropdown/SingleSelect';
import RadioButton from 'common/ui/form/RadioButton';
import Modal, { ModalProps } from 'common/ui/Modal';
import { convertToHTML } from 'draft-convert';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';

import { saveExerciseService } from './DAL/ExerciseService';
import useExerciseDropDownOptions from './DAL/useExerciseDropDownOptions';
import useExerciseList from './DAL/useExerciseList';
import { athleteLevelOptions, difficultyMultiplierOptions } from './constants';
import { ExerciseFormData, ExerciseFormSchema } from './types.d';

interface AddEditExercisesProps extends ModalProps {
  defaultFormData?: ExerciseFormData;
  exerciseId?: string;
}

export const blankForm: ExerciseFormData = {
  name: '',
  type: 'bodyWeight',
  focus: [],
  primary_muscle_group: [],
  equipment: [],
  instruction: '',
  image: [],
  is_draft: false,
};

function AddEditExerciseModal(props: AddEditExercisesProps) {
  const { defaultFormData = blankForm, exerciseId, ...restProps } = props;
  const [isLoading, setLoading] = useState(false);
  const { loadExercises, refreshData } = useExerciseList();
  const { isOptionsLoading, focusOptions, muscleGroupOptions, equipmentRequiredOptions } =
    useExerciseDropDownOptions({ autoLoad: true });

  const { files, removeAllFiles, setFiles, uploadFiles } = useFileUploadService();

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<ExerciseFormData>({
    defaultValues: defaultFormData,
    resolver: zodResolver(ExerciseFormSchema),
  });

  const onChangeImages = (event: MultiImageChangeEvent) => {
    setFiles(event.target.name, event.target.value.files);
    setValue('image', event.target.value.imageURLs);
  };

  const onChangeVideo = (event: VideoFileChangeEvent) => {
    const videoFile = event.target.value;
    if (videoFile) {
      setFiles(event.target.name, [videoFile]);
    } else {
      setFiles(event.target.name, EMPTY_ARRAY);
      setValue('video', '');
    }
  };

  const handleSaveExercise = async (exercisePayload: ExerciseFormData) => {
    setLoading(true);

    try {
      const mediaFiles = await uploadFiles();
      if (mediaFiles.video) {
        exercisePayload.video = mediaFiles.video?.[0].data?.location;
      }
      if (mediaFiles.image) {
        exercisePayload.image = exercisePayload.image.concat(
          mediaFiles.image
            .filter((single) => single.data !== null)
            .map((single) => single!.data!.location)
        );
      }
      if (exercisePayload.video?.length === 0) {
        exercisePayload.video = null;
      }
      console.log(exercisePayload);
      await saveExerciseService(exercisePayload, exerciseId);
      removeAllFiles();
      reset();
      if (exerciseId) {
        refreshData();
      } else {
        loadExercises({ page: 1 });
      }
      props.onClose();
    } finally {
      setLoading(false);
    }
  };

  const onClickSave = async () => {
    handleSaveExercise({ ...getValues(), is_draft: false });
  };
  const onClickSaveAsDraft = async () => {
    clearErrors();
    const exercisePayload = { ...getValues() };
    exercisePayload.is_draft = true;
    if (exercisePayload.name.trim().length === 0) {
      setError('name', { type: 'custom', message: 'Atleast name is required to save draft.' });
    } else {
      handleSaveExercise({ ...getValues(), is_draft: true });
    }
  };

  const selectedExerciseType = watch('type');

  const [editorState, setEditorState] = useState(() => {
    const formData = getValues().instruction;
    if (formData) {
      let { contentBlocks, entityMap } = convertFromHTML(formData);
      const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      return EditorState.createWithContent(newContentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  useEffect(() => {
    let raw = convertToHTML(editorState.getCurrentContent());
    setValue('instruction', raw);
  }, [editorState, setValue]);

  const renderDifficultyMultiplier = () => {
    if (selectedExerciseType === 'weighted') {
      return (
        <div className="mb-8">
          <label className="mb-2">Difficulty Multiplier</label>
          {athleteLevelOptions.map((dfmlt) => (
            <div className="flex mb-4" key={dfmlt.id}>
              <div className="w-1/2 pr-2">
                <button className="outlined px-4 h-9 w-full" type="button">
                  {dfmlt.data}
                </button>
              </div>
              <div className="w-1/2 px-2">
                <Controller
                  name={`difficulty_level.${dfmlt.id}`}
                  control={control}
                  render={({ field }) => (
                    <SimpleSingleSelect
                      name={`difficulty_level.${dfmlt.data}`}
                      options={difficultyMultiplierOptions}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      className="w-full lg:w-3/4 max-w-screen-lg"
      {...restProps}
      isLoading={isLoading || isOptionsLoading}
    >
      <form autoComplete="off" onSubmit={handleSubmit(onClickSave)}>
        <div className="h-pageContent overflow-y-scroll text-md">
          <div className="flex min-h-full">
            <div className="w-1/2 px-11 pt-10 bg-white">
              <div className="mb-6">
                <input
                  className="noBorder text-xl w-full"
                  type="text"
                  placeholder="Name your Exercise"
                  {...register('name')}
                />
                {errors.name ? <p className="fieldError">{errors.name.message}</p> : null}
              </div>
              <div className="flex flex-col lg:flex-row mb-8">
                <div className="lg:w-1/2 overflow-hidden lg:pr-2 mb-8 lg:mb-0">
                  <label className="mb-2">Focus</label>
                  <Controller
                    name="focus"
                    control={control}
                    render={({ field }) => (
                      <SimpleMultiSelect
                        name="focus"
                        options={focusOptions}
                        onChange={field.onChange}
                        values={field.value}
                        className={clsx(errors.focus && 'error')}
                      />
                    )}
                  />
                  <p className="fieldError">{errors?.focus?.message}</p>
                </div>
                <div className="lg:w-1/2 overflow-hidden lg:pl-2">
                  <label className="mb-2">Primary Muscle Groups</label>
                  <Controller
                    name="primary_muscle_group"
                    control={control}
                    render={({ field }) => (
                      <SimpleMultiSelect
                        name="primary_muscle_group"
                        options={muscleGroupOptions}
                        onChange={field.onChange}
                        values={field.value}
                        className={clsx(errors.primary_muscle_group && 'error')}
                      />
                    )}
                  />
                  <p className="fieldError">{errors?.primary_muscle_group?.message}</p>
                </div>
              </div>
              <div className="mb-8">
                <label className="mb-2">Exercise Type</label>
                <div className="flex">
                  <div className="w-1/2 pr-2">
                    <RadioButton
                      className="h-10 w-full"
                      label="Bodyweight"
                      value="bodyWeight"
                      {...register('type')}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <RadioButton
                      className="h-10 w-full"
                      label="Weighted"
                      value="weighted"
                      {...register('type')}
                    />
                  </div>
                </div>
              </div>
              {renderDifficultyMultiplier()}
              <div className="flex-1 mb-8">
                <label className="mb-2">Equipment Required</label>
                <Controller
                  name="equipment"
                  control={control}
                  render={({ field }) => (
                    <SimpleMultiSelect
                      name="equipment"
                      options={equipmentRequiredOptions}
                      onChange={field.onChange}
                      values={field.value}
                      className={clsx(errors.equipment && 'error')}
                    />
                  )}
                />
                <p className="fieldError">{errors?.equipment?.message}</p>
              </div>
              <div className="flex-1">
                <label className="mb-2">Technique Cues</label>
                <Editor
                  // className={clsx('w-full', errors?.instruction && 'error')}
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{ options: ['inline', 'list', 'textAlign'] }}
                />
                <p className="fieldError">{errors?.instruction?.message}</p>
              </div>
            </div>
            <div className="w-1/2 pl-6 bg-gray-light">
              <div className="px-9 pt-10">
                <h2 className="text-2xl mb-6">Media</h2>
                <label>Video</label>
                <Controller
                  name="video"
                  control={control}
                  render={({ field }) => (
                    <VideoFileControl
                      name="video"
                      videoURL={field.value}
                      file={files?.video?.[0]}
                      onChange={onChangeVideo}
                    />
                  )}
                />

                <label className="mt-10 flex items-center">
                  <p>Photos</p>
                  <p className="fieldError ml-2">{errors?.image?.message}</p>
                </label>
                <Controller
                  name="image"
                  control={control}
                  render={({ field }) => (
                    <MultiImageFileControl
                      name="image"
                      imageURLs={field.value}
                      files={files.image}
                      onChange={onChangeImages}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex px-8 py-4">
          <div className="flex-1" />
          <button
            className="w-32 outlined_primary narrow  text-teal-normal mr-4"
            type="button"
            onClick={onClickSaveAsDraft}
          >
            Save as draft
          </button>
          <button className="w-32 primary narrow" type="submit">
            {exerciseId ? 'Update' : 'Save'}
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddEditExerciseModal;
