import { useRef } from 'react';
import useApiCallStatus from 'common/hooks/useApiCallStatus';
import Throbber from 'common/ui/Throbber';
import { EMAxios } from 'common/utils/EMService';

import usePolicyTC from '../usePolicyTC';

function TermsConditions() {
  const { resource, loadPolicy } = usePolicyTC();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { isLoading, setLoading, setSuccess, setError } = useApiCallStatus();
  const handleSavePolicy = async () => {
    try {
      setLoading();
      await EMAxios.put('/api/admin/profile/policy', {
        termsAndConditions: textAreaRef?.current?.value,
        privacyPolicy: resource.privacyPolicy,
      });
      await loadPolicy();
      setSuccess();
    } catch (e) {
      setError();
    }
  };
  return (
    <div className="relative">
      <textarea
        className="border border-gray-5 p-4 w-full"
        rows={10}
        defaultValue={resource?.termsCondition}
        ref={textAreaRef}
      />
      <div className="flex mt-10 mb-6">
        <div className="flex-1" />
        <button className="primary uppercase w-80" type="button" onClick={handleSavePolicy}>
          Save Changes
        </button>
      </div>
      {isLoading ? <Throbber withMask /> : null}
    </div>
  );
}

export default TermsConditions;
