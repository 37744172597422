import { EyeOnOutlineIcon, MoreVertical, TrashIcon } from 'assets/icons';
import useAppNaviagte from 'common/hooks/useAppNaviagate';
import { useDeleteResource } from 'common/remoteResource/resourceCUD';
import Menu, { MenuProps } from 'common/ui/Menu';
import Throbber from 'common/ui/Throbber';

import BlankStream from './BlankStream';
import { ProgramModel } from './types';
import usePrograms from './usePrograms';

interface StreamProgramsProps {
  streamId: string;
}

const RowActions: MenuProps['items'] = [
  { name: 'editDetails', display: 'Edit Program', iconLeft: <EyeOnOutlineIcon /> },
  {
    name: 'remove',
    display: 'Remove',
    iconLeft: <TrashIcon />,
    confirm: 'Are you sure to delete the program?',
  },
];

function StreamPrograms(props: StreamProgramsProps) {
  const { streamId } = props;
  const { isLoading, items, loadPrograms } = usePrograms({
    streamId,
    autoLoad: true,
  });
  const { navigate } = useAppNaviagte();
  const { deleteResource, setDeleteInProgress, setDeleteSuccess, setDeleteError, isDeleteIP } =
    useDeleteResource({ url: `/api/admin/stream/${streamId}/programs` });

  if (isLoading || isDeleteIP) {
    return (
      <div className="bg-gray-light h-full relative">
        <Throbber withMask />
      </div>
    );
  } else if (items.length === 0) {
    return <BlankStream streamId={streamId} />;
  }
  const handleDelete = async (programId: string) => {
    setDeleteInProgress();
    const result = await deleteResource(programId);
    if (result) {
      await loadPrograms();
      setDeleteSuccess();
    } else {
      setDeleteError('Unable to delete program');
    }
  };
  const handleMenuAction = (actionName: string, menuData: ProgramModel) => {
    switch (actionName) {
      case 'editDetails':
        navigate(`/library/streams/${streamId}/update-program/${menuData._id}`);
        break;
      case 'remove':
        handleDelete(menuData._id);
        break;
      default:
        break;
    }
  };

  return (
    <div className="streamDetails flex h-full gap-6" style={{ width: `${items.length * 300}px` }}>
      {items.map((item) => (
        <div key={item._id} className="programColumn h-full overflow-auto bg-gray-100 relative">
          <div className="flex sticky top-0  px-3 py-2 items-center bg-gray-100">
            <h2 className="flex-1 font-semibold text-sm">{item.name}</h2>
            <p className="mr-4">{item.workouts.length} units</p>
            <Menu
              triggerElement={<MoreVertical />}
              items={RowActions}
              menuData={item}
              onItemClick={handleMenuAction}
            />
          </div>
          <div className="p-3">
            {item.workouts.map((workout, index) => (
              <div
                className="flex items-center mb-2 border border-gray-5 bg-gray-50 pr-2"
                key={`${workout._id}_${index}`}
              >
                <img
                  src={workout.cover_image || undefined}
                  alt=""
                  className="w-20 h-20 object-cover"
                  draggable={false}
                />
                <p className="flex-1 px-2">{workout.name}</p>
              </div>
            ))}
          </div>
          {item.is_draft ? (
            <div className="text-xs absolute top-0 px-1 bg-gray-200 text-gray-400">Draft</div>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default StreamPrograms;
