interface DropTargetProps {
  className?: string;
  children?: React.ReactNode;
  onDrop?: (data: any) => void;
}
export const DropTarget = (props: DropTargetProps) => {
  const { onDrop, children, className = 'bg-gray-100' } = props;
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const data = event.dataTransfer.getData('text/plain');
    event.preventDefault();
    if (onDrop) {
      onDrop(JSON.parse(data));
    }
  };
  return (
    <div
      className={className}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
};

interface EmptyDropTargetProps extends Omit<DropTargetProps, 'className'> {
  message?: string;
}
export const EmptyDropTarget = (props: EmptyDropTargetProps) => (
  <DropTarget className="h-32 flexCenter border-2 border-dashed bg-gray-100" {...props}>
    <p className="text-gray-400">{props.message}</p>
  </DropTarget>
);
