import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import SearchField from 'common/ui/form/SearchField';

import useWorkoutList from './DAL/useWorkoutList';
import WorkoutList from './WorkoutList';

function Workouts() {
  const navigate = useNavigate();
  const { searchWorkout, filters } = useWorkoutList();
  const openCreateWorkoutPage = () => {
    navigate('/library/workouts/create-workout');
  };
  return (
    <div className="contentArea h-appContent">
      <div className="flex items-center h-pageHeader">
        <h1 className="flex-1 font-semibold text-2xl">Workout Library</h1>
        <SearchField value={filters.searchText} onSearch={searchWorkout} />
        <button className="primary px-4 ml-4" onClick={openCreateWorkoutPage}>
          <PlusIcon />
          Add Workout
        </button>
      </div>
      <div className="h-pageContent pb-4">
        <WorkoutList />
      </div>
    </div>
  );
}

export default Workouts;
