import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CloseRoundFilledIcon } from 'assets/icons';
import { athleteDifficultyLevelLabel } from 'common/constants';

import { ProgramBuildFormData } from '../types';

interface WorkoutItemProps {
  workout: ProgramBuildFormData['workouts'][0];
  compactMode: boolean;
  id: string;
  onRemoveWorkout: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function WorkoutItem(props: WorkoutItemProps) {
  const { workout, compactMode, onRemoveWorkout } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div
      className={`drop-shadow-sm rounded overflow-hidden 
                 flex items-center border border-gray-5
                bg-gray-100 pr-2 show-items-on-hover my-2`}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      key={workout.uniqueId}
    >
      {compactMode ? null : <img src={workout.cover_image} className="h-20 w-20" alt="workout" />}
      <div className="ml-4 flex-1">
        <p className="text-base">{workout.name}</p>
        {compactMode ? null : (
          <p className="text-xs">
            {[
              workout.category,
              athleteDifficultyLevelLabel[workout.difficulty_level],
              workout.workout_length,
            ].join(' . ')}
          </p>
        )}
      </div>
      <button
        className="iconButton text-alert-error bg-white rounded-full visible-on-hover"
        type="button"
        value={workout.uniqueId}
        onPointerDown={(e) => {
          e.stopPropagation();
          onRemoveWorkout(e);
        }}
      >
        <CloseRoundFilledIcon />
      </button>
    </div>
  );
}

export default WorkoutItem;
