import React from 'react';
import {
  ActionColumn,
  Column,
  Content,
  DataTable,
  DataTableHeader,
  DataTableHelperInfo,
  DataTableRow,
  HeaderColumn,
  Pager,
} from 'common/ui/DataTable';
import Throbber from 'common/ui/Throbber';
import { noop } from 'common/utils/general';

import useExerciseList from './DAL/useExerciseList';
import ExerciseRowAction from './ExerciseRowAction';
import { ExerciseModel } from './types';

const columns = [
  { name: 'name', displayText: 'Name', sortable: true },
  { name: 'muscleGroup', displayText: 'Muscle Group' },
  { name: 'focus', displayText: 'Focus' },
  { name: 'equipment', displayText: 'Equipment' },
  { name: 'updated_at', displayText: 'Last updated', sortable: true },
  { name: 'status', displayText: 'Status' },
];

interface ExerciseListProps {
  onClickDataRow: (exercise: ExerciseModel) => void;
}

function ExerciseList(props: ExerciseListProps) {
  const {
    isLoadingEL,
    status,
    items,
    filters: { page, perPage, sortByColumn, sortOrder },
    totalCount,
    loadExercises,
    refreshData,
    moveToPage,
  } = useExerciseList({ autoLoad: true });

  const handleClickDataRow = (singleExercise: ExerciseModel) => {
    props.onClickDataRow(singleExercise);
  };

  const handleColumnSort = (name: string, currentSort = 'desc') => {
    loadExercises({ sortByColumn: name, sortOrder: currentSort === 'desc' ? 'asc' : 'desc' });
  };

  return (
    <DataTable className="bg-gray-50 relative">
      <Content>
        <DataTableHeader className="bg-gray-normal text-white text-sm font-medium ">
          {columns.map((column) => (
            <HeaderColumn
              key={column.name}
              name={column.name}
              onClick={column.sortable ? handleColumnSort : noop}
              sortOrder={sortByColumn === column.name ? sortOrder : undefined}
            >
              {column.displayText}
            </HeaderColumn>
          ))}
          <ActionColumn />
        </DataTableHeader>
        <DataTableHelperInfo status={status} items={items} refreshData={refreshData} />
        {items.map((singleExercise) => (
          <DataTableRow
            key={singleExercise._id}
            rowData={singleExercise}
            className="py-2 border-b border-b-gray-light"
            onClick={handleClickDataRow}
          >
            <Column className="break-all">{singleExercise.name}</Column>
            <Column>
              {singleExercise.primary_muscle_group
                .map((pm: { name: string }) => pm?.name)
                .join(', ')}
            </Column>
            <Column>
              {singleExercise.focus?.map((pm: { name: string }) => pm?.name).join(', ')}
            </Column>
            <Column>
              {singleExercise.equipment.map((pm: { name: string }) => pm?.name).join(', ')}
            </Column>
            <Column>{singleExercise.updated_at}</Column>
            <Column>{singleExercise.is_draft ? 'Disabled' : 'Active'}</Column>
            <ActionColumn>
              <ExerciseRowAction exercise={singleExercise} onClickView={handleClickDataRow} />
            </ActionColumn>
          </DataTableRow>
        ))}
      </Content>
      <Pager
        curPage={page}
        pageSize={perPage}
        totalCount={totalCount}
        isLoading={isLoadingEL}
        moveToPage={moveToPage}
      />
      {isLoadingEL ? <Throbber withMask /> : null}
    </DataTable>
  );
}

export default ExerciseList;
