import React from 'react';
import clsx from 'clsx';

import Modal, { useModalConfig } from './Modal';

interface ConfirmModalProps {
  triggerElement: JSX.Element;
  triggerClassName?: string;
  confirmBody: string | JSX.Element;
  actionName: string;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCancel: () => void;
}

function ConfirmModal(props: ConfirmModalProps) {
  const { triggerElement, triggerClassName, confirmBody, actionName, onConfirm, onCancel } = props;
  const { isModalOpen, openModal } = useModalConfig(false);
  return (
    <>
      <button className={clsx(triggerClassName)} onClick={openModal} type="button">
        {triggerElement}
      </button>
      <Modal isOpen={isModalOpen} onClose={onCancel} className="px-8 py-4 w-modal-sm">
        <h1 className="font-semibold mb-4">Confirm</h1>
        {confirmBody}
        <div className="flex pt-14">
          <button className="narrow  text-teal-normal" onClick={onCancel}>
            Cancel
          </button>
          <div className="flex-1" />
          <button className="w-24 primary narrow" name={actionName} onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmModal;
