import { ResourceLoaderFn, useRemoteResource } from '@cksutils/remote-resource-toolkit';
import { deafultQueryParams, EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { ResourceFilters, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

import { AthleteModel } from '../types';

type AthleteList = { items: AthleteModel[]; totalCount: number };

const resourceLoader: ResourceLoaderFn<AthleteList, ResourceFilters> = async (args) => {
  try {
    const queryParams = {
      ...deafultQueryParams,
      ...args.params,
    };
    const { data } = await EMAxios.get('/api/admin/athelete', {
      params: queryParams,
      signal: args?.abortSignal,
    });
    return {
      success: data.success,
      data: {
        items: data?.data?.athelete,
        totalCount: data?.data?.total_count,
      },
      params: queryParams,
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useAthleteList = (params: ResourceHookParams = EMPTY_OBJECT) => {
  const {
    status,
    resource,
    resourceParams = deafultQueryParams,
    loadData,
  } = useRemoteResource<AthleteList, ResourceFilters>({
    resourceName: 'athletes',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  const moveToPage = async (page: number) => {
    return loadData({ ...resourceParams, page });
  };

  const searchAthlete = (searchText: string) => loadData({ searchText });

  return {
    isLoadingAth: status === 'IN_PROGRESS',
    status,
    items: resource?.items || EMPTY_ARRAY,
    totalCount: resource?.totalCount || 0,
    filters: resourceParams,
    loadAthletes: loadData,
    refreshData: loadData,
    moveToPage,
    searchAthlete,
  };
};

export default useAthleteList;
