import { EMAIL_REGX } from 'common/constants';
import { _IdNamePair, APIResponse } from 'common/types';
import * as Z from 'zod';

export const AthleteFormSchema = Z.object({
  atheleteData: Z.object({
    first_name: Z.string().trim().min(1, { message: 'First name required.' }),
    last_name: Z.string().trim().min(1, { message: 'Last name required.' }),
    email: Z.string()
      .trim()
      .min(1, { message: 'Email is required.' })
      .regex(EMAIL_REGX, { message: 'Enter valid email.' }),
    athelete_type: Z.string().trim().min(1, { message: 'Select athlete type' }),
    profile_image: Z.union([Z.string(), Z.instanceof(File)])
      .optional()
      .nullable(),
  }),
});
export type AthleteFormData = Z.infer<typeof AthleteFormSchema>;

export interface AthleteModel {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  athelete_type: _IdNamePair;
  workout_completed: number;
  active_program: string;
  created_at: string;
  updated_at: string;
  profile_image?: string | null;
}

export type AthleteOptionsResponse = APIResponse<{
  athelete_type: _IdNamePair[];
}>;
