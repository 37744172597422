import React from 'react';
import clsx from 'clsx';

interface RadioButtonProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
  variant?: 'outlined' | 'filled';
  rounded?: string | undefined;
}

function RadioButton(props: RadioButtonProps, ref: React.Ref<HTMLInputElement>) {
  const { className, variant = 'outlined', label, rounded, ...restProps } = props;

  return (
    <div className={clsx(className, 'radioButton relative')}>
      <input
        {...restProps}
        type="radio"
        className="h-full w-full absolute cursor-pointer opacity-0"
        ref={ref}
      />
      <div
        className={clsx(variant, 'btn h-full w-full flex flexCenter')}
        style={{
          borderRadius: rounded,
        }}
      >
        {label}
      </div>
    </div>
  );
}

export default React.forwardRef(RadioButton);
