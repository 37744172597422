import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import clsx from 'clsx';
import { ApiCallStatus } from 'common/types';
import PasswordField from 'common/ui/form/PasswordField';
import Throbber from 'common/ui/Throbber';
import { authActions } from 'modules/auth/authSlice';

import * as authService from './authService';
import LoginPageLayout from './LoginPageLayout';

interface LoginFormData {
  emailId: string;
  password: string;
  rememberMe: boolean;
}

function LoginPage() {
  const { register, handleSubmit, watch } = useForm<LoginFormData>({
    defaultValues: { emailId: '', password: '' },
  });
  const [loginState, setLoginState] = React.useState<ApiCallStatus>('INIT');
  const isLoginError = loginState === 'ERROR';
  const isAuthError = loginState === 'ERROR_PRIVILEGES';
  const isError = isAuthError || isLoginError;
  const { pathname } = useLocation();

  const formData = watch();
  const dispatch = useAppDispatch();

  const handleLogin = async (formData: LoginFormData) => {
    setLoginState('IN_PROGRESS');
    try {
      await authService.login({ email: formData.emailId, password: formData.password });
      dispatch(authActions.setStatus({ status: 'LOGGED_IN' }));
    } catch (e: any) {
      e.message === 'AUTH_ERROR'
          ? setLoginState('ERROR_PRIVILEGES')
          : setLoginState('ERROR')
    }
  };

  const isFormFilled = formData.emailId && formData.password;
  return (
    <LoginPageLayout>
      <div className="bg-white px-8 py-11 lg:w-[450px] lg:ml-32 relative">
        {pathname === '/reset-password-success' && (
          <p className="bg-alert-success p-2 rounded mb-6">Successful password reset.</p>
        )}
        <form noValidate autoComplete="off" onSubmit={handleSubmit(handleLogin)}>
          <h3 className="mb-2 font-medium">Welcome back</h3>
          <h1 className="text-3xl mb-6 font-semibold">Login to your account</h1>

          {isLoginError ? (
            <p className="text-alert-error mb-3">Incorrect email or password. Please try again.</p>
          ) : null}
          {isAuthError ? (
            <p className="text-alert-error mb-3">You do not have access to this resource.</p>
          ) : null}

          <label className="block text-sm font-medium mb-2">Email</label>
          <input
            className={clsx('w-full mb-8', (isError) && 'error')}
            type="text"
            {...register('emailId')}
          />

          <label className="block text-sm font-medium mb-2">Password</label>
          <PasswordField
            containerClassName="mb-6"
            className={clsx('w-full', isError && 'error')}
            {...register('password')}
          />

          <div className="flex items-center justify-center mb-11">
            <label className="flex items-center flex-1 select-none cursor-pointer">
              <input
                type="checkbox"
                tabIndex={-1}
                className="mr-3 w-5 h-5"
                {...register('rememberMe')}
              />
              Remember me
            </label>
            <Link to="/forget-password">Forget password?</Link>
          </div>
          <button className="primary w-full uppercase" type="submit" disabled={!isFormFilled}>
            Login
          </button>
          {loginState === 'IN_PROGRESS' && <Throbber withMask />}
        </form>
      </div>
    </LoginPageLayout>
  );
}

export default LoginPage;
