import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { APICallState } from 'common/types';
import PasswordField from 'common/ui/form/PasswordField';
import Throbber from 'common/ui/Throbber';
import { EMAxios } from 'common/utils/EMService';

import LoginPageLayout from '../LoginPageLayout';

import { ChangePasswordData, ForgetPasswordData } from './forgetpasswordTypes';

type ResetPasswordFormData = Pick<ChangePasswordData, 'newPassword' | 'confirmPassword'>;

function ResetPasswordForm(props: ForgetPasswordData) {
  const {
    register,
    formState: { isValid },
    getValues,
    handleSubmit,
  } = useForm<ResetPasswordFormData>({ mode: 'onChange' });
  const naviagte = useNavigate();
  const [resetPasswordState, setResetPasswordState] = React.useState<APICallState>();

  const handleResetPassword = async (formData: ResetPasswordFormData) => {
    setResetPasswordState({ status: 'IN_PROGRESS' });
    try {
      await EMAxios.post('/api/verify-code', {
        email: props.emailId,
        code: props.code,
        password: formData.newPassword,
      });
      naviagte('/reset-password-success', { replace: true });
    } catch (e) {
      setResetPasswordState({ status: 'ERROR' });
    }
  };

  return (
    <LoginPageLayout>
      <div className="w-[450px] lg:ml-32 relative">
        <div className="bg-white px-8 py-11">
          <form noValidate autoComplete="off" onSubmit={handleSubmit(handleResetPassword)}>
            <h1 className="text-3xl mb-6 font-semibold">Reset Password</h1>

            <label className="block text-sm font-medium mb-2">New Password</label>
            <PasswordField
              containerClassName="mb-6"
              className="w-full"
              {...register('newPassword', { required: true })}
            />

            <label className="block text-sm font-medium mb-2">Confirm Password</label>
            <PasswordField
              containerClassName="mb-12"
              className="w-full"
              {...register('confirmPassword', {
                required: true,
                validate: (value) => {
                  const { newPassword } = getValues();
                  return newPassword === value || 'Passwords should match!';
                },
              })}
            />

            <button className="primary w-full uppercase" type="submit" disabled={!isValid}>
              RESET
            </button>
            {resetPasswordState?.status === 'IN_PROGRESS' && <Throbber withMask />}
          </form>
        </div>
      </div>
    </LoginPageLayout>
  );
}

export default ResetPasswordForm;
