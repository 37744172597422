import React from 'react';
import Modal, { useModalConfig } from 'common/ui/Modal';

interface DeleteConfirmModalProps {
  renderTrigger: (props: { onClick: () => void }) => React.ReactNode;
  message: string | React.ReactNode;
  onClose?: () => void;
  onConfirm: () => Promise<void>;
}

function DeleteConfirmModal(props: DeleteConfirmModalProps) {
  const { renderTrigger, message, onClose, onConfirm } = props;
  const { isModalOpen, isModalLoading, openModal, closeModal, setLoading } = useModalConfig(false);
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    closeModal();
  };
  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onConfirm();
    } finally {
      handleCloseModal();
    }
  };
  return (
    <>
      {renderTrigger({ onClick: openModal })}
      <Modal
        isOpen={isModalOpen}
        isLoading={isModalLoading}
        onClose={handleCloseModal}
        className="px-10 py-4 w-modal-sm"
      >
        <p>Confirm</p>
        {message}
        <div className="flex pt-14">
          <button className="w-24  narrow  text-teal-normal" onClick={handleCloseModal}>
            Cancel
          </button>
          <div className="flex-1" />
          <button className="w-24 primary narrow" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
      </Modal>
    </>
  );
}

export default DeleteConfirmModal;
