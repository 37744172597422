import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { _IdNamePair, OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type ProgramOptions = {
  program: OptionData<string>[];
};

const resourceLoader: ResourceLoaderFn<ProgramOptions> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/program/all', {
      signal: args?.abortSignal,
    });
    const { program } = data?.data!;
    const programOptions = program.map((option: _IdNamePair) => ({
      id: option._id,
      data: option.name,
    }));

    return {
      success: data.success,
      data: {
        program: programOptions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useProgramDropDownOptions = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource } = useRemoteResource<ProgramOptions>({
    resourceName: 'programOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });
  return {
    isProgramOptionsLoading: status === 'IN_PROGRESS',
    status,
    programs: resource?.program || EMPTY_ARRAY,
  };
};

export default useProgramDropDownOptions;
