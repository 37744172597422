import { Route, Routes } from 'react-router-dom';
import useExerciseList from 'modules/exercises/DAL/useExerciseList';

import CreateWorkOutPage from './CreateUpdate/SaveWorkout';
import UpdateWorkOutPage from './CreateUpdate/UpdateWorkOutPage';
import useWorkoutList from './DAL/useWorkoutList';
import useWorkoutDropDownOptions from './DAL/useWorkputDropDownOptions';
import WorkoutPage from './WorkoutPage';

function WorkoutModule() {
  useWorkoutList({ autoRemove: true, disconnected: true });
  useWorkoutDropDownOptions({ autoRemove: true, disconnected: true });
  useExerciseList({ autoRemove: true, disconnected: true });
  return (
    <Routes>
      <Route path="" element={<WorkoutPage />} />
      <Route path="create-workout" element={<CreateWorkOutPage />} />
      <Route path="update-workout/:workoutId" element={<UpdateWorkOutPage />} />
    </Routes>
  );
}
export default WorkoutModule;
