import React from 'react';
import ConfirmModal from 'common/ui/ConfirmModal';
import Popup, { PopupProps, usePopupConfig } from 'common/ui/Popup';

export interface MenuProps<T = any> {
  triggerElement: JSX.Element;
  items: Array<{
    name: string;
    display: string;
    iconLeft?: JSX.Element;
    confirm?: string;
  }>;
  menuData?: T; // any contextual data that need to pass on item click
  placement?: PopupProps['placement'];
  onItemClick: (itemName: string, menudata?: T) => void; // function to call when item clicked
}

function Menu(props: MenuProps) {
  const { items, triggerElement, menuData, placement = 'bottom-end', onItemClick } = props;
  const { anchorEl, showOptions, closeOptions } = usePopupConfig<HTMLButtonElement>();

  const handleItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    closeOptions();
    onItemClick(event.currentTarget.name, menuData);
  };

  const renderItem = (item: MenuProps['items'][0]) => {
    if (item.confirm) {
      return (
        <ConfirmModal
          triggerClassName="item justify-start"
          triggerElement={
            <>
              {item.iconLeft}
              <p className="ml-3">{item.display}</p>
            </>
          }
          actionName={item.name}
          confirmBody={item.confirm}
          onConfirm={handleItemClick}
          onCancel={closeOptions}
        />
      );
    }
    return (
      <button className="item justify-start" name={item.name} onClick={handleItemClick}>
        {item.iconLeft}
        <p className="ml-3">{item.display}</p>
      </button>
    );
  };

  return (
    <>
      <button
        style={{
          borderRadius: '5px',
          paddingTop: '0px',
          paddingBottom: '0px',
          margin: '5px',
        }}

        onClick={showOptions} type="button">
        {triggerElement}
      </button>
      <Popup anchorEl={anchorEl} onClose={closeOptions} placement={placement}>
        <ul className="menu">
          {items.map((item) => (
            <li key={item.name}>{renderItem(item)}</li>
          ))}
        </ul>
      </Popup>
    </>
  );
}

export default Menu;
