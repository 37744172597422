import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { EMAIL_REGX } from 'common/constants';
import { useSaveResource } from 'common/remoteResource/resourceCUD';
import ImageInputFile from 'common/ui/form/dndFileInput/ImageInputFile';
import { SimpleSingleSelect } from 'common/ui/form/dropdown/SingleSelect';
import Modal, { ModalProps } from 'common/ui/Modal';
import { uploadFile } from 'common/utils/general';

import useAthleteList from './DAL/useAthleteList';
import useAthleteDropDownOptions from './DAL/useAthleteOptions';
import { AthleteFormData, AthleteFormSchema } from './types';

interface AddEditAthletesProps extends ModalProps {
  defaultFormData?: AthleteFormData;
  athleteId?: string;
}

export const blankForm: AthleteFormData = {
  atheleteData: {
    first_name: '',
    last_name: '',
    email: '',
    athelete_type: '',
    profile_image: null,
  },
};

function AddEditAthleteModalComponent(props: AddEditAthletesProps) {
  const { athleteId, defaultFormData, onClose, ...restProps } = props;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AthleteFormData>({
    defaultValues: defaultFormData,
    resolver: zodResolver(AthleteFormSchema),
  });
  const { isOptionsLoading, athleteTypeOptions } = useAthleteDropDownOptions({ autoLoad: true });
  const { filters, refreshData, loadAthletes } = useAthleteList();
  const {
    isSaveIP,
    isSaveError,
    saveMessage,
    setSaveInProgress,
    setSaveError,
    saveResource,
    resetSuccessCount,
    getSuccessCount,
  } = useSaveResource({ url: '/api/admin/athelete' });

  const handleSaveAthlete = async (payload: AthleteFormData) => {
    setSaveInProgress();
    const profileImageLocation = await uploadFile(payload.atheleteData.profile_image);
    payload.atheleteData.profile_image = profileImageLocation;
    const result = await saveResource({ payload, resourceId: athleteId });
    if (result) {
      loadAthletes({ page: 1 });
      onClose();
    } else {
      setSaveError('Unable to save athlete.');
    }
  };

  const handleClose = () => {
    const successCount = getSuccessCount();
    if (athleteId && successCount > 0 && filters.sortByColumn !== 'updated_at') {
      refreshData();
    } else if (successCount > 0) {
      loadAthletes({ page: 1 });
    }
    resetSuccessCount();
    onClose();
  };

  return (
    <Modal
      className="w-full md:w-modal-md max-w-screen-lg"
      onClose={handleClose}
      isLoading={isOptionsLoading || isSaveIP}
      {...restProps}
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleSaveAthlete)}>
        <div className="max-h-pageContent overflow-y-auto px-9 pt-6 bg-white text-md">
          <div className="flex items-center mb-6">
            <h1 className="text-xl">{athleteId ? 'Update Athlete' : 'Create New Athlete'}</h1>
            <p
              className={clsx(
                'flex-1 ml-2',
                isSaveError ? 'text-alert-error' : 'text-alert-success'
              )}
            >
              {saveMessage}
            </p>
          </div>
          <div className="mb-6 flexCenter flex-col">
            <Controller
              control={control}
              name="atheleteData.profile_image"
              render={({ field }) => (
                <ImageInputFile
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  className="h-32 w-32 text-sm"
                  rounded
                />
              )}
            />
            <p className="fieldError">{errors?.atheleteData?.profile_image?.message}</p>
          </div>

          <div className="flex flex-col lg:flex-row mb-8">
            <div className="lg:w-1/2 overflow-hidden lg:pr-2 mb-8 lg:mb-0">
              <label className="mb-2">First Name</label>
              <input className="w-full" type="text" {...register('atheleteData.first_name')} />
              <p className="fieldError">{errors?.atheleteData?.first_name?.message}</p>
            </div>
            <div className="lg:w-1/2 overflow-hidden lg:pl-2">
              <label className="mb-2">Last Name</label>
              <input className="w-full" type="text" {...register('atheleteData.last_name')} />
              <p className="fieldError">{errors?.atheleteData?.last_name?.message}</p>
            </div>
          </div>
          <div className="mb-8">
            <label className="mb-2">Email</label>
            <input
              className="w-full"
              type="text"
              {...register('atheleteData.email', { required: true, pattern: EMAIL_REGX })}
            />
            <p className="fieldError">{errors?.atheleteData?.email?.message}</p>
          </div>
          <div className="flex-1 mb-8">
            <label className="mb-2">Athlete Type</label>
            <Controller
              name="atheleteData.athelete_type"
              control={control}
              render={({ field }) => (
                <SimpleSingleSelect
                  name="athelete_type"
                  options={athleteTypeOptions}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <p className="fieldError">{errors?.atheleteData?.athelete_type?.message}</p>
          </div>
        </div>
        <div className="flex px-8 py-4">
          <button className="flex-1 primary narrow">{athleteId ? 'Update' : 'Save'}</button>
        </div>
      </form>
    </Modal>
  );
}

function AddEditAthleteModal(props: AddEditAthletesProps) {
  if (props.isOpen) {
    return <AddEditAthleteModalComponent {...props} />;
  }
  return null;
}
export default AddEditAthleteModal;
