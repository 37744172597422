import { useState } from 'react';
import useApiCallStatus from 'common/hooks/useApiCallStatus';
import { CustomFieldEvent } from 'common/types';
import ImageInputFile from 'common/ui/form/dndFileInput/ImageInputFile';
import { EMAxios } from 'common/utils/EMService';
import { uploadFile } from 'common/utils/general';

import useCurrentUser from './useCurrentUser';

function ProfileImage() {
  const { profilePicture, loadProfile } = useCurrentUser();
  const [profileImageURL, setProfileImageURL] = useState<File | null>();
  const { isLoading, setLoading, setSuccess, setError } = useApiCallStatus();
  const onChangeProfilePicture = async (event: CustomFieldEvent<File | null>) => {
    try {
      setLoading();
      const imageURL = await uploadFile(event.target.value);
      setProfileImageURL(event.target.value);
      if (imageURL) {
        await EMAxios.put('/api/admin/profile', { profilePicture: imageURL });
        await loadProfile();
      }
      setSuccess();
    } catch (e) {
      setError();
    }
  };
  return (
    <div className="relative m-auto w-36">
      <ImageInputFile
        name="profileImage"
        value={profileImageURL || profilePicture}
        onChange={onChangeProfilePicture}
        className="h-32 w-32 text-sm"
        rounded
      />
      {isLoading ? (
        <div
          className={`
      border-alert-error
        border-r-transparent
        animate-spin 
        inline-block
        w-32 h-32 border-4
        rounded-full absolute left-0 top-0`}
        />
      ) : null}
    </div>
  );
}

export default ProfileImage;
