import { ResourceLoaderFn, useRemoteResource } from '@cksutils/remote-resource-toolkit';
import { deafultQueryParams, EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { ResourceFilters, ResourceHookParams } from 'common/types';
import { formatISODate } from 'common/utils/dateTimeUtils';
import { EMAxios } from 'common/utils/EMService';

import { ExerciseModel } from '../types';

type ExerciseList = { items: ExerciseModel[]; totalCount: number };

const resourceLoader: ResourceLoaderFn<ExerciseList, ResourceFilters> = async (args) => {
  try {
    const queryParams = {
      ...deafultQueryParams,
      ...args.params,
    };
    const { data } = await EMAxios.get('/api/admin/exercise', {
      params: queryParams,
      signal: args?.abortSignal,
    });
    const exercises = data?.data?.exercises || EMPTY_ARRAY;
    const updatedExercises = exercises.map((exercise: ExerciseModel) => ({
      ...exercise,
      updated_at: formatISODate(exercise.updated_at),
    }));

    return {
      success: data.success,
      data: {
        items: updatedExercises,
        totalCount: data?.data?.total_count!,
      },
      params: queryParams,
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useExerciseList = (params: ResourceHookParams = EMPTY_OBJECT) => {
  const {
    status,
    resource,
    resourceParams = deafultQueryParams,
    loadData,
  } = useRemoteResource<ExerciseList, ResourceFilters>({
    resourceName: 'exerciselist',
    autoLoad: params.autoLoad,
    autoLoadParams: params.autoLoadParams,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  const moveToPage = async (page: number) => {
    return loadData({ ...resourceParams, page });
  };

  const searchExercise = (searchText: string) => {
    loadData({ searchText: searchText || undefined });
  };
  return {
    isLoadingEL: status === 'IN_PROGRESS',
    status,
    items: resource?.items || EMPTY_ARRAY,
    totalCount: resource?.totalCount || 0,
    filters: resourceParams,
    loadExercises: loadData,
    refreshData: loadData,
    searchExercise,
    moveToPage,
  };
};

export default useExerciseList;
