import React from "react";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod/dist/zod";
import clsx from "clsx";

import {useSaveResource} from "../../common/remoteResource/resourceCUD";
import SimpleMultiSelect from "../../common/ui/form/dropdown/SimpleMultiSelect";
import Throbber from "../../common/ui/Throbber";

import {NotificationFormData, NotificationFormSchema} from "./types";
import useEmailDropDownOptions from './useEmailOptions';
export const blankForm: NotificationFormData = {
  data: {
    title: '',
    body: '',
    emails: [],
  }
};
function Notifications() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<NotificationFormData>({
    defaultValues: blankForm,
    resolver: zodResolver(NotificationFormSchema),
  });
  const {
    isSaveError,
    saveMessage,
    setSaveInProgress,
    isSaveIP,
    setSaveError,
    saveResource,
    setSaveSuccess
  } = useSaveResource({ url: '/api/admin/notifications' });
  const { emailOptions } = useEmailDropDownOptions({ autoLoad: true, disconnected: false });
  const handleSendNotifications = async (payload: NotificationFormData) => {
    setSaveInProgress();
    console.log("saving")
    const result = await saveResource({ payload, method: "PUT" });
    if (result) {
      setSaveSuccess("Notifications Sent!")
    } else {
      setSaveError('Unable to send notifications.');
    }
  };
  const formData = watch();
  const isFormFilled = formData.data.title && formData.data.body && formData.data.emails.length > 0;
  return (
    <div className="contentArea h-appContent">
      <div className="flex items-center h-pageHeader">
        <h1 className="flex-1 font-semibold text-2xl">Notifications</h1>
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleSendNotifications)}>
        <div className="max-h-pageContent overflow-y-auto px-9 pt-6 bg-white text-md">
          <div className="flex items-center mb-6">
            <h1 className="text-xl">Send Notifications to emails</h1>
            <p
              className={clsx(
                'flex-1 ml-2',
                isSaveError ? 'text-alert-error' : 'text-alert-success'
              )}
            >
              {saveMessage}
            </p>
          </div>

          <div className="mb-8">
            <label className="mb-2">Title</label>
            <input className="w-full" type="text" {...register('data.title')} />
            <p className="fieldError">{errors?.data?.title?.message}</p>
          </div>
          <div className="mb-8">
            <label className="mb-2">Body</label>
            <input className="w-full" type="text" {...register('data.body')} />
            <p className="fieldError">{errors?.data?.body?.message}</p>
          </div>
          <div className="flex-1 mb-8">
            <label className="mb-2">Send To Users</label>
            <Controller
              name="data.emails"
              control={control}
              render={({ field }) => (
                <SimpleMultiSelect
                  name="athelete_type"
                  options={emailOptions}
                  onChange={field.onChange}
                  values={field.value}/>
              )}
            />
          </div>
          <p className="fieldError">{errors?.data?.emails?.message}</p>
        </div>
          <button className="flex-1 w-full primary narrow uppercase"  disabled={!isFormFilled}>Fire Notifications</button>
        {isSaveIP && <Throbber withMask />}
      </form>
    </div>
  );
}

export default Notifications;
