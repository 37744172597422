import React from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as EyeOnOutlineIcon } from 'assets/icons/eyeOnOutline.svg';
import { ReactComponent as MoreHorizontalIcon } from 'assets/icons/moreHorizontal.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import DeleteConfirmModal from 'common/ui/DeleteConfirmModal';
import Popup, { usePopupConfig } from 'common/ui/Popup';
import { EMAxios } from 'common/utils/EMService';

import useExerciseList from './DAL/useExerciseList';
import { ExerciseModel } from './types';

interface ExerciseRowActionProps {
  exercise: ExerciseModel;
  onClickView: (exercise: ExerciseModel) => void;
}

function ExerciseRowAction(props: ExerciseRowActionProps) {
  const { anchorEl, showOptions, closeOptions } = usePopupConfig<HTMLButtonElement>();

  const { refreshData } = useExerciseList();

  const { exercise, onClickView } = props;

  const handleDuplicate = async () => {
    try {
      await EMAxios.request({ method: 'POST', url: `/api/admin/exercise/clone/${exercise._id}` });
      refreshData();
    } catch (e) {
      console.error(e);
    }
    closeOptions();
  };
  const onConfirmDelete = async () => {
    await EMAxios.request({ method: 'DELETE', url: `/api/admin/exercise/${exercise._id}` });
    refreshData();
  };
  const handleView = () => {
    closeOptions();
    onClickView(exercise);
  };

  return (
    <>
      <button onClick={showOptions} type="button">
        <MoreHorizontalIcon />
      </button>
      <Popup anchorEl={anchorEl} onClose={closeOptions} placement="bottom-end">
        <ul className="menu">
          <li>
            <button className="item justify-start" onClick={handleView}>
              <EyeOnOutlineIcon />
              <p className="ml-3">View Exercise</p>
            </button>
          </li>
          <li>
            <button className="item justify-start" onClick={handleDuplicate}>
              <CopyIcon />
              <p className="ml-3">Duplicate</p>
            </button>
          </li>
          <li>
            <DeleteConfirmModal
              renderTrigger={({ onClick }) => (
                <button className="item justify-start" onClick={onClick} type="button">
                  <TrashIcon />
                  <p className="ml-3">Delete</p>
                </button>
              )}
              message="Are you sure to remove this exercise?"
              onConfirm={onConfirmDelete}
              onClose={closeOptions}
            />
          </li>
        </ul>
      </Popup>
    </>
  );
}

export default ExerciseRowAction;
