import './programs.css';

import { Route, Routes } from 'react-router-dom';
import useExerciseDropDownOptions from 'modules/exercises/DAL/useExerciseDropDownOptions';

import ProgramForm from './createupdate/ProgramForm';
import UpdateProgram from './createupdate/UpdateProgram';
import Streams from './streams/Streams';
import useStreamOptions from './streams/useStreamOptions';
import useStreamList from './streams/useStreams';
import StreamDetails from './StreamDetails';
import usePrograms from './usePrograms';

function Programs() {
  useStreamList({ autoRemove: true, disconnected: true });
  usePrograms({ streamId: '', autoRemove: true, disconnected: true });
  useStreamOptions({ autoRemove: true, disconnected: true });
  useExerciseDropDownOptions({ autoRemove: true, disconnected: true });
  return (
    <Routes>
      <Route path="/" element={<Streams />} />
      <Route path=":streamId/programs" element={<StreamDetails />} />
      <Route path=":streamId/create-program" element={<ProgramForm />} />
      <Route path=":streamId/update-program/:programId" element={<UpdateProgram />} />
    </Routes>
  );
}
export default Programs;
