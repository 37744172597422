import React from 'react';
import { ReactComponent as CloseRoundedFilled } from 'assets/icons/closeRoundedFilled.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { EMPTY_ARRAY } from 'common/constants';
import { imageMimeTypes } from 'common/constants';

import DropTarget, { SimpleDropTarget } from './DropTarget';

export interface MultiImageChangeEvent {
  target: {
    name: string;
    value: {
      files: File[];
      imageURLs: string[];
    };
  };
}
interface MultiImageFileInputProps {
  name: string;
  files?: File[];
  imageURLs?: string[];
  onChange?: (event: MultiImageChangeEvent) => void;
}

function MultiImageFileInput(props: MultiImageFileInputProps) {
  const { name, files = EMPTY_ARRAY, imageURLs = EMPTY_ARRAY, onChange } = props;

  const handleChange = (newFiles: File[]) => {
    if (onChange) {
      const updatedFiles = [...files, ...newFiles];
      onChange({ target: { name, value: { files: updatedFiles, imageURLs } } });
    }
  };

  const removeFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onChange) {
      const updatedFiles = files?.filter((f, idx) => idx !== Number(event.currentTarget.value));
      onChange({ target: { name, value: { files: updatedFiles, imageURLs } } });
    }
  };
  const removeImageURL = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onChange) {
      const updatedImageURLs = imageURLs?.filter((imgURL) => imgURL !== event.currentTarget.value);
      onChange({ target: { name, value: { files, imageURLs: updatedImageURLs } } });
    }
  };

  if (files.length === 0 && imageURLs.length === 0) {
    return (
      <DropTarget
        className="px-4 py-3"
        onChange={handleChange}
        multiple={true}
        allowedMimetypes={imageMimeTypes}
      />
    );
  }
  return (
    <div className="flex flex-wrap overflow-hidden">
      {imageURLs.map((imgURL) => (
        <React.Suspense fallback={<p>...</p>} key={imgURL}>
          <div className="dndFIImgTile" key={imgURL}>
            <img src={imgURL} alt="" />
            <button
              type="button"
              className="iconButton absolute top-0 right-0  bg-white rounded-full"
              value={imgURL}
              onClick={removeImageURL}
            >
              <CloseRoundedFilled />
            </button>
          </div>
        </React.Suspense>
      ))}
      {files.map((file, index) => {
        const imgURL = URL.createObjectURL(file);
        return (
          <div className="dndFIImgTile" key={imgURL}>
            <img src={imgURL} alt="" />
            <button
              type="button"
              className="iconButton absolute top-0 right-0 bg-white rounded-full"
              value={index}
              onClick={removeFile}
            >
              <CloseRoundedFilled />
            </button>
          </div>
        );
      })}
      <div className="dndFIImgTile">
        <SimpleDropTarget
          className="h-full"
          onChange={handleChange}
          multiple={true}
          allowedMimetypes={imageMimeTypes}
        >
          <PlusIcon />
        </SimpleDropTarget>
      </div>
    </div>
  );
}

export default MultiImageFileInput;
