import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevronRight.svg';
import clsx from 'clsx';
import useAppNaviagte from 'common/hooks/useAppNaviagate';

import { ProgramFormSection } from '../types';

interface FormHeaderProps {
  streamId: string;
  currentSection: ProgramFormSection;
  isError: boolean;
  message?: string;
}

function FormHeader(props: FormHeaderProps) {
  const { currentSection, isError, message, streamId } = props;
  const { switchRoute } = useAppNaviagte();
  return (
    <div className="flex py-4 text-18 items-center h-16">
      <p className={clsx('linkButton mr-2', currentSection === 'details' && 'font-medium')}>
        Stream Details
      </p>
      <ChevronRightIcon />
      <p className={clsx('linkButton mr-2', currentSection === 'build' && 'font-medium')}>
        Build program
      </p>
      <p className={clsx('flex-1 ml-6', isError ? 'text-alert-error' : 'text-alert-success')}>
        {message}
      </p>
      <button
        className="linkButton"
        value={`/library/streams/${streamId}/programs`}
        onClick={switchRoute}
      >
        Exit
      </button>
    </div>
  );
}

export default FormHeader;
