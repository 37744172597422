import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as EyeOnOutlineIcon } from 'assets/icons/eyeOnOutline.svg';
import { ReactComponent as MoreHorizontalIcon } from 'assets/icons/moreHorizontal.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { athleteDifficultyLevelLabel } from 'common/constants';
import useApiCallStatus from 'common/hooks/useApiCallStatus';
import useAppNaviagte from 'common/hooks/useAppNaviagate';
import { useDeleteResource } from 'common/remoteResource/resourceCUD';
import {
  ActionColumn,
  Column,
  Content,
  DataTable,
  DataTableHeader,
  DataTableHelperInfo,
  DataTableRow,
  HeaderColumn,
  Pager,
} from 'common/ui/DataTable';
import Menu, { MenuProps } from 'common/ui/Menu';
import Throbber from 'common/ui/Throbber';
import { EMAxios } from 'common/utils/EMService';

import useWorkoutList from './DAL/useWorkoutList';
import { WorkoutModel } from './types';

const RowActions: MenuProps['items'] = [
  { name: 'editWorkout', display: 'View Workout', iconLeft: <EyeOnOutlineIcon /> },
  { name: 'duplicate', display: 'Duplicate', iconLeft: <CopyIcon /> },
  {
    name: 'remove',
    display: 'Remove',
    iconLeft: <TrashIcon />,
    confirm: 'Are you sure to delete this workout?',
  },
];

function WorkoutList() {
  const {
    isLoadingWO,
    status,
    items,
    totalCount,
    filters: { page, perPage, sortByColumn, sortOrder },
    loadWorkouts,
    moveToPage,
    refreshData,
  } = useWorkoutList({ autoLoad: true });

  const { navigate } = useAppNaviagte();
  const { isLoading, setLoading, setSuccess, setError } = useApiCallStatus();
  const { deleteResource, setDeleteInProgress, setDeleteSuccess, setDeleteError, isDeleteIP } =
    useDeleteResource({ url: '/api/admin/stream' });

  const handleClickDataRow = (workout: WorkoutModel) => {
    navigate(`/library/workouts/update-workout/${workout._id}`);
  };

  const handleDuplicate = async (workoutId: string) => {
    setLoading();
    try {
      await EMAxios.request({ method: 'POST', url: `/api/admin/workout/clone/${workoutId}` });
      refreshData();
      setSuccess();
    } catch (e) {
      console.error(e);
      setError();
    }
  };

  const handleDelete = async (workoutId: string) => {
    setDeleteInProgress();
    const result = await deleteResource(workoutId);
    if (result) {
      await loadWorkouts();
      setDeleteSuccess();
    } else {
      setDeleteError('Unable to delete workout');
    }
  };

  const handleMenuAction = (actionName: string, menuData: WorkoutModel) => {
    switch (actionName) {
      case 'editWorkout':
        handleClickDataRow(menuData);
        break;
      case 'remove':
        handleDelete(menuData._id);
        break;
      case 'duplicate':
        handleDuplicate(menuData._id);
        break;
      default:
        break;
    }
  };
  const handleColumnSort = (name: string, currentSort = 'desc') => {
    loadWorkouts({ sortByColumn: name, sortOrder: currentSort === 'desc' ? 'asc' : 'desc' });
  };
  return (
    <DataTable className="bg-gray-50 relative">
      <Content>
        <DataTableHeader className="bg-gray-normal text-white text-sm font-medium ">
          <HeaderColumn
            className="flex-grow-[2]"
            sortOrder={sortByColumn === 'name' ? sortOrder : undefined}
            name="name"
            onClick={handleColumnSort}
          >
            Name
          </HeaderColumn>
          <HeaderColumn className="flex-grow-[2]">Category</HeaderColumn>
          <HeaderColumn
            sortOrder={sortByColumn === 'difficulty_level' ? sortOrder : undefined}
            name="difficulty_level"
            onClick={handleColumnSort}
          >
            Level
          </HeaderColumn>
          <HeaderColumn>Workout Length</HeaderColumn>
          <HeaderColumn
            name="updated_at"
            sortOrder={sortByColumn === 'updated_at' ? sortOrder : undefined}
            onClick={handleColumnSort}
          >
            Last updated
          </HeaderColumn>
          <HeaderColumn>Status</HeaderColumn>
          <ActionColumn />
        </DataTableHeader>
        <DataTableHelperInfo status={status} items={items} refreshData={refreshData} />
        {items.map((singleWorkout) => (
          <DataTableRow
            key={singleWorkout._id}
            className="py-2 border-b border-b-gray-light"
            rowData={singleWorkout}
            onClick={handleClickDataRow}
          >
            <Column className="flex-grow-[2] break-all">{singleWorkout.name}</Column>
            <Column className="flex-grow-[2]">{singleWorkout?.category?.name}</Column>
            <Column>{athleteDifficultyLevelLabel[singleWorkout.difficulty_level]}</Column>
            <Column>{singleWorkout.type === 'quick' ? 'Quick' : 'Normal'}</Column>
            <Column>{singleWorkout.updated_at}</Column>
            <Column>{singleWorkout.is_draft ? 'Disabled' : 'Active'}</Column>
            <ActionColumn>
              <Menu
                triggerElement={<MoreHorizontalIcon />}
                items={RowActions}
                menuData={singleWorkout}
                onItemClick={handleMenuAction}
              />
            </ActionColumn>
          </DataTableRow>
        ))}
      </Content>
      <Pager curPage={page} pageSize={perPage} totalCount={totalCount} moveToPage={moveToPage} />
      {isLoadingWO || isDeleteIP || isLoading ? <Throbber withMask /> : null}
    </DataTable>
  );
}

export default WorkoutList;
