import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';

interface SearchFieldProps extends React.HTMLProps<HTMLInputElement> {
  containerClassName?: string;
  containerProps?: Omit<React.HTMLProps<HTMLDivElement>, 'className'>;
  onSearch?: (searchString: string) => void;
}

function SearchField(props: SearchFieldProps) {
  const { containerProps = {}, containerClassName, value = '', onSearch, ...inputProps } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleOnSearch = () => {
    if (onSearch && inputRef.current) {
      onSearch(inputRef.current.value);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSearch) {
      event.preventDefault();
      onSearch(event.currentTarget.value);
    }
  };

  return (
    <div {...containerProps} className={`relative ${containerClassName}`}>
      <input
        type="text"
        {...inputProps}
        ref={inputRef}
        className="searchField"
        defaultValue={value}
        onKeyDown={handleKeyDown}
      />
      <button
        className="iconButton absolute right-2 top-1/2 -translate-y-1/2"
        type="button"
        tabIndex={-1}
        onClick={handleOnSearch}
      >
        <SearchIcon />
      </button>
    </div>
  );
}

export default SearchField;
