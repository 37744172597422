import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as CloseSmallIcon } from 'assets/icons/closeSmall.svg';
import clsx from 'clsx';

import Throbber from './Throbber';

export interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  isLoading?: boolean;
  isOpen: boolean;
  onClose: <T>(event?: React.MouseEvent<T, globalThis.MouseEvent>) => void;
  children?: React.ReactNode;
  closeButtonGutter?: string;
}

function Modal(props: ModalProps) {
  const {
    isLoading = false,
    isOpen,
    children,
    onClose,
    className,
    closeButtonGutter = 'right-4 top-4',
    ...restProps
  } = props;
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="modalBackdrop bg-gray-normal opacity-25" />
      <div className={clsx(className, 'modal')} {...restProps}>
        {children}
        <button className={clsx('absolute iconButton', closeButtonGutter)} onClick={onClose}>
          <CloseSmallIcon />
        </button>
        {isLoading ? <Throbber withMask /> : null}
      </div>
    </>,
    document.body
  );
}

export default Modal;

interface ModalState<T> {
  isOpen: boolean;
  isLoading?: boolean;
  data?: T;
}
export function useModalConfig<T extends {}>(isOpen: boolean) {
  const [state, setState] = React.useState<ModalState<T>>({ isOpen });
  const openModal = useCallback(() => setState({ isOpen: true }), [setState]);
  const closeModal = () => setState({ isOpen: false, isLoading: false });
  const setModalData = (data: T) => setState({ data, isOpen: true });
  const setLoading = (isLoading = true) => setState({ ...state, isLoading });
  return {
    isModalOpen: state.isOpen,
    isModalLoading: state.isLoading,
    data: state.data,
    openModal,
    setModalData,
    setLoading,
    closeModal,
  };
}
