import { useState } from 'react';

function useToggle(initialValue = false) {
  const [isOpen, setOpen] = useState<boolean>(initialValue);
  const close = () => setOpen(false);
  const open = () => setOpen(true);
  const toggle = () => setOpen(!isOpen);
  return {
    isOpen,
    close,
    open,
    toggle,
  };
}
export default useToggle;
