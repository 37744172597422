import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import Collapsible from 'common/ui/Collapsible';
import { DropTarget, EmptyDropTarget } from 'common/ui/DragnDrop';
import { generateUniqueId } from 'common/utils/general';

import { WorkoutBuildFormData, WorkoutExerciseFormData, WorkoutStageFormData } from '../types';

import { deepCloneExercise } from './mapper';
import { MenuActionsModel } from './menuActions.model';
import WorkoutStageExercise from './WorkoutStageExercise';

interface WorkoutStageProps {
  stageName: WorkoutStageFormData;
  displayText: string;
  exercises: WorkoutExerciseFormData[];
  selectedDuration: string;
  isExpandView: boolean;
  isCopyLocked: boolean;
  getParentValues: UseFormGetValues<WorkoutBuildFormData>;
  moveUp: (unique_id: string, formData: WorkoutBuildFormData) => void;
  moveDown: (unique_id: string, formData: WorkoutBuildFormData) => void;
  onChange: (exercise: WorkoutExerciseFormData[]) => void;
  onViewExercise: (exercise: WorkoutExerciseFormData) => void;
}
type DroppedData = Pick<
  WorkoutExerciseFormData,
  'exercise_id' | 'exercise_name' | 'exercise_image' | 'exercise_type'
>;

const WorkoutStageComponent = forwardRef((props: WorkoutStageProps, ref) => {
  const {
    displayText,
    selectedDuration,
    exercises,
    isExpandView,
    isCopyLocked,
    onChange,
    onViewExercise,
    stageName,
    moveUp,
    moveDown,
    getParentValues,
  } = props;
  const [refreshKey, setRefreshKey] = useState(Date.now());
  useImperativeHandle(ref, () => ({
    clearAllWorkouts(
      previousExercises: Array<WorkoutExerciseFormData>
    ): Array<WorkoutExerciseFormData> {
      const exercisesToDelete = previousExercises
        .filter((x) => x.workout_duration.toString() === selectedDuration && x.stage === stageName)
        .map((x) => x.unique_id);

      const newState = previousExercises.filter(
        (exe) => !exercisesToDelete.includes(exe.unique_id)
      );
      onChange(newState);
      setRefreshKey(Date.now());
      return newState;
    },
    getCurrentExercises(): Array<WorkoutExerciseFormData> {
      return exercises;
    },
  }));
  const handleDrop = (data: DroppedData) => {
    //TODO check if ex has already been added to this stage X duration
    const updateSyncToken = generateUniqueId().substring(1, 5);
    const uniqueExerciseId = generateUniqueId();
    const newExercise: WorkoutExerciseFormData = {
      ...data,
      unique_id: `${uniqueExerciseId}::${selectedDuration}min`,
      updateSyncToken: isCopyLocked ? updateSyncToken : undefined,
      stage: stageName,
      workout_duration: Number(selectedDuration),
      sets: [
        {
          unique_id: generateUniqueId(),
          reps: 5,
          goal_rir: 0,
          rm: 1,
          rest: '00:00',
          reps_descriptor: 'Reps',
        },
      ],
    };
    onChange([...exercises, newExercise]);
    setRefreshKey(Date.now());
  };

  const handleAddSet = (targetExercise: WorkoutExerciseFormData) => {
    const updatedExercises = exercises.map((exercise) => {
      if (
        exercise.unique_id === targetExercise.unique_id ||
        (exercise.updateSyncToken && exercise.updateSyncToken === targetExercise.updateSyncToken)
      ) {
        return {
          ...exercise,
          sets: [
            ...exercise.sets,
            { ...exercise.sets[exercise.sets.length - 1], unique_id: generateUniqueId() },
          ],
        };
      }
      return exercise;
    });
    onChange(updatedExercises);
  };

  const updateExercise = (updatedExercise: WorkoutExerciseFormData) => {
    onChange(
      exercises.map((exercise) =>
        exercise.unique_id === updatedExercise.unique_id ? updatedExercise : exercise
      )
    );
  };
  const removeExercise = (exerciseUniqueId: string) => {
    onChange(exercises.filter((exercise) => exercise.unique_id !== exerciseUniqueId));
  };
  const handleDuplicate = (exercise: WorkoutExerciseFormData) => {
    onChange([
      ...exercises,
      { ...exercise, unique_id: `${generateUniqueId()}::${exercise.workout_duration}min` },
    ]);
  };
  const handleCopyToAllDuration = (exercise: WorkoutExerciseFormData) => {
    const durationsToCopy = [60, 45, 30].filter((d) => d !== exercise.workout_duration);
    const updatedExercise = [...exercises];
    const newExercises = durationsToCopy.map((duration) => ({
      ...deepCloneExercise(exercise),
      unique_id: `${exercise.unique_id.split('::')[0]}::${duration}min`,
      workout_duration: duration,
    }));

    newExercises.forEach((exe) => {
      const index = updatedExercise.findIndex((e) => e.unique_id === exe.unique_id);
      if (index === -1) {
        updatedExercise.push(exe);
      } else {
        updatedExercise[index] = exe;
      }
    });

    onChange(updatedExercise);
  };
  const handleRemoveFromAllDuration = (exercise: WorkoutExerciseFormData) => {
    const exerciseUIdToRemove = [60, 45, 30].map(
      (duration) => `${exercise.unique_id.split('::')[0]}::${duration}min`
    );
    onChange(exercises.filter((exe) => !exerciseUIdToRemove.includes(exe.unique_id)));
  };
  useEffect(() => {
    // We only want this to happen in 1 stage as it affects all of them
    if (isCopyLocked && stageName === 'activation') {
      const justDropped = exercises.filter((exe) => Boolean(exe?.updateSyncToken));
      console.log(justDropped, selectedDuration, stageName);
      if (justDropped.length > 0) {
        const durationsToCopy = [60, 45, 30].filter((d) => d.toString() !== selectedDuration);
        const newExercises = justDropped
          .filter((x) => x.workout_duration.toString() === selectedDuration.toString())
          .map((exer) => [
            {
              ...deepCloneExercise(exer),
              unique_id: `${exer.unique_id.split('::')[0]}::${durationsToCopy[0].toString()}min`,
              workout_duration: durationsToCopy[0],
            },
            {
              ...deepCloneExercise(exer),
              unique_id: `${exer.unique_id.split('::')[0]}::${durationsToCopy[1].toString()}min`,
              workout_duration: durationsToCopy[1],
            },
          ])
          .flat();
        onChange([...exercises, ...newExercises].map(({ updateSyncToken, ...exe }) => exe));
      }
    }
  }, [selectedDuration, exercises, stageName, isCopyLocked, onChange]);

  const applicableExercises = React.useMemo(
    () =>
      exercises.filter(
        (ex) => ex.stage === stageName && ex.workout_duration === Number(selectedDuration)
      ),
    [stageName, selectedDuration, exercises]
  );

  if (applicableExercises.length === 0) {
    return (
      <Collapsible className="mb-4" title={displayText} headerClassName="bg-gray-normal text-white">
        <EmptyDropTarget message="Drag exercise from the left to add." onDrop={handleDrop} />
      </Collapsible>
    );
  }

  const handleMenuAction = (actionName: string, menuData: WorkoutExerciseFormData) => {
    switch (actionName) {
      case MenuActionsModel.Remove:
        removeExercise(menuData.unique_id);
        break;
      case MenuActionsModel.ViewExercise:
        onViewExercise(menuData);
        break;
      case MenuActionsModel.Duplicate:
        handleDuplicate(menuData);
        break;
      case MenuActionsModel.CopyToAll:
        handleCopyToAllDuration(menuData);
        break;
      case MenuActionsModel.RemoveFromAll:
        handleRemoveFromAllDuration(menuData);
        break;
      case MenuActionsModel.MoveDown:
        moveDown(menuData.exercise_id, getParentValues());
        break;
      case MenuActionsModel.MoveUp:
        moveUp(menuData.exercise_id, getParentValues());
        break;
      default:
        break;
    }
  };

  return (
    <Collapsible
      className="mb-4"
      title={`${displayText} (${applicableExercises.length} exercises)`}
      headerClassName="bg-gray-normal text-white"
    >
      <DropTarget
        className="border-2 bg-gray-100 p-4"
        onDrop={handleDrop}
        key={selectedDuration + refreshKey}
      >
        {applicableExercises.map((exercise, index) => (
          <WorkoutStageExercise
            index={index}
            length={applicableExercises.length}
            exercise={exercise}
            selectedDuration={selectedDuration}
            key={exercise.unique_id + exercise.workout_duration}
            onUpdateExercise={updateExercise}
            handleAddSet={handleAddSet}
            handleMenuAction={handleMenuAction}
            isExpandView={isExpandView}
          />
        ))}
      </DropTarget>
    </Collapsible>
  );
});

export default WorkoutStageComponent;
