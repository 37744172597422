import { remoteResourceReducer } from '@cksutils/remote-resource-toolkit';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import authSlice from 'modules/auth/authSlice';

import appSlice from './appSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    app: appSlice,
    remoteResource: remoteResourceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type GetStateFn = () => RootState;
export type AppThunk<ThunkArgs, ReturnType> = ThunkAction<
  ReturnType,
  RootState,
  ThunkArgs,
  Action<string>
>;
