import React from 'react';
import { useNavigate } from 'react-router-dom';

function useAppNaviagte() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const switchRoute = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate(event.currentTarget.value);
  };
  return {
    goBack,
    switchRoute,
    navigate,
  };
}

export default useAppNaviagte;
