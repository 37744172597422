import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { _IdNamePair, OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

const resourceLoader: ResourceLoaderFn<OptionData<string>[]> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/athelete/init', {
      signal: args?.abortSignal,
    });
    return {
      success: data.success,
      data:
        data?.data?.athelete_type?.map?.((option: _IdNamePair) => ({
          id: option._id,
          data: option.name,
        })) || EMPTY_ARRAY,
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useAthleteDropDownOptions = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource } = useRemoteResource<OptionData<string>[]>({
    resourceName: 'athleteOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  return {
    isOptionsLoading: status === 'IN_PROGRESS',
    athleteTypeOptions: resource || EMPTY_ARRAY,
  };
};
export default useAthleteDropDownOptions;
