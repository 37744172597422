import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import AppBar from 'common/layout/AppBar';
import Throbber from 'common/ui/Throbber';
import Athletes from 'modules/athletes/Athletes';
import CurrentUserProfilePage from 'modules/auth/currentUser/CurrentUserProfilePage';
import useCurrentUser from 'modules/auth/currentUser/useCurrentUser';
import DashboardPage from 'modules/dashboard/Dashboard';
import Exercises from 'modules/exercises/Exercises';
import Programs from 'modules/programs';
import Workouts from 'modules/workouts';

import Notifications from "../modules/notifications/Notifications";

function HomePage() {
  const { status } = useAppSelector((state) => state.app);
  useCurrentUser({ autoLoad: true, autoRemove: true, disconnected: true });

  return (
    <Router>
      <AppBar />
      <Routes>
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/athletes/*" element={<Athletes />} />
        <Route path="/notifications/*" element={<Notifications />} />
        <Route path="/library/exercises/*" element={<Exercises />} />
        <Route path="/library/workouts/*" element={<Workouts />} />
        <Route path="/library/streams/*" element={<Programs />} />
        <Route path="/profile/*" element={<CurrentUserProfilePage />} />
      </Routes>
      {status === 'LOADING' && <Throbber withMask />}
    </Router>
  );
}

export default HomePage;
