import React from 'react';

import { OptionData } from '../types';

import MultiSelect, { MultiSelectProps } from './MultiSelect';

function SimpleMultiSelect(props: Omit<MultiSelectProps<string>, 'renderItem' | 'renderSelected'>) {
  const renderItem = (value: string, checked: boolean) => (
    <div className="flex items-center py-4 px-3">
      <input type="checkbox" readOnly checked={checked} tabIndex={-1} className="mr-3 w-5 h-5" />
      <p>{value}</p>
    </div>
  );
  const renderSelected = (selectedValues: OptionData<string>[]) => (
    <>
      <p className="whitespace-nowrap text-ellipsis overflow-hidden">
        {selectedValues.map((option) => option.data).join(', ')}
      </p>
    </>
  );
  return (
    <div className="relative">
      <MultiSelect {...props} renderItem={renderItem} renderSelected={renderSelected} />
    </div>
  );
}

export default SimpleMultiSelect;
