import { formatSecondValue, generateUniqueId, parseTimeStrToSecond } from 'common/utils/general';

import {
  ExercisePayload,
  SaveWorkoutPayload,
  WorkoutExerciseFormData,
  WorkoutFormData,
  WorkoutModel,
} from '../types';

export function mapWorkoutFormDataToPayload(formData: WorkoutFormData): SaveWorkoutPayload {
  const exercises = formData.build.exercises
    .filter((exercise) =>
      formData.details.type === 'quick'
        ? exercise.workout_duration === 15
        : exercise.workout_duration !== 15
    )
    .map<ExercisePayload>((exercise) => ({
      stage: exercise.stage,
      exercise_id: exercise.exercise_id,
      unique_id: exercise.unique_id,
      workout_duration: exercise.workout_duration,
      sets: exercise.sets.map((set) => ({ ...set, rest: parseTimeStrToSecond(set.rest) })),
    }));
  const result: SaveWorkoutPayload = {
    workout: {
      ...formData.details,
      category: formData.details.category || null,
      exercises,
      cover_image: '',
    },
  };
  return result;
}

function sanitizeUniqueId(exercise: WorkoutModel['exercises']['0']) {
  if (exercise.unique_id) {
    return `${exercise.unique_id.split('::')[0]}::${exercise.workout_duration}min`;
  } else {
    return `${generateUniqueId()}::${exercise.workout_duration}min`;
  }
}
export function mapWorkoutModelToFormData(payload: WorkoutModel): WorkoutFormData {
  const { _id, exercises, ...details } = payload;
  return {
    details: {
      name: details.name,
      type: details.type,
      is_draft: details.is_draft,
      cover_image: details.cover_image || '',
      category: details.category._id,
      difficulty_level: details.difficulty_level,
      description: details.description,
    },
    build: {
      name: details.name,
      workout_duration: '60',
      exercises: exercises.filter(Boolean).map((exercise) => ({
        ...exercise,
        unique_id: sanitizeUniqueId(exercise),
        exercise_image: exercise.exercise_image[0],
        sets: exercise.sets.map((set) => ({ ...set, rest: formatSecondValue(set.rest) })),
      })),
    },
  };
}

export function deepCloneExercise(exercise: WorkoutExerciseFormData) {
  return {
    ...exercise,
    sets: exercise.sets.map((set) => ({ ...set })),
  };
}
