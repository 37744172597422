import React from 'react';
import { ReactComponent as CaretDownIcon } from 'assets/icons/caretDown.svg';
import { ReactComponent as CaretUpIcon } from 'assets/icons/caretUp.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevronRight.svg';
import clsx from 'clsx';
import { ApiCallStatus } from 'common/types';
import { range } from 'common/utils/general';

export const DataTable = (props: React.HTMLProps<HTMLDivElement>) => {
  const { children, className, ...restProps } = props;
  return (
    <div
      className={clsx(className, 'h-full overflow-y-hidden overflow-x-auto flex flex-col')}
      {...restProps}
    >
      {children}
    </div>
  );
};
export const Content = (props: React.HTMLProps<HTMLDivElement>) => {
  const { children, className, ...restProps } = props;
  return (
    <div
      className={clsx(className, 'relative flex-1 overflow-y-auto flex flex-col')}
      {...restProps}
    >
      {children}
    </div>
  );
};

export const DataTableHeader = (props: React.HTMLProps<HTMLDivElement>) => {
  const { children, className, ...restProps } = props;
  const scrollBarWidth = window.innerWidth - document.body.clientWidth;
  return (
    <div
      className={clsx(
        className,
        'py-4 flex justify-between  top-0 sticky w-full border-b-2 border-gray-900'
      )}
      style={{ paddingRight: scrollBarWidth }}
      {...restProps}
    >
      {children}
    </div>
  );
};

interface DataTableRowProps<T> extends Omit<React.HTMLProps<HTMLDivElement>, 'onClick'> {
  rowData?: T;
  onClick?: (data: T) => void;
}
export const DataTableRow = <T,>(props: DataTableRowProps<T>) => {
  const { children, className, rowData, onClick, ...restProps } = props;
  const handleOnClick = () => {
    if (onClick && rowData) {
      onClick(rowData);
    }
  };
  return (
    <div
      className={clsx(className, 'flex justify-between hover:bg-teal-light_hover text-sm')}
      onClick={handleOnClick}
      {...restProps}
    >
      {children}
    </div>
  );
};

interface ColumnProps extends React.HTMLProps<HTMLDivElement> {
  autoWidth?: boolean;
}
export const Column = (props: ColumnProps) => {
  const { children, className, autoWidth = true, ...restProps } = props;
  return (
    <div
      className={clsx(className, autoWidth ? 'flex-1' : 'flex-none', 'flex items-center px-2')}
      {...restProps}
    >
      {children}
    </div>
  );
};

export const ActionColumn = ({ className, ...props }: ColumnProps) => {
  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };
  return (
    <Column
      {...props}
      className={clsx(className, 'justify-center w-14')}
      autoWidth={false}
      onClick={handleOnClick}
    />
  );
};

export type SortOrder = 'asc' | 'desc';

interface HeaderColumnProps extends Omit<ColumnProps, 'onClick'> {
  name?: string;
  sortOrder?: SortOrder;
  onClick?: (name: string, currentSort?: SortOrder) => void;
}
export const HeaderColumn = (props: HeaderColumnProps) => {
  const { children, className, name, sortOrder, onClick, ...restProps } = props;
  const handleOnClick = () => {
    if (onClick && name) {
      onClick(name, sortOrder);
    }
  };
  return (
    <Column className={clsx(className, 'cursor-pointer')} {...restProps} onClick={handleOnClick}>
      {children}
      <div>
        {sortOrder === 'asc' ? <CaretUpIcon /> : null}
        {sortOrder === 'desc' ? <CaretDownIcon /> : null}
      </div>
    </Column>
  );
};

interface PagerProps {
  isLoading?: boolean;
  curPage: number;
  pageSize: number;
  totalCount: number;
  moveToPage?: (page: number) => void;
}

export const Pager = (props: PagerProps) => {
  const { isLoading = false, curPage, pageSize, totalCount, moveToPage } = props;

  const startCount = (curPage - 1) * pageSize + 1;
  const endCount = Math.min(totalCount, startCount + pageSize - 1);
  const totalPage = Math.ceil(totalCount / pageSize);
  const pageWindowEnd = Math.min(totalPage, curPage > 3 ? curPage + 2 : 5);
  const pageWindowStart = Math.max(1, pageWindowEnd - 4);

  const goToPage = (pageNumber: number) => {
    if (
      !isLoading &&
      moveToPage &&
      pageNumber > 0 &&
      pageNumber <= totalPage &&
      pageNumber !== curPage
    ) {
      moveToPage(pageNumber);
    }
  };

  const onClickPageButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    const pageNumber = Number(event.currentTarget.value);
    goToPage(pageNumber);
  };

  const goPrevious = () => goToPage(curPage - 1);
  const goNext = () => goToPage(curPage + 1);
  return (
    <div className="pageInfo flex items-center gap-6 p-6 text-sm">
      <div className="flex-1" />
      <p>
        Showing {startCount}-{endCount} of {totalCount}
      </p>
      <div className="pager flex items-center gap-2">
        <button disabled={curPage === 1} className="pagerButton" onClick={goPrevious} type="button">
          <ChevronLeftIcon />
        </button>
        {pageWindowStart > 1 ? (
          <button className="pagerButton" type="button">
            ...
          </button>
        ) : null}
        {range(pageWindowStart, pageWindowEnd).map((pageIndex) => (
          <button
            className={clsx('pagerButton', curPage === pageIndex && 'bg-teal-light_active')}
            value={pageIndex}
            key={pageIndex}
            onClick={onClickPageButton}
            type="button"
          >
            {pageIndex}
          </button>
        ))}
        {pageWindowEnd < totalPage ? (
          <button className="pagerButton iconButton" type="button">
            ...
          </button>
        ) : null}
        <button
          className="pagerButton"
          onClick={goNext}
          disabled={curPage === totalPage}
          type="button"
        >
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export const PagerSmall = (props: PagerProps) => {
  const { isLoading = false, curPage, pageSize, totalCount, moveToPage } = props;

  const startCount = (curPage - 1) * pageSize + 1;
  const endCount = Math.min(totalCount, startCount + pageSize - 1);
  const totalPage = Math.ceil(totalCount / pageSize);

  const goToPage = (pageNumber: number) => {
    if (
      !isLoading &&
      moveToPage &&
      pageNumber > 0 &&
      pageNumber <= totalPage &&
      pageNumber !== curPage
    ) {
      moveToPage(pageNumber);
    }
  };

  const goPrevious = () => goToPage(curPage - 1);
  const goNext = () => goToPage(curPage + 1);
  return (
    <div className="pageInfo flex items-center gap-6 text-sm">
      <div className="pager flex items-center gap-2">
        <button disabled={curPage === 1} className="pagerButton" onClick={goPrevious} type="button">
          <ChevronLeftIcon />
        </button>
        <p>
          {startCount}-{endCount} of {totalCount}
        </p>
        <button
          className="pagerButton"
          onClick={goNext}
          disabled={curPage === totalPage}
          type="button"
        >
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

export const DataTableHelperInfo = (props: {
  status: ApiCallStatus;
  items: any[];
  refreshData: () => Promise<any>;
}) => {
  const { status, refreshData, items } = props;
  const onRefresh = () => {
    refreshData();
  };

  if (status === 'SUCCESS' && items.length === 0) {
    return <p className="flexCenter flex-1">No items...</p>;
  } else if (status === 'INIT') {
    return <p className="flexCenter flex-1">Data list...</p>;
  } else if (status === 'IN_PROGRESS' && items.length === 0) {
    return <p className="flexCenter flex-1">Loading data. Please wait...</p>;
  } else if (status === 'ERROR') {
    return (
      <div className="flex-1 flexCenter h-40 flex-col">
        <p>Some error occured while fetching data.</p>
        <button className="outlined_primary h-8 w-32" onClick={onRefresh}>
          Reload
        </button>
      </div>
    );
  }
  return null;
};
