import { OptionData, ResourceFilters } from './types';

export const API_BASE_URL = process.env.REACT_APP_ENV === 'staging'
  ? process.env.REACT_APP_API_TEST_BASE_URL
  : process.env.REACT_APP_API_PROD_BASE_URL
  || 'http://localhost:3030';
export const SESSION_TOKEN_KEY = 'EM_SESSION_TOKEN';
export const SESSION_EMAIL_KEY = 'EM_SESSION_EMAIL_ID';

export const EMAIL_REGX = /^\w+([.-/+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const EMPTY_ARRAY = Object.freeze([]) as [];
export const EMPTY_OBJECT = Object.freeze({}) as {};

export const athleteDifficultyLevelLabel = {
  novice: 'Novice',
  intermediate: 'Intermediate',
  advanced: 'Advanced',
  'N/A': 'N/A',
};

/** To be used in form dropdown */
export const athleteDifficultyLevelOptions: OptionData<string>[] = [
  { id: 'novice', data: athleteDifficultyLevelLabel.novice },
  { id: 'intermediate', data: athleteDifficultyLevelLabel.intermediate },
  { id: 'advanced', data: athleteDifficultyLevelLabel.advanced },
  { id: 'N/A', data: athleteDifficultyLevelLabel['N/A'] },
];

/** To be used in form dropdown */
export const repsDescriptorOptions: OptionData<string>[] = [
  { id: 'Seconds', data: 'Seconds' },
  { id: 'Reps', data: 'Reps' },
];

export const imageMimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
export const videoMimeTypes = ['video/mp4', 'video/webm'];
export const deafultQueryParams: ResourceFilters = {
  page: 1,
  perPage: 20,
  sortByColumn: 'updated_at',
  sortOrder: 'desc',
};
