import { Navigate, Route, Routes } from 'react-router-dom';
import { useResolvedPath } from 'react-router-dom';

import usePolicyTC from '../usePolicyTC';

import PrivacyPolicy from './PrivacyPolicy';
import SettingsNav from './SettingsNav';
import TermsConditions from './TermsConditions';

function SettingsPage() {
  const { pathname } = useResolvedPath('.');
  usePolicyTC({ autoLoad: true, disconnected: true });

  return (
    <div className="pl-24 max-w-screen-lg pr-5 h-appContent flex-1 flex flex-col">
      <h1 className="mt-12 text-2xl font-semibold">Settings</h1>
      <SettingsNav basePath={pathname} />
      <Routes>
        <Route path="" element={<Navigate to="privacy-policy" />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
      </Routes>
    </div>
  );
}

export default SettingsPage;
