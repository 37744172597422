import { athleteDifficultyLevelLabel } from 'common/constants';
import { _IdNamePair } from 'common/types';
import * as Z from 'zod';

export const ProgramDetailsSchema = Z.object({
  name: Z.string().trim().min(1, { message: 'Please enter program title.' }),
  image: Z.union([Z.string(), Z.instanceof(File)]).nullable(),
  image_overlay: Z.union([Z.string(), Z.instanceof(File), Z.null()])
    .nullable()
    .optional(),
  description: Z.string().trim().min(1, { message: 'Please enter description.' }),
  equipments: Z.array(Z.string()),
  display_tags: Z.array(Z.string()),
  user_access: Z.array(Z.string()),
  addon_purchase_id: Z.string().nullable().default(null),
  next_program_id: Z.string().trim().min(1, { message: 'Please enter next program.' }),
  type: Z.enum(['quick', 'normal'], { required_error: 'Please select workout type.' }),
  title_hidden: Z.boolean().default(false),
});
export const ProgramBuildSchema = Z.object({
  name: Z.string().trim().min(1, { message: 'Please enter program title.' }),
  workouts: Z.array(
    Z.object({
      _id: Z.string().min(1),
      id: Z.string(),
      uniqueId: Z.string(),
      name: Z.string(),
      cover_image: Z.string(),
      category: Z.string(),
      difficulty_level: Z.enum(['novice', 'intermediate', 'advanced', 'N/A'], {
        required_error: 'Select level.',
      }),
      workout_length: Z.string(),
    })
  ).min(1, { message: 'Please add atleast one workout.' }),
});

export type ProgramDetailsFormData = Z.infer<typeof ProgramDetailsSchema>;
export type ProgramBuildFormData = Z.infer<typeof ProgramBuildSchema>;
export type ProgramFormData = {
  details: ProgramDetailsFormData;
  build: ProgramBuildFormData;
};
export type ProgramFormSection = keyof ProgramFormData;

export type SaveProgramPayload = {
  program_data: Omit<ProgramFormData['details'], 'image'> & {
    image: string | null;
    image_overlay: string | null;
    is_draft: boolean;
    workouts: string[];
  };
};

export type ProgramModel = {
  _id: string;
  name: string;
  description: string;
  next_program_id: string;
  display_tags: string[];
  user_access: string[];
  addon_purchase_id: string;
  image: string | null;
  image_overlay: string | null;
  title_hidden: boolean;
  equipments: _IdNamePair[];
  type: 'normal' | 'quick';
  workouts: Array<{
    _id: string;
    name: string;
    cover_image: string | null;
    category: { name: string };
    difficulty_level: keyof typeof athleteDifficultyLevelLabel;
    workout_length: string;
    type?: string;
    exercises?: any;
  }>;
  updated_at: string;
  is_draft: boolean;
};
