import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BackIcon } from 'assets/icons';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { athleteDifficultyLevelLabel } from 'common/constants';
import useAppNaviagte from 'common/hooks/useAppNaviagate';

import useStreamList from './streams/useStreams';
import StreamPrograms from './StreamPrograms';

function StreamDetails() {
  const { streamId = '' } = useParams();
  const { items: streams } = useStreamList();
  const { switchRoute } = useAppNaviagte();

  const streamData = useMemo(
    () => streams.find((item) => item._id === streamId),
    [streamId, streams]
  );
  if (!streamData) {
    return (
      <div className="contentArea h-appContent">
        <div className="h-full flexCenter flex-col">
          <p className="mb-2">No Stream found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="contentArea h-appContent">
      <div className="flex items-center h-pageHeader">
        <button
          className="outlined_primary w-20 h-10 mr-4"
          value="/library/streams"
          onClick={switchRoute}
        >
          <BackIcon />
          Back
        </button>
        <h1 className="flex-1 font-semibold">
          {[
            streamData.athelete_type.name,
            streamData.workout_group.name,
            athleteDifficultyLevelLabel[streamData.difficulty_level],
          ].join(' . ')}
        </h1>
        <button
          className="primary px-4 ml-4"
          value={`/library/streams/${streamId}/create-program`}
          onClick={switchRoute}
        >
          <PlusIcon /> Add Program
        </button>
      </div>
      <div className="h-pageContent pb-8">
        <div className="h-full overflow-x-auto">
          <StreamPrograms streamId={streamId} />
        </div>
      </div>
    </div>
  );
}

export default StreamDetails;
