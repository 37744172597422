import React from 'react';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevronRight.svg';
import clsx from 'clsx';
import useAppNaviagte from 'common/hooks/useAppNaviagate';

import useWorkoutList from '../DAL/useWorkoutList';
import { WorkoutFormSection } from '../types';

interface FormHeaderProps {
  currentSection: WorkoutFormSection;
  isError: boolean;
  message?: string;
  workoutSavedCount: number;
  resetSaveCount: () => void;
}

function FormHeader(props: FormHeaderProps) {
  const { currentSection, isError, message, resetSaveCount, workoutSavedCount } = props;
  const { loadWorkouts } = useWorkoutList();
  const { switchRoute } = useAppNaviagte();

  const handleExit = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (workoutSavedCount > 0) {
      resetSaveCount();
      loadWorkouts({ page: 1 });
    }
    switchRoute(event);
  };
  return (
    <div className="flex py-4 text-18 items-center h-16">
      <p className={clsx('linkButton mr-2', currentSection === 'details' && 'font-medium')}>
        Workout Details
      </p>
      <ChevronRightIcon />
      <p className={clsx('linkButton mr-2', currentSection === 'build' && 'font-medium')}>
        Build workout
      </p>
      <p className={clsx('flex-1 ml-6', isError ? 'text-alert-error' : 'text-alert-success')}>
        {message}
      </p>
      <button className="linkButton" value="/library/workouts" onClick={handleExit}>
        Exit
      </button>
    </div>
  );
}

export default FormHeader;
