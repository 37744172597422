import React from 'react';

import EnterCodeForm from './EnterCodeForm';
import EnterEmailForm from './EnterEmailForm';
import { ForgetPasswordData } from './forgetpasswordTypes';
import ResetPasswordForm from './ResetPasswordForm';

function ForgetPassword() {
  const [state, setState] = React.useState<Partial<ForgetPasswordData>>({});

  const onEnterEmailId = (params: Pick<ForgetPasswordData, 'emailId'>) => {
    setState({ emailId: params.emailId });
  };
  const onEnterCode = (params: Pick<ForgetPasswordData, 'code'>) => {
    setState({ ...state, code: params.code });
  };

  if (state.emailId && state.code) {
    return <ResetPasswordForm emailId={state.emailId} code={state.code} />;
  } else if (state.emailId) {
    return <EnterCodeForm onEnterCode={onEnterCode} emailId={state.emailId} />;
  }
  return <EnterEmailForm onEnterEmail={onEnterEmailId} />;
}

export default ForgetPassword;
