import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_OBJECT } from 'common/constants';
import { ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type DashboardMetrics = {
  newUsersCount: number;
  subscribedUsers: number;
  workoutCompleted: number;
};
const emptyMetrics = {
  newUsersCount: 0,
  subscribedUsers: 0,
  workoutCompleted: 0,
};
const resourceLoader: ResourceLoaderFn<DashboardMetrics> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/dashboard/getStats', {
      signal: args?.abortSignal,
    });
    return {
      success: data.success,
      data: {
        newUsersCount: data?.data?.newUsersCount || 0,
        subscribedUsers: data?.data?.subscribedUsers || 0,
        workoutCompleted: data?.data?.workoutCompleted || 0,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useDashboardMetrics = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource = emptyMetrics } = useRemoteResource<DashboardMetrics>({
    resourceName: 'dashboardMetrics',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  return {
    isMetricsLoading: status === 'IN_PROGRESS',
    ...resource,
  };
};
export default useDashboardMetrics;
