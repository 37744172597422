import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import UserAccountPage from './account/UserAccountPage';
import SettingsPage from './settings/SettingsPage';
import DebugMode from './DebugMode';
import Sidebar from './Sidebar';
import usePolicyTC from './usePolicyTC';

interface CurrentUserProfilePageProps {}

function CurrentUserProfilePage(props: CurrentUserProfilePageProps) {
  usePolicyTC({ autoRemove: true, disconnected: true });
  return (
    <div className="flex h-appContent overflow-auto">
      <Sidebar />
      <Routes>
        <Route path="" element={<Navigate to="settings" />} />
        <Route path="account" element={<UserAccountPage />} />
        <Route path="settings/*" element={<SettingsPage />} />
        <Route path="debugmode" element={<DebugMode />} />
      </Routes>
    </div>
  );
}

export default CurrentUserProfilePage;
