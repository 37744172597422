import React from 'react';
import { useParams } from 'react-router-dom';

import useWorkoutList from '../DAL/useWorkoutList';

import { mapWorkoutModelToFormData } from './mapper';
import WorkoutForm from './SaveWorkout';

function UpdateWorkOutPage() {
  const { workoutId } = useParams();
  const { items } = useWorkoutList();

  const workoutFormData = React.useMemo(() => {
    const workout = items.find((item) => item._id === workoutId);
    return workout ? mapWorkoutModelToFormData(workout) : null;
  }, [items, workoutId]);
  if (!workoutFormData) {
    return <h3>No workout found</h3>;
  }
  return <WorkoutForm defaultWorkoutFormData={workoutFormData} workoutId={workoutId} />;
}

export default UpdateWorkOutPage;
