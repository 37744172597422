import { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import Throbber from 'common/ui/Throbber';
import { authActions } from 'modules/auth/authSlice';

import * as AuthService from './authService';

function CheckSessionPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    AuthService.checkForSession().then((hasValidSession) =>
      hasValidSession
        ? dispatch(authActions.setStatus({ status: 'LOGGED_IN' }))
        : dispatch(authActions.setStatus({ status: 'NO_SESSION' }))
    );
  });
  return (
    <div className="h-screen flex justify-center items-center">
      <Throbber message="Please wait..." />
    </div>
  );
}

export default CheckSessionPage;
