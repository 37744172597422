import { useRef, useState } from 'react';
import { produce } from 'immer';

function useMultiPageForm<Type>(initialFormData: Type, defaultSection: keyof Type) {
  const formDataRef = useRef<Type>(initialFormData);
  const [currentSection, setCurrentSection] = useState<keyof Type>(defaultSection);

  const setFormData = <Key extends string & keyof Type>(
    sectionName: Key,
    formData: Partial<Type[Key]>
  ) => {
    const currentFormData = formDataRef.current;
    formDataRef.current = produce(currentFormData, (draft: Type) => {
      draft[sectionName] = {
        ...currentFormData[sectionName],
        ...formData,
      };
    });

  };

  const replaceFormData = (updatedFormData: Type, resetSection = false) => {
    formDataRef.current = updatedFormData;
    if (resetSection) {
      setCurrentSection(defaultSection);
    }
  };

  return {
    currentSection,
    formData: formDataRef.current,
    replaceFormData,
    setFormData,
    setCurrentSection,
  };
}

export default useMultiPageForm;
