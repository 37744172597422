import React from 'react';
import { useParams } from 'react-router-dom';
import Throbber from 'common/ui/Throbber';
import { generateUniqueId } from 'common/utils/general';

import { ProgramFormData, ProgramModel } from '../types';
import usePrograms from '../usePrograms';

import ProgramForm from './ProgramForm';

const mapProgramToFormData = (program: ProgramModel): ProgramFormData => {
  return {
    details: {
      name: program.name,
      next_program_id: program.next_program_id,
      display_tags: program.display_tags,
      user_access: program.user_access,
      image: program.image,
      image_overlay: program.image_overlay,
      description: program.description,
      addon_purchase_id: program.addon_purchase_id,
      equipments: program.equipments.map((e) => e._id),
      type: program.type,
      title_hidden: program.title_hidden,
    },
    build: {
      name: program.name,
      workouts: program.workouts.map((wo) => {
        const uniqueId = generateUniqueId();
        return {
          _id: wo._id,
          name: wo.name,
          cover_image: wo.cover_image || '',
          category: wo.category.name,
          difficulty_level: wo.difficulty_level,
          workout_length: wo.type || '',
          uniqueId,
          id: uniqueId,
        };
      }),
    },
  };
};

function UpdateProgram() {
  const { streamId = '', programId } = useParams();
  const { items, isLoading } = usePrograms({ streamId, autoLoad: true });

  console.log(items);
  const programFormData = React.useMemo(() => {
    const program = items.find((item) => item._id === programId);
    return program ? mapProgramToFormData(program) : null;
  }, [items, programId]);

  if (isLoading) {
    return <Throbber withMask />;
  }

  if (!programFormData) {
    return <h3>No Program found</h3>;
  }

  return <ProgramForm defaultFormData={programFormData} programId={programId} />;
}

export default UpdateProgram;
