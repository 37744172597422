import { NavLink } from 'react-router-dom';

import LibraryMenu from './LibraryMenu';

function TopNavigation() {
  return (
    <ul className="tab text-gray-3">
      <li className="mr-8">
        <NavLink className="navLink" to="/dashboard">
          Dashboard
        </NavLink>
      </li>
      <li className="mr-8">
        <NavLink className="navLink" to="/athletes">
          Athletes
        </NavLink>
      </li>
      <li className="mr-8">
        <LibraryMenu />
      </li>
      <li className="mr-8">
        <NavLink className="navLink" to="/notifications">
          Notifications
        </NavLink>
      </li>
    </ul>
  );
}

export default TopNavigation;
