import { ResourceLoaderFn, useRemoteResource } from '@cksutils/remote-resource-toolkit';
import { deafultQueryParams, EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { ResourceFilters, ResourceHookParams } from 'common/types';
import { formatISODate } from 'common/utils/dateTimeUtils';
import { EMAxios } from 'common/utils/EMService';
import produce from 'immer';

import { StreamModel } from './types';

type StreamList = { items: StreamModel[]; totalCount: number };
const resourceLoader: ResourceLoaderFn<StreamList, ResourceFilters> = async (args) => {
  try {
    const queryParams = {
      ...deafultQueryParams,
      ...args.params,
    };
    const { data } = await EMAxios.get('/api/admin/stream', {
      params: queryParams,
      signal: args?.abortSignal,
    });
    const streams = data?.data?.streams || EMPTY_ARRAY;
    const updatedStreams = streams.map(
      produce((stream: StreamModel) => {
        stream.updated_at = formatISODate(stream.updated_at);
      })
    );

    return {
      success: data.success,
      data: {
        items: updatedStreams,
        totalCount: data?.data?.total_count!,
      },
      params: queryParams,
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useStreams = (params: ResourceHookParams = EMPTY_OBJECT) => {
  const {
    status,
    resource,
    resourceParams = deafultQueryParams,
    loadData,
  } = useRemoteResource<StreamList, ResourceFilters>({
    resourceName: 'streamList',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  const moveToPage = async (page: number) => {
    return loadData({ ...resourceParams, page });
  };

  const searchStream = (searchText: string) => loadData({ searchText });

  return {
    isLoadingStream: status === 'IN_PROGRESS',
    status,
    items: resource?.items || EMPTY_ARRAY,
    totalCount: resource?.totalCount || 0,
    filters: resourceParams,
    loadStreams: loadData,
    refreshData: loadData,
    moveToPage,
    searchStream,
  };
};

export default useStreams;
