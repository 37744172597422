import { EMAxios } from 'common/utils/EMService';

import { ExerciseFormData } from '../types';

export const saveExerciseService = async (payload: ExerciseFormData, id?: string) => {
  const config = {
    method: 'POST',
    url: '/api/admin/exercise',
  };
  if (id) {
    config.method = 'PUT';
    config.url = `/api/admin/exercise/${id}`;
  }
  try {
    await EMAxios.request({ ...config, data: { exercise: payload } });
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};
