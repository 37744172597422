import { EMAIL_REGX } from 'common/constants';
import * as Z from 'zod';

export const NotificationFormSchema = Z.object({
    data: Z.object({
        title: Z.string().trim().min(1, {message: 'First name required.'}),
        body: Z.string().trim().min(1, {message: 'Last name required.'}),
        emails: Z.array(
          Z.string()
            .trim()
            .min(1, {message: 'Email is required.'})
            .regex(EMAIL_REGX, {message: 'Enter valid email.'})
        ),
    })
});
export type NotificationFormData = Z.infer<typeof NotificationFormSchema>;
