import { ExerciseFormData, ExerciseModel } from '../types';

export const mapExerciseModelToFormData = (singleExercise: ExerciseModel): ExerciseFormData => ({
  name: singleExercise?.name,
  focus: singleExercise?.focus?.map?.((pm: { _id: string }) => pm?._id),
  primary_muscle_group: singleExercise?.primary_muscle_group?.map((pm: { _id: string }) => pm?._id),
  type: singleExercise?.type,
  difficulty_level: singleExercise?.difficulty_level,
  equipment: singleExercise?.equipment.map((pm: { _id: string }) => pm?._id),
  video: singleExercise?.video,
  image: singleExercise?.image,
  instruction: singleExercise?.instruction,
  is_draft: singleExercise.is_draft,
});
