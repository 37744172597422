import { useAppSelector } from 'app/hooks';
import clsx from 'clsx';
import CheckSessionPage from 'modules/auth/CheckSessionPage';

import HomePage from './Homepage';
import PublicRoutes from './PublicRoutes';

function EMAdminApp() {
  const { status } = useAppSelector((state) => state.auth);

  if (status === 'NO_SESSION') {
    return <PublicRoutes />;
  } else if (status === 'LOGGED_IN') {
    return <HomePage />;
  }
  return <CheckSessionPage />;
}

function App() {
  const { debugMode } = useAppSelector((state) => state.app);

  return (
    <div className={clsx(debugMode === 'on' ? 'debugModeON' : 'debugModeOFF')}>
      <EMAdminApp />
    </div>
  );
}

export default App;
