import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import SearchField from 'common/ui/form/SearchField';
import { useModalConfig } from 'common/ui/Modal';

import useAthleteList from './DAL/useAthleteList';
import useAthleteDropDownOptions from './DAL/useAthleteOptions';
import AddEditAtheletModal from './AddEditAthletModal';
import AtheletList from './AthletList';
import { AthleteFormData, AthleteModel } from './types';

function Athletes() {
  const { isModalOpen, data, openModal, setModalData, closeModal } = useModalConfig<{
    athleteId: string;
    defaultFormData: AthleteFormData;
  }>(false);
  const { searchAthlete, filters } = useAthleteList({ autoRemove: true });
  useAthleteDropDownOptions({ autoRemove: true, disconnected: true });

  const onClickDataRow = (athlete: AthleteModel) => {
    setModalData({
      athleteId: athlete._id,
      defaultFormData: {
        atheleteData: {
          first_name: athlete.first_name,
          last_name: athlete.last_name,
          email: athlete.email,
          athelete_type: athlete.athelete_type._id,
          profile_image: athlete.profile_image,
        },
      },
    });
  };
  return (
    <div className="contentArea h-appContent">
      <div className="flex items-center h-pageHeader">
        <h1 className="flex-1 font-semibold text-2xl">Athletes</h1>
        <SearchField placeholder="Search" onSearch={searchAthlete} value={filters.searchText} />
        <button className="primary px-4 ml-4" onClick={openModal} disabled>
          <PlusIcon /> Add Athlete
        </button>
      </div>
      <div className="h-pageContent pb-4">
        <AtheletList onClickDataRow={onClickDataRow} />
      </div>
      <AddEditAtheletModal isOpen={isModalOpen} onClose={closeModal} {...data} />
    </div>
  );
}

export default Athletes;
