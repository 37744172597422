import React from 'react';
import { ReactComponent as MenuDuoIcon } from 'assets/icons/menuDuo.svg';
import { PagerSmall } from 'common/ui/DataTable';
import SearchField from 'common/ui/form/SearchField';
import useExerciseList from 'modules/exercises/DAL/useExerciseList';
import { ExerciseModel } from 'modules/exercises/types';

function ExercisePicker() {
  const {
    items,
    totalCount,
    filters: { page, perPage, searchText },
    moveToPage,
    loadExercises,
  } = useExerciseList({ autoLoad: true, autoLoadParams: { isDraft: false } });
  const searchExercise = (searchText: string) => {
    loadExercises({ searchText: searchText || undefined });
  };

  return (
    <div className="px-5">
      <div className="sticky top-0 bg-gray-light py-4">
        <div className="flex items-center mb-2">
          <label className="flex-1">Find an Exercise</label>
          <PagerSmall
            curPage={page}
            pageSize={perPage}
            totalCount={totalCount}
            moveToPage={moveToPage}
          />
        </div>
        <SearchField placeholder="Search" onSearch={searchExercise} value={searchText} />
      </div>
      <div>
        {items.map((exercise) => (
          <ExerciseCard exercise={exercise} key={exercise._id} />
        ))}
      </div>
    </div>
  );
}

export default ExercisePicker;

function ExerciseCard(props: { exercise: ExerciseModel }) {
  const { exercise } = props;
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        exercise_name: exercise.name,
        exercise_id: exercise._id,
        exercise_image: exercise.image[0],
        exercise_type: exercise.type,
      })
    );
  };
  return (
    <div
      className="exerciseCard flex items-center mb-2 border border-gray-5 bg-gray-100 pr-2"
      draggable
      onDragStart={handleDragStart}
    >
      <img src={exercise.image[0]} alt="" className="w-20 h-20 object-cover" />
      <p className="flex-1 px-2">{exercise.name}</p>
      <MenuDuoIcon />
    </div>
  );
}
