import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
  status: 'LOADING' | 'READY';
  debugMode: 'on' | 'off';
}

const initialState: AppState = {
  status: 'READY',
  debugMode: 'off',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<{ status: AppState['status'] }>) => {
      state.status = action.payload.status;
    },
    setDebugMode: (state, action: PayloadAction<{ debugMode: AppState['debugMode'] }>) => {
      state.debugMode = action.payload.debugMode;
    },
  },
});

export const appActions = appSlice.actions;
export default appSlice.reducer;
