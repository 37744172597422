import { NavLink } from 'react-router-dom';
import { ReactComponent as AccountIcon } from 'assets/icons/accountIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settingsIcon.svg';
import { GetNavLinkClassName } from 'common/types';

import ProfileImage from './ProfileImage';

function AccountSidebar() {
  const getNavlinkClassNames: GetNavLinkClassName = ({ isActive }) => {
    const baseClasses = 'flex items-center py-4 px-14 hover:bg-teal-light_hover border-l-4 ';
    return isActive ? `${baseClasses} border-l-teal-normal bg-teal-light_active` : baseClasses;
  };

  return (
    <div className="h-appContent bg-gray-cultured w-70 pt-20">
      <ProfileImage />
      <ul className="mt-14">
        <li>
          <NavLink to="/profile/account" className={getNavlinkClassNames}>
            <AccountIcon />
            <p className="ml-3">Account</p>
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile/settings" className={getNavlinkClassNames}>
            <SettingsIcon />
            <p className="ml-3">Settings</p>
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile/debugmode" className={getNavlinkClassNames}>
            <SettingsIcon />
            <p className="ml-3">Debug Mode</p>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default AccountSidebar;
