import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_OBJECT } from 'common/constants';
import { ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type PolicyResource = { privacyPolicy: string; termsCondition: string };

const resourceLoader: ResourceLoaderFn<PolicyResource> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/profile/policy', { signal: args?.abortSignal });
    return {
      success: data.success,
      data: {
        privacyPolicy: data?.data?.policy[0].privacyPolicy,
        termsCondition: data?.data?.policy[0].termsAndConditions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const defaultPolicyData: PolicyResource = {
  privacyPolicy: '',
  termsCondition: '',
};
function usePolicyTC(params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) {
  const {
    status,
    resource = defaultPolicyData,
    loadData,
  } = useRemoteResource<PolicyResource>({
    resourceName: 'privacyTermsCondition',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  return {
    isLoading: status === 'IN_PROGRESS',
    resource,
    loadPolicy: loadData,
  };
}

export default usePolicyTC;
