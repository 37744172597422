import React from 'react';

function LoginPageLayout(props: { children: React.ReactNode }) {
  return (
    <div className="flex h-screen items-center bg-[url('./assets/images/login_bg.png')] bg-cover md:bg-contain bg-no-repeat bg-gray-cultured relative">
      <img
        src="/static/media/LogoFull.png"
        className="absolute left-20 bottom-20 w-60"
        alt="sidebar logo"
      />
      <div className="w-full flex">
        <div className="hidden lg:block">
          <img
            src="/static/media/login_bg.png"
            className="h-screen invisible"
            alt="layout placeholder"
          />
        </div>
        <div className="flex flex-1 items-center justify-center lg:justify-start">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default LoginPageLayout;
