import React from 'react';
import InputMask from 'react-input-mask';
import { ReactComponent as CloseRoundFilledIcon } from 'assets/icons/closeRoundFilled.svg';

import {repsDescriptorOptions} from "../../../common/constants";
import {SimpleSingleSelect} from "../../../common/ui/form/dropdown/SingleSelect";
import {FormCompEvent} from "../../../common/ui/form/types";
import {ExerciseSetFormData, UpdateSetValueFn} from '../types';

type WorkoutExerciseSetField = keyof ExerciseSetFormData;

interface ExerciseSetRowProps {
  set: ExerciseSetFormData;
  index: number;
  exerciseType: 'bodyWeight' | 'weighted';
  onUpdateSetValue: UpdateSetValueFn;
  onRemoveSet: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function ExerciseSetRow(props: ExerciseSetRowProps) {
  const { set, index, onUpdateSetValue, onRemoveSet } = props;
  const handleUpdateValue = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputVal = Number(event.target.value);
    const inputNumberVal = isNaN(inputVal) ? 0 : inputVal;
    event.target.value = `${inputNumberVal}`;
    onUpdateSetValue(index, event.target.name as WorkoutExerciseSetField, inputNumberVal);
  };
  const handleUpdateDropdown = (event: FormCompEvent) => {
    const inputVal = String(event.target.value);
    event.target.value = `${inputVal}`;
    onUpdateSetValue(index, event.target.name as WorkoutExerciseSetField, inputVal);
  };
  const handleUpdateRest = (event: React.FocusEvent<HTMLInputElement>) => {
    onUpdateSetValue(
      index,
      event.target.name as WorkoutExerciseSetField,
      event.target.value.trim()
    );
  };

  return (
    <tr key={set.unique_id} className="show-items-on-hover">
      <td className="relative">
        <button
          className="absolute iconButton -left-3 text-alert-error bg-white rounded-full visible-on-hover"
          type="button"
          value={set.unique_id}
          onClick={onRemoveSet}
        >
          <CloseRoundFilledIcon />
        </button>
        {index + 1}
      </td>
      <td>
        <input
          className="noBorder narrow w-14 text-center compact p-4"
          type="text"
          name="reps"
          onBlur={handleUpdateValue}
          defaultValue={set.reps}
        />
      </td>
          <SimpleSingleSelect
            name="reps_descriptor"
            options={repsDescriptorOptions}
            onChange={handleUpdateDropdown}
            value={set.reps_descriptor}
          />
      <td>
        <input
          className="noBorder narrow w-14 text-center compact p-4"
          type="text"
          name="goal_rir"
          onBlur={handleUpdateValue}
          defaultValue={set.goal_rir}
        />
      </td>
      <td>
        <InputMask
          name="rest"
          type="text"
          className="noBorder narrow w-28 text-center compact p-4"
          onBlur={handleUpdateRest}
          defaultValue={set.rest}
          mask="99:99"
          maskChar="0"
          alwaysShowMask={true}
        />
      </td>
    </tr>
  );
}

export default ExerciseSetRow;
