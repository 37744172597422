import React from 'react';
import {CopyIcon, EyeOnOutlineIcon, MoreHorizontalIcon, MoveDownIcon, MoveUpIcon, TrashIcon} from 'assets/icons';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import clsx from 'clsx';
import Menu, { MenuProps } from 'common/ui/Menu';

import { UpdateSetValueFn, WorkoutExerciseFormData } from '../types';

import ExerciseSetRow from './ExerciseSetRow';
import {MenuActionsModel} from "./menuActions.model";


interface WorkoutExerciseProps {
  length: number;
  index: number;
  exercise: WorkoutExerciseFormData;
  selectedDuration: string;
  isExpandView: boolean;
  onUpdateExercise: (updatedExercise: WorkoutExerciseFormData) => void;
  handleAddSet: (exercise: WorkoutExerciseFormData) => void;
  handleMenuAction: (actionName: string, menuData: WorkoutExerciseFormData) => void;
}

const RowActions: MenuProps['items'] = [
  { name: MenuActionsModel.ViewExercise, display: 'View Exercise', iconLeft: <EyeOnOutlineIcon /> },
  { name: MenuActionsModel.Duplicate, display: 'Duplicate', iconLeft: <CopyIcon /> },
  {
    name: MenuActionsModel.Remove,
    display: 'Remove',
    iconLeft: <TrashIcon />,
    confirm: 'Are you sure to remove this exercise?',
  },
];

const copyToAll = {
  name: MenuActionsModel.CopyToAll,
  display: 'Copy to Other Duration',
  iconLeft: <CopyIcon />,
  confirm: 'Any existing data present in other duration will be overwritten.',
};
const removeFromAll = {
  name: MenuActionsModel.RemoveFromAll,
  display: 'Remove from all duration',
  iconLeft: <CopyIcon />,
  confirm: 'Are you sure to remove this exercise from all durations?',
};

function GetRowActions(duration: string) {
  const actions = [...RowActions]
  if (duration !== '15') {
    actions.unshift(copyToAll)
    actions.push(removeFromAll)
  }
  return actions
}

function WorkoutExerciseComponent(props: WorkoutExerciseProps) {
  const {
    exercise,
    selectedDuration,
    isExpandView,
    onUpdateExercise,
    handleAddSet,
    handleMenuAction,
    length,
    index
  } = props;

  const onClickAddSet = () => {
    handleAddSet(exercise);
  };

  const onRemoveSet = (event: React.MouseEvent<HTMLButtonElement>) => {
    const updatedSets = exercise.sets.filter((set) => set.unique_id !== event.currentTarget.value);
    onUpdateExercise({
      ...exercise,
      sets: updatedSets,
    });
  };

  const onUpdateSetValue: UpdateSetValueFn = (setIndex, fieldName, fieldValue) => {
    const updatedSets = exercise.sets.map((s, index) =>
      index === setIndex ? { ...s, [fieldName]: fieldValue } : s
    );
    onUpdateExercise({
      ...exercise,
      sets: updatedSets,
    });
  };
  const handleItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleMenuAction(event.currentTarget.name, exercise);
  };

  return (
    <div
      className={clsx(
        'border border-gray-light bg-white relative',
        isExpandView ? 'p-4 mb-4' : 'mb-1'
      )}
    >
      <div className="flex items-center">
        <img src={exercise.exercise_image} className="h-14 w-14" alt="exercise_image" />
        <p className="flex-1 ml-4 font-semibold text-base">{exercise.exercise_name}</p>
        <p className="text-xs absolute right-0 top-0 text-gray-50">{exercise.unique_id}</p>
        <button
          style={{
            borderRadius: '5px',
            paddingTop: '0px',
            paddingBottom: '0px',
            margin: '5px',
          }}
          disabled={!((index + 1) > 1)}
          name={MenuActionsModel.MoveUp}
          onClick={handleItemClick}
          type="button"
        >
          { <MoveUpIcon />}
        </button>

        <button
          style={{
            borderRadius: '5px',
            paddingTop: '0px',
            paddingBottom: '0px',
            margin: '5px',
          }}
          disabled={!((index + 1) < length)}
          name={MenuActionsModel.MoveDown}
          onClick={handleItemClick}
          type="button"
        >
          {<MoveDownIcon />}
        </button>


        <Menu
          triggerElement={<MoreHorizontalIcon />}
          items={GetRowActions(
            selectedDuration
          )}
          menuData={exercise}
          onItemClick={handleMenuAction}
        />
      </div>
      {isExpandView ? (
        <>
          <table className="plainTable mt-4">
            <thead>
              <tr>
                <th>SET</th>
                <th>QUANTITY</th>
                <th>QUALIFIER</th>
                <th>GOAL RIR</th>
                <th>REST(min:sec)</th>
              </tr>
            </thead>
            <tbody>
              {exercise.sets.map((set, index) => (
                <ExerciseSetRow
                  key={`${set.unique_id}_${exercise.workout_duration}_${exercise.stage}}`}
                  set={set}
                  index={index}
                  exerciseType={exercise.exercise_type}
                  onRemoveSet={onRemoveSet}
                  onUpdateSetValue={onUpdateSetValue}
                />
              ))}
            </tbody>
          </table>
          <div className="flex">
            <p className="flex-1" />
            <button type="button" className="outlined w-28 mt-4 h-8" onClick={onClickAddSet}>
              <PlusIcon /> Add Set
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default WorkoutExerciseComponent;
