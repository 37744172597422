import {API_BASE_URL, SESSION_TOKEN_KEY} from 'common/constants';
import isURL from 'validator/lib/isURL';

export function noop() {}

export function range(start: number, end: number, interval: number = 1) {
  const arr = [];
  for (let i = start; i <= end; i += interval) {
    arr.push(Number(i.toFixed(2)));
  }
  return arr;
}

export const parseTimeStrToSecond = (timeStr: string): number => {
  const minuteVal = Number(timeStr.slice(0, 2));
  const secondVal = Number(timeStr.slice(3));
  return minuteVal * 60 + secondVal;
};

export const formatSecondValue = (seconds: number) => {
  const minuteVal = String(Math.floor(seconds / 60));
  const secondVal = String(seconds % 60);
  return `${minuteVal.padStart(2, '0')}:${secondVal.padStart(2, '0')}`;
};

/**
 * Utility to upload file additionally it also checks the input param
 * so that the return of this function can safely assigned to payload of request.
 * e.g. imageURL for any resource should be valid url or null.
 * this function can be called with the value received from file input or any file control
 * @param file
 * @returns
 */
export async function uploadFile(file: File | any): Promise<string | null> {
  const token = sessionStorage.getItem(SESSION_TOKEN_KEY);
  if (file instanceof File) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const { data } = await fetch(`${API_BASE_URL}/api/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((d) => d.json());
      return data?.file?.location;
    } catch (e) {
      console.error(e);
      return null;
    }
  } else if (typeof file === 'string' && isURL(file)) {
    return file;
  }
  return null;
}

export function generateUniqueId() {
  return Math.floor(Math.random() * 10 ** 16).toString(16);
}
