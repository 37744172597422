import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_OBJECT } from 'common/constants';
import { ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type UserProfile = {
  email: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
};

const resourceLoader: ResourceLoaderFn<UserProfile> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/profile', { signal: args?.abortSignal });
    return {
      success: data.success,
      data: data?.data as UserProfile,
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

function useCurrentUser(params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) {
  const { status, resource, loadData } = useRemoteResource<UserProfile>({
    resourceName: 'currentUser',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  return {
    isLoading: status === 'IN_PROGRESS',
    email: resource?.email,
    firstName: resource?.firstName,
    lastName: resource?.lastName,
    profilePicture: resource?.profilePicture,
    loadProfile: loadData,
  };
}

export default useCurrentUser;
