import React, { MouseEventHandler, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Placement, ReferenceType, shift, size, useFloating } from '@floating-ui/react-dom';
import clsx from 'clsx';

export interface PopupProps {
  anchorEl?: ReferenceType;
  onClose?: MouseEventHandler;
  placement?: Placement;
  children?: React.ReactNode;
  className?: string;
}

function Popup(props: PopupProps) {
  const { anchorEl, className, placement, children, onClose } = props;
  const { x, y, reference, floating, strategy } = useFloating({
    placement,
    middleware: [
      // flip(),
      shift(),
      size({
        apply({ availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight - 20}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  useLayoutEffect(() => {
    if (anchorEl) {
      reference(anchorEl);
    }
  }, [reference, anchorEl]);

  if (!anchorEl) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="modalBackdrop" onClick={onClose} />
      <div
        className={clsx('overflow-auto', className)}
        ref={floating}
        style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
      >
        {children}
      </div>
    </>,
    document.body
  );
}

export default Popup;

export function usePopupConfig<T>() {
  const [anchorEl, setAnchorEl] = useState<EventTarget & T>();
  const showOptions = (event?: React.MouseEvent<T>) => {
    setAnchorEl(event?.currentTarget);
  };

  const closeOptions = () => {
    setAnchorEl(undefined);
  };
  return {
    anchorEl,
    setAnchorEl,
    showOptions,
    closeOptions,
  };
}
