import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip } from '@mui/material';
import { CopyIcon, FoldIcon, LockIcon, TrashIcon, UnfoldIcon } from 'assets/icons';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevronLeft.svg';
import useToggle from 'common/hooks/useToggle';
import RadioButton from 'common/ui/form/RadioButton';
import { useModalConfig } from 'common/ui/Modal';
import Throbber from 'common/ui/Throbber';
import useExerciseList from 'modules/exercises/DAL/useExerciseList';

import {
  WorkoutBuildFormData,
  WorkOutBuildFormSchema,
  WorkoutDetailsFormData,
  WorkoutExerciseFormData,
} from '../types';

import ExerciseDetailModal from './ExerciseDetailModal';
import ExercisePicker from './ExercisePicker';
import WorkoutStage from './WorkoutStage';

interface BuildWorkoutFormProps {
  defaultValues: WorkoutBuildFormData;
  workoutType: WorkoutDetailsFormData['type'];
  onPublishWorkout: (formData: WorkoutBuildFormData) => void;
  onSaveDraft: (formData: WorkoutBuildFormData) => void;
  goPreviousSection: (formData: WorkoutBuildFormData) => void;
  moveUp: (unique_id: string, formData: WorkoutBuildFormData) => void;
  moveDown: (unique_id: string, formData: WorkoutBuildFormData) => void;
}

function BuildWorkoutForm(props: BuildWorkoutFormProps) {
  const {
    defaultValues,
    workoutType,
    onSaveDraft,
    onPublishWorkout,
    goPreviousSection,
    moveUp,
    moveDown,
  } = props;

  const {
    control,
    formState: { errors },
    reset,
    watch,
    getValues,
    handleSubmit,
    clearErrors,
    register,
  } = useForm<WorkoutBuildFormData>({
    defaultValues,
    resolver: zodResolver(WorkOutBuildFormSchema),
  });
  const { isLoadingEL } = useExerciseList();
  const { isOpen: isExpandView, toggle } = useToggle(true);
  const { isOpen: isCopy, toggle: toggleCopyToOthers } = useToggle(true);
  const {
    isModalOpen,
    data: exerciseDetails,
    closeModal,
    setModalData,
  } = useModalConfig<WorkoutExerciseFormData>(false);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [workout_duration] = watch(['workout_duration']);

  const handleGoBack = () => {
    clearErrors();
    goPreviousSection(getValues());
  };
  const selectedDuration = workoutType === 'normal' ? workout_duration : '15';

  const onClickSaveAsDraft = async () => {
    clearErrors();
    onSaveDraft(getValues());
  };
  const activationStage = useRef<any>();
  const warmupStage = useRef<any>();
  const mainsetStage = useRef<any>();
  const onClickClearWorkouts = async () => {
    let ex = activationStage.current.getCurrentExercises();
    mainsetStage.current.clearAllWorkouts(
      warmupStage.current.clearAllWorkouts(activationStage.current.clearAllWorkouts(ex))
    );
  };
  // const [copyState, updateCopyState] = useState(true);

  if (errors.exercises) {
    console.error(
      'Workout build form error:',
      JSON.stringify(
        {
          errors,
          formData: {
            build: getValues(),
          },
        },
        null,
        2
      )
    );
  }

  return (
    <form
      autoComplete="off"
      className="h-pageContent flex flex-col"
      onSubmit={handleSubmit(onPublishWorkout)}
    >
      <div className="flex flex-1 gap-6 overflow-hidden">
        <div className="w-1/3 relative bg-gray-light">
          <div className="h-full overflow-auto">
            <ExercisePicker />
          </div>
          {isLoadingEL ? <Throbber withMask /> : null}
        </div>
        <div className="relative w-2/3 flex flex-col overflow-hidden bg-gray-light">
          <div className="flex items-center px-7 h-16 bg-gray-light">
            <h3 className="text-18 font-medium">{defaultValues.name}</h3>
            <p className="flex-1 ml-4 fieldError">{errors?.exercises?.message}</p>
            {workoutType === 'normal' ? (
              <>
                <RadioButton
                  {...register('workout_duration')}
                  className="w-16 pl-1 h-8"
                  label="60m"
                  value="60"
                  rounded="8px"
                  variant="filled"
                />
                <RadioButton
                  {...register('workout_duration')}
                  className="w-16 pl-1 h-8"
                  label="45m"
                  rounded="8px"
                  value="45"
                  variant="filled"
                />
                <RadioButton
                  {...register('workout_duration')}
                  className="w-16 pl-1 h-8"
                  label="30m"
                  rounded="8px"
                  value="30"
                  variant="filled"
                />
              </>
            ) : null}
            <button type="button" className="iconButton ml-4" onClick={toggle}>
              {isExpandView ? <FoldIcon /> : <UnfoldIcon />}
            </button>
            <Tooltip title={'Clear the workouts from ' + selectedDuration + ' mins'}>
              <button type="button" className="iconButton ml-4" onClick={onClickClearWorkouts}>
                <TrashIcon />
              </button>
            </Tooltip>
            {workoutType === 'normal' ? (
              <Tooltip
                title={
                  <div>
                    {'Copy dropped workouts to other durations'}
                    <br />
                    <br />
                    {isCopy
                      ? 'Copy Activated! The workouts you drop will be copied to the other durations'
                      : 'Copy Off, The workouts you drop will allocated to this duration only'}
                  </div>
                }
              >
                <button
                  type="button"
                  className={`iconButton ml-4 ${isCopy ? 'text-teal-normal' : ''}`}
                  onClick={toggleCopyToOthers}
                >
                  <div style={{ position: 'relative' }}>
                    <CopyIcon />
                    {isCopy ? (
                      <LockIcon
                        style={{
                          position: 'absolute',
                          top: '10px',
                          width: '18px',
                          height: '18px',
                          left: '10px',
                          filter: ' invert(0%) sepia(1000%) saturate(5000%) hue-rotate(200deg)',
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </button>
              </Tooltip>
            ) : null}
          </div>
          <p className="px-7 py-2 text-alert-error">
            {errors?.exercises
              ? 'Seems some error in form, All values in set is required. Please check.'
              : null}
          </p>
          <div className="flex-1 px-7 overflow-y-scroll">
            <Controller
              name="exercises"
              control={control}
              render={({ field }) => (
                <WorkoutStage
                  ref={activationStage}
                  getParentValues={getValues}
                  moveUp={moveUp}
                  moveDown={moveDown}
                  stageName="activation"
                  selectedDuration={selectedDuration}
                  displayText="Activation"
                  exercises={field.value}
                  onChange={field.onChange}
                  onViewExercise={setModalData}
                  isExpandView={isExpandView}
                  isCopyLocked={isCopy}
                />
              )}
            />
            <Controller
              name="exercises"
              control={control}
              render={({ field }) => (
                <WorkoutStage
                  ref={warmupStage}
                  getParentValues={getValues}
                  moveUp={moveUp}
                  moveDown={moveDown}
                  stageName="warmup"
                  selectedDuration={selectedDuration}
                  displayText="Warmup"
                  exercises={field.value}
                  onChange={field.onChange}
                  onViewExercise={setModalData}
                  isExpandView={isExpandView}
                  isCopyLocked={isCopy}
                />
              )}
            />
            <Controller
              name="exercises"
              control={control}
              render={({ field }) => (
                <WorkoutStage
                  ref={mainsetStage}
                  getParentValues={getValues}
                  moveUp={moveUp}
                  moveDown={moveDown}
                  stageName="mainset"
                  selectedDuration={selectedDuration}
                  displayText="Main Set"
                  exercises={field.value}
                  onChange={field.onChange}
                  onViewExercise={setModalData}
                  isExpandView={isExpandView}
                  isCopyLocked={isCopy}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex pt-6">
        <button className="w-32 outlined_primary narrow mr-4" onClick={handleGoBack} type="button">
          <ChevronLeftIcon /> <p>Back</p>
        </button>
        <div className="flex-1" />
        <button
          className="w-32 narrow  text-teal-normal mr-4"
          type="button"
          onClick={onClickSaveAsDraft}
        >
          Save as draft
        </button>
        <button className="w-32 primary narrow">Publish</button>
      </div>
      <ExerciseDetailModal
        isModalOpen={isModalOpen}
        exercise={exerciseDetails}
        onClose={closeModal}
      />
    </form>
  );
}

export default BuildWorkoutForm;
