import React, { useState } from 'react';
import { ReactComponent as EyeOffOutline } from 'assets/icons/eyeOffOutline.svg';
import { ReactComponent as EyeOnOutline } from 'assets/icons/eyeOnOutline.svg';

interface PasswordFieldProps extends React.HTMLProps<HTMLInputElement> {
  containerClassName?: string;
  containerProps?: Omit<React.HTMLProps<HTMLDivElement>, 'className'>;
}

function PasswordField(props: PasswordFieldProps, ref: React.Ref<HTMLInputElement>) {
  const { containerProps = {}, containerClassName, ...inputProps } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const togglePassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div {...containerProps} className={`relative ${containerClassName}`}>
      <input {...inputProps} type={showPassword ? 'text' : 'password'} ref={ref} />
      <button
        className="iconButton absolute right-2 top-1/2 -translate-y-1/2"
        type="button"
        tabIndex={-1}
        onClick={togglePassword}
      >
        {showPassword ? <EyeOnOutline /> : <EyeOffOutline />}
      </button>
    </div>
  );
}

export default React.forwardRef(PasswordField);
