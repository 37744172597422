import React from 'react';

import { OptionData } from '../types';

import { DropDownOption } from './utils';

export interface MultiSelectProps<T> {
  renderItem: (param: T, isSelected: boolean) => React.ReactNode;
  optionsSet: OptionData<T>[];
  selectedValues: OptionData<T>[];
  handleOptionClick: (option: OptionData<T>, isSelected: boolean) => void;
}

export function MultiSelectOptions<T>(props: MultiSelectProps<T>) {
  const { renderItem, selectedValues, optionsSet, handleOptionClick } = props;

  return (
    <ul className="bg-white shadow-lg selectMenu z-50">
      {optionsSet.map((option) => {
        const isSelected = selectedValues.findIndex((v) => v.id === option.id) !== -1;
        return (
          <DropDownOption
            key={option.id}
            handleOptionClick={handleOptionClick}
            option={option}
            isSelected={isSelected}
          >
            {renderItem(option.data, isSelected)}
          </DropDownOption>
        );
      })}
    </ul>
  );
}
