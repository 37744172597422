import React from 'react';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevronDown.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevronRight.svg';
import clsx from 'clsx';
import useToggle from 'common/hooks/useToggle';

interface CollapsibleProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
  headerClassName?: string;
}

function Collapsible(props: CollapsibleProps) {
  const { headerClassName = 'bg-gray-normal text-white', children, title, ...rootProps } = props;
  const { isOpen, toggle } = useToggle(true);

  return (
    <div {...rootProps}>
      <div
        className={clsx('p-2 cursor-pointer flex select-none', headerClassName)}
        onClick={toggle}
      >
        <p>{isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}</p>
        <p>{title}</p>
      </div>
      {isOpen ? children : null}
    </div>
  );
}

export default Collapsible;
