import { useMemo } from 'react';
import { ResourceLoaderFn, useRemoteResource } from '@cksutils/remote-resource-toolkit';
import { deafultQueryParams, EMPTY_ARRAY } from 'common/constants';
import { ResourceFilters, ResourceHookParams } from 'common/types';
import { formatISODate } from 'common/utils/dateTimeUtils';
import { EMAxios } from 'common/utils/EMService';
import { produce } from 'immer';

import { ProgramModel } from './types';

type ProgramList = { items: ProgramModel[]; totalCount: number };
type ProgramParams = ResourceFilters & { streamId: string };

const resourceLoader: ResourceLoaderFn<ProgramList, ProgramParams> = async (args) => {
  try {
    const { streamId, ...restParams } = args.params;
    if (!streamId) {
      return { success: false };
    }
    const { data } = await EMAxios.get(`/api/admin/stream/${streamId}/programs`, {
      params: {
        ...deafultQueryParams,
        perPage: 1000,
        ...restParams,
      },
      signal: args?.abortSignal,
    });
    const programs = data?.data?.programs || EMPTY_ARRAY;
    const updatedPrograms = programs.map(
      produce((program: ProgramModel) => {
        program.updated_at = formatISODate(program.updated_at);
        program.workouts = program.workouts.map(({ exercises, ...wo }) => wo);
      })
    );
    return {
      success: data.success,
      data: {
        items: updatedPrograms,
        totalCount: data?.data?.total_count!,
      },
      params: {
        ...deafultQueryParams,
        ...restParams,
        streamId,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};
interface UseProgramParams extends ResourceHookParams<ProgramParams> {
  streamId: string;
}
const usePrograms = (params: UseProgramParams) => {
  const autoLoadParams = useMemo(() => ({ streamId: params.streamId }), [params.streamId]);
  const {
    status,
    resource,
    resourceParams = deafultQueryParams,
    loadData,
  } = useRemoteResource<ProgramList, ProgramParams>({
    resourceName: 'programs',
    resourceKey: params.streamId,
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    autoLoadParams,
    resourceLoader,
  });

  const moveToPage = async (page: number) => {
    return loadData({ ...resourceParams, page });
  };

  const searchProgram = (searchText: string) => loadData({ searchText });
  return {
    isLoading: status === 'IN_PROGRESS',
    status,
    items: resource?.items || EMPTY_ARRAY,
    totalCount: resource?.totalCount || 0,
    filters: resourceParams,
    loadPrograms: loadData,
    refreshData: loadData,
    moveToPage,
    searchProgram,
  };
};
export default usePrograms;
