import React from 'react';
import { ReactComponent as MenuDuoIcon } from 'assets/icons/menuDuo.svg';
import { PagerSmall } from 'common/ui/DataTable';
import SearchField from 'common/ui/form/SearchField';
import useWorkoutList from 'modules/workouts/DAL/useWorkoutList';
import { WorkoutModel } from 'modules/workouts/types';

import { ProgramBuildFormData } from '../types';

function WorkoutPicker() {
  const {
    items,
    totalCount,
    filters: { page, perPage, searchText },
    moveToPage,
    loadWorkouts,
  } = useWorkoutList({ autoLoad: true, autoLoadParams: { isDraft: false } });
  const searchWorkout = (searchText: string) => {
    loadWorkouts({ searchText: searchText || undefined });
  };

  return (
    <div className="px-5">
      <div className="sticky top-0 bg-gray-light py-4">
        <div className="flex items-center mb-2">
          <label className="flex-1">Find Workout</label>
          <PagerSmall
            curPage={page}
            pageSize={perPage}
            totalCount={totalCount}
            moveToPage={moveToPage}
          />
        </div>
        <SearchField placeholder="Search" onSearch={searchWorkout} value={searchText} />
      </div>
      <div>
        {items.map((workout) => (
          <WorkoutCard workout={workout} key={workout._id} />
        ))}
      </div>
    </div>
  );
}

export default WorkoutPicker;

function WorkoutCard(props: { workout: WorkoutModel }) {
  const { workout } = props;
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const workoutData: ProgramBuildFormData['workouts'][0] = {
      name: workout.name,
      cover_image: workout.cover_image || '',
      category: workout.category.name,
      difficulty_level: workout.difficulty_level,
      workout_length: workout.type === 'normal' ? 'Normal' : 'Quick',
      _id: workout._id,
      uniqueId: '',
      id: '',
    };
    event.dataTransfer.setData('text/plain', JSON.stringify(workoutData));
  };
  return (
    <div
      className="workoutCard flex items-center mb-2 border border-gray-5 bg-gray-100 pr-2"
      draggable
      onDragStart={handleDragStart}
    >
      <img src={workout.cover_image || undefined} alt="" className="w-20 h-20 object-cover" />
      <p className="flex-1 px-2">{workout.name}</p>
      <MenuDuoIcon />
    </div>
  );
}
