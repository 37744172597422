import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FoldIcon, UnfoldIcon } from 'assets/icons';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevronLeft.svg';
import useToggle from 'common/hooks/useToggle';
import Throbber from 'common/ui/Throbber';
import useWorkoutList from 'modules/workouts/DAL/useWorkoutList';

import { ProgramBuildFormData, ProgramBuildSchema } from '../types';

import ProgramWorkouts from './ProgramWorkouts';
import WorkoutPicker from './WorkoutPicker';

interface ProgramBuildFormProps {
  defaultValues: ProgramBuildFormData;
  onPublishProgram: (formData: ProgramBuildFormData) => void;
  onSaveDraft: (formData: ProgramBuildFormData) => void;
  goPreviousSection: (formData: ProgramBuildFormData) => void;
}

function ProgramBuildForm(props: ProgramBuildFormProps) {
  const { defaultValues, onPublishProgram, onSaveDraft, goPreviousSection } = props;
  const { isOpen: isExpandView, toggle } = useToggle(false);
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm<ProgramBuildFormData>({
    defaultValues,
    resolver: zodResolver(ProgramBuildSchema),
  });

  const { isLoadingWO } = useWorkoutList();
  const handleGoBack = () => {
    clearErrors();
    goPreviousSection(getValues());
  };
  const onClickSaveAsDraft = async () => {
    clearErrors();
    onSaveDraft(getValues());
  };

  return (
    <form
      autoComplete="off"
      className="h-pageContent flex flex-col"
      onSubmit={handleSubmit(onPublishProgram)}
    >
      <div className="flex-1 flex gap-6 overflow-hidden">
        <div className="w-1/3 relative bg-gray-light">
          <div className="h-full overflow-auto">
            <WorkoutPicker />
          </div>
          {isLoadingWO ? <Throbber withMask /> : null}
        </div>
        <div className="w-2/3 relative px-7 flex flex-col bg-gray-light">
          <div className="flex items-center h-12 bg-gray-light">
            <h3 className="text-18 font-medium">{defaultValues.name}</h3>
            <p className="flex-1 ml-4 fieldError">{errors?.workouts?.message}</p>
            <button type="button" className="iconButton ml-4" onClick={toggle}>
              {isExpandView ? <FoldIcon /> : <UnfoldIcon />}
            </button>
          </div>
          <Controller
            control={control}
            name="workouts"
            render={({ field }) => (
              <ProgramWorkouts
                workouts={field.value}
                compactMode={isExpandView}
                onChangeWorkout={field.onChange}
              />
            )}
          />
        </div>
      </div>
      <div className="flex py-4">
        <button className="w-32 outlined narrow  text-teal-normal mr-4" onClick={handleGoBack}>
          <ChevronLeftIcon /> <p>Back</p>
        </button>
        <div className="flex-1" />
        <button className="w-32 narrow  text-teal-normal mr-4" onClick={onClickSaveAsDraft}>
          Save as draft
        </button>
        <button className="w-32 primary narrow">Publish</button>
      </div>
    </form>
  );
}

export default ProgramBuildForm;
