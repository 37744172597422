import { appActions } from 'app/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { generateUniqueId } from 'common/utils/general';
import LogRocket from 'logrocket';

import useCurrentUser from './useCurrentUser';

function DebugMode() {
  const dispatch = useAppDispatch();
  const { debugMode } = useAppSelector((state) => state.app);
  const { firstName, lastName } = useCurrentUser();
  const turnOnDebugMode = () => {
    if (debugMode === 'off') {
      LogRocket.init('vjzuyt/emadmin');
      LogRocket.identify(generateUniqueId(), {
        name: `${firstName} ${lastName}`,
      });
      dispatch(appActions.setDebugMode({ debugMode: 'on' }));
    }
  };
  const turnOffDebugMode = () => {
    dispatch(appActions.setDebugMode({ debugMode: 'off' }));
    window.location.reload();
  };
  return (
    <div className="pl-24 max-w-screen-lg pr-5 h-appContent flex-1">
      <h1 className="my-12 text-2xl font-semibold">Debug mode</h1>
      {<p className="font-semibold mb-4">Debug mode is {debugMode}</p>}
      {debugMode === 'off' ? <p>Turn on debug mode to record any error or issue.</p> : null}
      <p>
        When debug mode is on current browser tab, network activities from this tab will be recorded
        for analysis.
      </p>
      {debugMode === 'on' ? (
        <button type="button" className="primary px-4 mt-10" onClick={turnOffDebugMode}>
          Turn off debug mode
        </button>
      ) : (
        <button type="button" className="primary px-4 mt-10" onClick={turnOnDebugMode}>
          Turn on debug mode
        </button>
      )}
    </div>
  );
}

export default DebugMode;
