import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type TagOptions = {
  tags: OptionData<string>[];
};

const resourceLoader: ResourceLoaderFn<TagOptions> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/program/tags', {
      signal: args?.abortSignal,
    });
    const { tags } = data?.data!;
    const programOptions = tags.map((option: string) => ({
      id: option,
      data: option,
    }));

    return {
      success: data.success,
      data: {
        tags: programOptions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useDisplayTagsDropDownOptions = (
  params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT
) => {
  const { status, resource, loadData } = useRemoteResource<TagOptions>({
    resourceName: 'tagOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });
  return {
    isDisplayTagsOptionsLoading: status === 'IN_PROGRESS',
    status,
    tags: resource?.tags || EMPTY_ARRAY,
    loadData,
  };
};

export default useDisplayTagsDropDownOptions;
