import { useRef, useState } from 'react';
import { APICallState } from 'common/types';

function useApiCallStatus() {
  const [apiCallState, setState] = useState<APICallState>();
  const successCountRef = useRef(0);
  const setLoading = () => {
    setState({
      status: 'IN_PROGRESS',
    });
  };
  const setSuccess = (message?: string, duration = 5000) => {
    setState({
      status: 'SUCCESS',
      message,
    });
    successCountRef.current += 1;
    resetApiCallConfig(duration);
  };

  const setError = (message?: string, duration = 5000) => {
    setState({
      status: 'ERROR',
      message,
    });
    resetApiCallConfig(duration);
  };

  const resetApiCallConfig = (duration: number) => {
    if (duration > 0) {
      setTimeout(() => {
        setState({ status: 'INIT' });
      }, duration);
    } else {
      setState({ status: 'INIT' });
    }
  };
  const resetSaveCount = () => {
    successCountRef.current = 0;
  };
  return {
    isLoading: apiCallState?.status === 'IN_PROGRESS',
    isError: apiCallState?.status === 'ERROR',
    message: apiCallState?.message,
    successCount: successCountRef.current,
    resetSaveCount,
    setLoading,
    setSuccess,
    setError,
  };
}
export default useApiCallStatus;
