import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { appActions } from 'app/appSlice';
import { useAppDispatch } from 'app/hooks';
import { ReactComponent as AccountIcon } from 'assets/icons/accountIcon.svg';
import { ReactComponent as CaretDownIcon } from 'assets/icons/arrowCaretDown.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logoutAlternateIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settingsIcon.svg';
import Popup from 'common/ui/Popup';
import * as AuthService from 'modules/auth/authService';
import { authActions } from 'modules/auth/authSlice';
import useCurrentUser from 'modules/auth/currentUser/useCurrentUser';

function AccountMenu(props: { className: string }) {
  const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLElement>();
  const dispatch = useAppDispatch();
  const naviagte = useNavigate();
  const { firstName, lastName, profilePicture } = useCurrentUser();

  const closeMenu = () => {
    setAnchorEl(undefined);
  };

  const handleLogout = async () => {
    dispatch(appActions.setStatus({ status: 'LOADING' }));
    try {
      await AuthService.logout();
      dispatch(authActions.setStatus({ status: 'NO_SESSION' }));
      naviagte('/', { replace: true });
    } catch (e) {
    } finally {
      dispatch(appActions.setStatus({ status: 'READY' }));
    }
  };
  return (
    <div className={props.className}>
      <AccountAvatar
        onClick={(event) => setAnchorEl(event.currentTarget)}
        firstName={firstName}
        profilePicture={profilePicture}
      />
      <Popup anchorEl={anchorEl} onClose={closeMenu} placement="bottom-start">
        <ul className="bg-white border-gray-5 border-solid border w-70 shadow-lg">
          <li className="flex items-center py-5 px-7 border-b border-b-gray-5 border-solid">
            <div className="bg-gray-light rounded-full w-10 h-10 flex items-center justify-center relative">
              {profilePicture ? (
                <img
                  src={profilePicture}
                  className="h-full w-full rounded-full object-cover"
                  alt="profile_image"
                />
              ) : (
                <p>{firstName ? firstName[0] : 'J'}</p>
              )}
            </div>
            <p className="ml-4">{`${firstName} ${lastName}`}</p>
          </li>
          <li>
            <NavLink
              to="/profile/account"
              className="flex items-center py-5 px-7 hover:bg-teal-light_hover"
              onClick={closeMenu}
            >
              <AccountIcon />
              <p className="ml-3">Account</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/profile/settings"
              className="flex items-center py-5 px-7  hover:bg-teal-light_hover"
              onClick={closeMenu}
            >
              <SettingsIcon />
              <p className="ml-3">Settings</p>
            </NavLink>
          </li>
          <li
            className="flex items-center py-5 px-7 cursor-pointer  hover:bg-teal-light_hover"
            onClick={handleLogout}
          >
            <LogoutIcon />
            <p className="ml-3">Logout</p>
          </li>
        </ul>
      </Popup>
    </div>
  );
}

function AccountAvatar(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    firstName?: string;
    profilePicture?: string | null;
  }
) {
  const { firstName, profilePicture, ...restProps } = props;
  return (
    <button {...restProps}>
      <div className="bg-gray-light rounded-full w-10 h-10 flex items-center justify-center relative">
        {profilePicture ? (
          <img
            src={profilePicture}
            className="h-full w-full rounded-full  object-cover"
            alt="profile_image"
          />
        ) : (
          <p>{firstName ? firstName[0] : 'J'}</p>
        )}
        <div className="absolute right-1 bottom-0 h-3 w-3">
          <CaretDownIcon />
        </div>
      </div>
    </button>
  );
}

export default AccountMenu;
