import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import {  OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

const resourceLoader: ResourceLoaderFn<OptionData<string>[]> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/notifications/emails', {
      signal: args?.abortSignal,
    });
    console.log(data)
    return {
      success: data.success,
      data:
        data?.data?.emails?.map?.((option: string) => ({
          id: option,
          data: option,
        })) || EMPTY_ARRAY,
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useEmailDropDownOptions = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource } = useRemoteResource<OptionData<string>[]>({
    resourceName: 'emailOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });

  return {
    isOptionsLoading: status === 'IN_PROGRESS',
    emailOptions: resource || EMPTY_ARRAY,
  };
};
export default useEmailDropDownOptions;
