import React from 'react';
import {API_BASE_URL, EMPTY_OBJECT, SESSION_TOKEN_KEY} from 'common/constants';

interface FileUploadResult {
  success: boolean;
  fieldName: string;
  data?: {
    originalname: string;
    mimetype: string;
    size: number;
    location: string;
  };
  message?: string;
}

const useFileUploadService = () => {
  const [filesToUpload, setFilesToUpload] = React.useState<{
    [fieldName: string]: File[];
  }>(EMPTY_OBJECT);
  const [isUploading, setLoading] = React.useState(false);

  const uploadSingleFile = async (fieldName: string, file: File): Promise<FileUploadResult> => {
    const token = sessionStorage.getItem(SESSION_TOKEN_KEY);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const { data } = await fetch(`${API_BASE_URL}/api/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((d) => d.json());
      if (!data?.file?.location) {
        return {
          success: false,
          fieldName,
        };
      }
      return {
        success: true,
        fieldName,
        data: {
          originalname: data?.file?.originalname,
          mimetype: data?.file?.mimetype,
          size: data?.file?.size,
          location: data?.file?.location,
        },
      };
    } catch (e) {
      return {
        success: false,
        fieldName,
      };
    }
  };

  const uploadFiles = async (): Promise<{ [key: string]: FileUploadResult[] }> => {
    setLoading(true);
    const response = await Promise.all(
      Object.entries(filesToUpload)
        .map(([fieldName, files]) => files.map((file) => ({ fieldName, file })))
        .flat()
        .map(({ fieldName, file }) => uploadSingleFile(fieldName, file))
    );
    setLoading(false);
    return response.reduce<{ [key: string]: FileUploadResult[] }>((acc, result) => {
      const currentValue = acc[result.fieldName] || [];
      return { ...acc, [result.fieldName]: [...currentValue, result] };
    }, {});
  };

  const setFiles = (fieldName: string, files: File[]) => {
    setFilesToUpload({
      ...filesToUpload,
      [fieldName]: files,
    });
  };

  const removeAllFiles = () => {
    setFilesToUpload(EMPTY_OBJECT);
  };

  return {
    isUploading,
    files: filesToUpload,
    removeAllFiles,
    setFiles,
    uploadFiles,
  };
};
export default useFileUploadService;
