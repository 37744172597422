import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import SearchField from 'common/ui/form/SearchField';
import { useModalConfig } from 'common/ui/Modal';

import { mapExerciseModelToFormData } from './DAL/mapper';
import useExerciseDropDownOptions from './DAL/useExerciseDropDownOptions';
import useExerciseList from './DAL/useExerciseList';
import AddEditExerciseModal from './AddEditExerciseModal';
import ExerciseList from './ExerciseList';
import { ExerciseFormData, ExerciseModel } from './types.d';

type ModalData = {
  defaultFormData?: ExerciseFormData;
  exerciseId?: string;
};
function Exercises() {
  const {
    isModalOpen,
    openModal,
    setModalData,
    closeModal,
    data = {},
  } = useModalConfig<ModalData>(false);

  const { searchExercise, filters } = useExerciseList({ autoRemove: true });
  useExerciseDropDownOptions({ autoRemove: true, disconnected: true });

  const onClickDataRow = (exercise: ExerciseModel) => {
    setModalData({
      exerciseId: exercise._id,
      defaultFormData: mapExerciseModelToFormData(exercise),
    });
  };
  return (
    <div className="contentArea h-appContent">
      <div className="flex items-center h-pageHeader">
        <h1 className="flex-1 font-semibold text-2xl">Exercise Library</h1>
        <SearchField onSearch={searchExercise} value={filters.searchText} />
        <button className="primary px-4 ml-4" onClick={openModal}>
          <PlusIcon /> Add Exercise
        </button>
      </div>
      <div className="h-pageContent pb-4">
        <ExerciseList onClickDataRow={onClickDataRow} />
      </div>
      {isModalOpen ? <AddEditExerciseModal isOpen={true} onClose={closeModal} {...data} /> : null}
    </div>
  );
}

export default Exercises;
