import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import useApiCallStatus from 'common/hooks/useApiCallStatus';
import PasswordField from 'common/ui/form/PasswordField';
import Throbber from 'common/ui/Throbber';
import { EMAxios } from 'common/utils/EMService';
import * as Z from 'zod';

import useCurrentUser from '../useCurrentUser';

const UserProfileSchema = Z.object({
  firstName: Z.string().trim().min(1, { message: 'Please mention first name.' }),
  lastName: Z.string().trim().min(1, { message: 'Please mention last name.' }),
  email: Z.string().trim().email().min(1, { message: 'Please mention first name.' }),
  currentPassword: Z.string().optional(),
  newPassword: Z.string().optional(),
  confirmPassword: Z.string().optional(),
}).superRefine((val, ctx) => {
  const isChangePasswd = val.newPassword || val.confirmPassword;
  if (isChangePasswd && val?.currentPassword?.length === 0) {
    ctx.addIssue({
      code: Z.ZodIssueCode.custom,
      message: 'Please mention current password',
      path: ['currentPassword'],
    });
  }
  if (isChangePasswd && val?.newPassword?.length === 0) {
    ctx.addIssue({
      code: Z.ZodIssueCode.custom,
      message: 'Type a new password',
      path: ['newPassword'],
    });
  }
  if (isChangePasswd && val?.confirmPassword?.length === 0) {
    ctx.addIssue({
      code: Z.ZodIssueCode.custom,
      message: 'Re-Type  new password',
      path: ['confirmPassword'],
    });
  }
  if (isChangePasswd && val?.confirmPassword !== val?.newPassword) {
    ctx.addIssue({
      code: Z.ZodIssueCode.custom,
      message: 'Password does not match',
      path: ['confirmPassword'],
    });
  }
});
type FormData = Z.infer<typeof UserProfileSchema>;

function AccountPage() {
  const { isLoading: isCuruserLoading, email, firstName, lastName, loadProfile } = useCurrentUser();
  const defaultValues = React.useMemo(
    () => ({
      firstName,
      lastName,
      email,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }),
    [firstName, lastName, email]
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields, isDirty },
  } = useForm<FormData>({
    defaultValues,
    resolver: zodResolver(UserProfileSchema),
  });
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
  const { isLoading, isError, message, setLoading, setSuccess, setError } = useApiCallStatus();

  const handleSaveChanges = async (profileData: FormData) => {
    setLoading();
    try {
      const { firstName, lastName, currentPassword, newPassword } = profileData;

      if (dirtyFields?.newPassword) {
        await EMAxios.post('/api/admin/profile/changePassword', {
          currentPassword,
          password: newPassword,
        });
      }

      if (dirtyFields?.firstName || dirtyFields?.lastName) {
        await EMAxios.put('/api/admin/profile', { firstName, lastName });
      }
      setSuccess('Successfully saved data.');
    } catch (e) {
      if (dirtyFields?.newPassword) {
        setError('Some error occured. please check you mention current password correctly.');
      } else {
        setError('Some error occured.');
      }
    } finally {
      if (dirtyFields?.firstName || dirtyFields?.lastName) {
        await loadProfile();
      }
      reset();
    }
  };

  return (
    <div className="px-20 py-10 max-w-screen-md flex-1">
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleSaveChanges)}>
        <p className={clsx('flex-1 h-8', isError ? 'text-alert-error' : 'text-alert-success')}>
          {message}
        </p>
        <p className="mb-4 font-semibold text-xl text-gray-normal">Name</p>
        <div className="flex mb-10 w-full">
          <div className="w-1/2 pr-5">
            <input
              type="text"
              placeholder="First name"
              className="w-full"
              {...register('firstName')}
            />
            <p className="fieldError">{errors?.firstName?.message}</p>
          </div>
          <div className="w-1/2 pl-5">
            <input
              type="text"
              placeholder="Last name"
              className="w-full"
              {...register('lastName')}
            />
            <p className="fieldError">{errors?.lastName?.message}</p>
          </div>
        </div>

        <p className="mb-4 font-semibold text-xl text-gray-normal">Email</p>
        <div className="flex mb-10 w-full">
          <div className="w-1/2 pr-5">
            <input
              type="text"
              readOnly={true}
              placeholder="johnsmith@gmail.com"
              className="w-full"
              {...register('email')}
            />
          </div>
        </div>

        <p className="mb-4 font-semibold text-xl text-gray-normal">Change Password</p>
        <div className="w-full">
          <div className="w-1/2 pr-5 mb-4">
            <PasswordField className="w-full" {...register('currentPassword')} />
            <p className="fieldError">{errors?.currentPassword?.message}</p>
          </div>
          <div className="w-1/2 pr-5 mb-4">
            <PasswordField className="w-full" {...register('newPassword')} />
            <p className="fieldError">{errors?.newPassword?.message}</p>
          </div>
          <div className="w-1/2 pr-5 mb-14">
            <PasswordField className="w-full" {...register('confirmPassword')} />
            <p className="fieldError">{errors?.confirmPassword?.message}</p>
          </div>
          <div className="w-1/2 pr-5">
            <button className="primary w-full" disabled={!isDirty}>
              Save Changes
            </button>
          </div>
        </div>
      </form>
      {isCuruserLoading || isLoading ? <Throbber withMask /> : null}
    </div>
  );
}

export default AccountPage;
