import React from 'react';

import { OptionProp } from '../types';

export function DropDownOption<T>({
  children,
  option,
  isSelected,
  handleOptionClick,
}: OptionProp<T>) {
  const onClickItem = (event: React.MouseEvent<HTMLLIElement>) =>
    handleOptionClick(option, isSelected, event);
  return <li onClick={onClickItem}>{children}</li>;
}
