import { _IdNamePair, APIResponse } from 'common/types';
import * as Z from 'zod';

/** ZOD validation schema used for form validation */
export const ExerciseFormSchema = Z.object({
  is_draft: Z.boolean().default(false),
  name: Z.string().trim().min(1, { message: 'Please enter exercise name.' }),
  focus: Z.array(Z.string()).min(1, { message: 'Please select atleast one focus area.' }),
  primary_muscle_group: Z.array(Z.string()).min(1, {
    message: 'Please select atleast one muscle group.',
  }),
  type: Z.enum(['bodyWeight', 'weighted'], { required_error: 'Please select exercise type.' }),
  difficulty_level: Z.object({
    novice: Z.number(),
    intermediate: Z.number(),
    advanced: Z.number(),
  }).optional(),
  equipment: Z.array(Z.string()).min(1, { message: 'Please select equipment required' }),
  video: Z.string().optional().nullable(),
  image: Z.array(Z.string()),
  instruction: Z.string().min(1, { message: 'Please enter Technique Cues.' }),
});

export type ExerciseFormData = Z.infer<typeof ExerciseFormSchema>;

export interface ExerciseModel extends ExerciseFormData {
  _id: string;
  is_active: boolean;
  focus: _IdNamePair[];
  primary_muscle_group: _IdNamePair[];
  equipment: _IdNamePair[];
  updated_at: string;
}

export type ExerciseListResponse = APIResponse<{ exercises: ExerciseModel[]; total_count: number }>;
