import { ReactComponent as NotificationOutlineIcon } from 'assets/icons/notificationOutlineDot.svg';

// import { ReactComponent as ShortLogo } from 'assets/images/ShortLogo.png';
import AccountMenu from './AccountMenu';
import TopNavigation from './TopNavigation';

function AppBar() {
  return (
    <div className="py-1 shadow flex items-center h-appHeader pr-14">
      {/*<div className="overflow-hidden w-42 pl-14">*/}
      <img
        src="/static/media/ShortLogo.png"
        className="overflow-hidden w-14 mr-8 ml-16"
        alt="app logo"
      />
      {/*<ShortLogo />*/}
      {/*</div>*/}
      <TopNavigation />
      <div className="flex-1" />
      <button>
        <NotificationOutlineIcon />
      </button>
      <AccountMenu className="ml-14" />
    </div>
  );
}
export default AppBar;
