import clsx from 'clsx';

interface ThrobberProps {
  message?: string;
  withMask?: boolean;
}

function Throbber(props: ThrobberProps) {
  const { message, withMask = false } = props;

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center',
        withMask && 'absolute left-0 right-0 top-0 bottom-0'
      )}
    >
      {withMask && (
        <div className="absolute left-0 right-0 top-0 bottom-0 bg-gray-400 opacity-40" />
      )}
      <div
        className={`
      border-teal-normal
        border-r-transparent
        animate-spin 
        inline-block
        w-16 h-16 border-4 
        rounded-full`}
      ></div>
      {message && <h4>{message}</h4>}
    </div>
  );
}

export default Throbber;
