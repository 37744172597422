import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

export type UserData = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
};
type UserOptions = {
  users: OptionData<UserData>[];
};

const resourceLoader: ResourceLoaderFn<UserOptions> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/program/users', {
      signal: args?.abortSignal,
    });
    const { data: dataSet } = data?.data!;
    const programOptions = dataSet.map((option: UserData) => ({
      id: option._id,
      data: option,
    }));

    return {
      success: data.success,
      data: {
        users: programOptions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

export function useUsersDropDownOptions(params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) {
  const {
    status,
    resource,
    loadData: loadDataUsers,
  } = useRemoteResource<UserOptions>({
    resourceName: 'usersOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });
  return {
    isUsersOptionsLoading: status === 'IN_PROGRESS',
    status,
    users: resource?.users || EMPTY_ARRAY,
    loadDataUsers,
  };
}
