import { SESSION_EMAIL_KEY, SESSION_TOKEN_KEY } from 'common/constants';
import { EMAxios } from 'common/utils/EMService';

export async function login(authData: { email: string; password: string }) {
  const { data } = await EMAxios.post('/api/login', authData);
  if (data.success && data.data.role >= 100) {
    sessionStorage.setItem(SESSION_EMAIL_KEY, authData.email);
    sessionStorage.setItem(SESSION_TOKEN_KEY, data.data.token);
  } else throw new Error('AUTH_ERROR');
}

export async function logout() {
  const email = sessionStorage.getItem(SESSION_EMAIL_KEY);
  const token = sessionStorage.getItem(SESSION_TOKEN_KEY);
  if (email && token) {
    await EMAxios.post('/api/logout', {
      email,
      token,
    });
  }
  sessionStorage.removeItem(SESSION_TOKEN_KEY);
  sessionStorage.removeItem(SESSION_EMAIL_KEY);
}

export async function checkForSession() {
  const email = sessionStorage.getItem(SESSION_EMAIL_KEY);
  const token = sessionStorage.getItem(SESSION_TOKEN_KEY);
  return email && token;
}
