import React from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DropTarget } from 'common/ui/DragnDrop';
import { generateUniqueId } from 'common/utils/general';

import { ProgramBuildFormData } from '../types';

import WorkoutItem from './WorkoutItem';

interface ProgramWorkoutProps {
  workouts: ProgramBuildFormData['workouts'];
  compactMode: boolean;
  onChangeWorkout: (workouts: ProgramBuildFormData['workouts']) => void;
}

function ProgramWorkouts(props: ProgramWorkoutProps) {
  const { workouts, compactMode = false, onChangeWorkout } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDrop = (data: ProgramBuildFormData['workouts'][0]) => {
    const uniqueId = generateUniqueId();
    onChangeWorkout([...workouts, { ...data, uniqueId, id: uniqueId }]);
  };
  const onRemoveWorkout = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangeWorkout(workouts.filter((wo) => wo.uniqueId !== event.currentTarget.value));
  };

  if (workouts.length === 0) {
    return (
      <>
        <div className="bg-gray-normal text-white p-2">Units({workouts.length})</div>
        <DropTarget
          className="border-2 border-dashed bg-white flex-1 mb-2 flexCenter"
          onDrop={handleDrop}
        >
          Drag workout from the left to add.
        </DropTarget>
      </>
    );
  }
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = workouts.findIndex((w) => w.uniqueId === active.id);
      const newIndex = workouts.findIndex((w) => w.uniqueId === over?.id);
      onChangeWorkout(arrayMove(workouts, oldIndex, newIndex));
    }
  };
  return (
    <>
      <div className="bg-gray-normal text-white p-2">Units({workouts.length})</div>
      <DropTarget
        className="border-2 border-dashed bg-white flex-1 mb-2 overflow-hidden"
        onDrop={handleDrop}
      >
        <div className="h-full overflow-auto p-3">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
            <SortableContext items={workouts as any} strategy={verticalListSortingStrategy}>
              {workouts.map((workout) => (
                <WorkoutItem
                  compactMode={compactMode}
                  workout={workout}
                  onRemoveWorkout={onRemoveWorkout}
                  id={workout.uniqueId}
                  key={workout.uniqueId}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </DropTarget>
    </>
  );
}

export default ProgramWorkouts;
