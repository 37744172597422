import { DumbellIcon } from 'assets/icons';
import useAppNaviagte from 'common/hooks/useAppNaviagate';

interface BlankStreamProps {
  streamId: string;
}

function BlankStream(props: BlankStreamProps) {
  const { streamId } = props;
  const { switchRoute } = useAppNaviagte();
  return (
    <div className="bg-gray-light h-full flexCenter flex-col">
      <DumbellIcon />
      <p className="mt-4">No Programs Available Yet</p>
      <button
        className="primary px-4 mt-6"
        value={`/library/streams/${streamId}/create-program`}
        onClick={switchRoute}
      >
        New Program
      </button>
    </div>
  );
}

export default BlankStream;
