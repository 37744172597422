import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthStatus = 'CHECKING_SESSION' | 'NO_SESSION' | 'LOGGED_IN';
export interface AuthState {
  status: AuthStatus;
}

const initialState: AuthState = {
  status: 'CHECKING_SESSION',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<{ status: AuthStatus }>) => {
      state.status = action.payload.status;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
