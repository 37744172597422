import { _IdNamePair } from 'common/types';
import * as Z from 'zod';

export const WorkOutDetailsFormSchema = Z.object({
  name: Z.string().trim().min(1, { message: 'Workout title is required.' }),
  is_draft: Z.boolean().default(false),
  cover_image: Z.union([Z.string().trim(), Z.instanceof(File)]),
  category: Z.string().trim().min(1, { message: 'Select workout category.' }).nullable(),
  difficulty_level: Z.enum(['novice', 'intermediate', 'advanced', 'N/A'], {
    required_error: 'Select level.',
  }),
  type: Z.enum(['quick', 'normal'], { required_error: 'Please select workout type.' }),
  description: Z.string().trim().min(1, { message: 'Add workout description.' }),
});

export const WorkOutBuildFormSchema = Z.object({
  name: Z.string().trim().min(1, { message: 'Workout title is required.' }),
  workout_duration: Z.enum(['15', '30', '45', '60']),
  exercises: Z.array(
    Z.object({
      stage: Z.enum(['activation', 'warmup', 'mainset'], {
        required_error: 'Mention exercise stage',
      }),
      workout_duration: Z.number().step(15).gte(15).lte(60),
      exercise_id: Z.string().min(1),
      exercise_name: Z.string().optional(),
      exercise_image: Z.string().optional(),
      exercise_video: Z.string().optional(),
      exercise_type: Z.enum(['bodyWeight', 'weighted']),
      unique_id: Z.string(),
      updateSyncToken: Z.string().optional(),
      sets: Z.array(
        Z.object({
          unique_id: Z.string(),
          reps: Z.number(),
          goal_rir: Z.number(),
          rm: Z.number().optional(),
          rest: Z.string(),
          reps_descriptor: Z.string(),
        })
      ),
    })
  ).min(1, { message: 'Please add some exercise' }),
});

export type WorkoutDetailsFormData = Z.infer<typeof WorkOutDetailsFormSchema>;
export type WorkoutBuildFormData = Z.infer<typeof WorkOutBuildFormSchema>;
export type WorkoutFormData = {
  details: WorkoutDetailsFormData;
  build: WorkoutBuildFormData;
};

export type WorkoutExerciseFormData = WorkoutBuildFormData['exercises'][0];
export type WorkoutFormSection = keyof WorkoutFormData;

export type WorkoutStageFormData = WorkoutBuildFormData['exercises'][0]['stage'];
export type ExerciseSetFormData = WorkoutBuildFormData['exercises'][0]['sets'][0];

export type UpdateSetValueFn = <Key extends string & keyof ExerciseSetFormData>(
  setIndex: number,
  fieldName: Key,
  fieldValue: ExerciseSetFormData[Key]
) => void;

export type ExerciseSetPayload = Omit<ExerciseSetFormData, 'rest'> & { rest: number };
export type ExercisePayload = Omit<
  WorkoutExerciseFormData,
  'exercise_name' | 'exercise_image' | 'exercise_type' | 'updateSyncToken' | 'sets'
> & {
  sets: ExerciseSetPayload[];
};
export type SaveWorkoutPayload = {
  workout: Omit<WorkoutDetailsFormData, 'cover_image'> & {
    cover_image: string | null;
    exercises: ExercisePayload[];
  };
};

export type WorkoutModel = Omit<SaveWorkoutPayload['workout'], 'exercises' | 'category'> & {
  _id: string;
  category: _IdNamePair;
  exercises: Array<
    Omit<WorkoutExerciseFormData, 'exercise_name' | 'updateSyncToken' | 'sets'> & {
      sets: ExerciseSetPayload[];
      exercise_name: string;
      exercise_image: string[];
      exercise_video: string | null;
      exercise_type: 'bodyWeight' | 'weighted';
    }
  >;
  updated_at: string;
};
