import React from 'react';
import { ReactComponent as CloseRoundedFilled } from 'assets/icons/closeRoundedFilled.svg';
import clsx from 'clsx';
import { imageMimeTypes } from 'common/constants';
import { CustomFieldEvent } from 'common/types';

import { useDropTargetConfig } from './DropTarget';

interface ImageInputFileProps {
  name: string;
  value?: File | string | null;
  rounded?: boolean;
  className?: string;
  onChange?: (event: CustomFieldEvent<File | null>) => void;
}

function ImageInputFile(props: ImageInputFileProps) {
  const { name, value, rounded = false, className, onChange } = props;
  const handleOnChange = (files: File[]) => {
    if (onChange) {
      onChange({ target: { name, value: files[0] } });
    }
  };

  const { handleDrop, handleDragOver, handleChange } = useDropTargetConfig({
    onChange: handleOnChange,
    multiple: false,
    allowedMimetypes: imageMimeTypes,
  });

  const removeFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onChange) {
      onChange({ target: { name, value: null } });
    }
  };

  const renderImage = (imageSource: string) => (
    <>
      <img
        src={imageSource}
        alt=""
        className={clsx('w-full h-full object-cover', rounded && 'rounded-full')}
      />

      <input
        type="file"
        accept={imageMimeTypes.join(',')}
        onChange={handleChange}
        className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer text-zero"
      />
      <button
        type="button"
        className="iconButton absolute -top-2 -right-2 bg-white rounded-full"
        onClick={removeFile}
      >
        <CloseRoundedFilled />
      </button>
    </>
  );

  const renderBlank = () => (
    <>
      <div className="p-4">
        <span className="mr-1">Drag and drop files here or </span>
        <div className={clsx('relative inline-block')}>
          <p className="text-purple-normal">Choose File</p>
          <input
            type="file"
            accept={imageMimeTypes.join(',')}
            onChange={handleChange}
            className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer text-zero"
          />
        </div>
      </div>
    </>
  );
  const imageSrc = React.useMemo(
    () => (value instanceof File ? URL.createObjectURL(value) : value),
    [value]
  );

  return (
    <div
      className={clsx(
        className,
        rounded && 'rounded-full',
        'relative border border-dashed border-gray-normal flexCenter text-center'
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {imageSrc ? renderImage(imageSrc) : renderBlank()}
    </div>
  );
}

export default ImageInputFile;
