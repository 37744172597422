import {
  ResourceLoaderFn,
  ResourceParams,
  useRemoteResource,
} from '@cksutils/remote-resource-toolkit';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'common/constants';
import { _IdNamePair, OptionData, ResourceHookParams } from 'common/types';
import { EMAxios } from 'common/utils/EMService';

type ProductOffering = {
  _id: OptionData<string>;
  identifier: OptionData<string>;
  display_name: OptionData<string>;

};

type ProductOptions = {
  products: OptionData<string>[];
};

const resourceLoader: ResourceLoaderFn<ProductOptions> = async (args) => {
  try {
    const { data } = await EMAxios.get('/api/admin/program/addon/products', {
      signal: args?.abortSignal,
    });
    const { products } = data?.data!;
    const productsOptions = products.map((option: ProductOffering) => ({
      id: option.identifier,
      data: option.display_name,
    }));
    return {
      success: data.success,
      data: {
        products:productsOptions,
      },
      message: data?.message,
    };
  } catch (e) {
    return { success: false };
  }
};

const useAddOnDropDownOptions = (params: ResourceHookParams<ResourceParams> = EMPTY_OBJECT) => {
  const { status, resource } = useRemoteResource<ProductOptions>({
    resourceName: 'addOnOptions',
    autoLoad: params.autoLoad,
    autoRemove: params.autoRemove,
    disconnected: params.disconnected,
    resourceLoader,
  });
  const items = JSON.parse(JSON.stringify((resource?.products || EMPTY_ARRAY)))
  items.unshift({id:null,data:"No Price"})
  return {
    isAddOnLoading: status === 'IN_PROGRESS',
    status,
    addonPurchaseIdOptions: items,
  };
};

export default useAddOnDropDownOptions;
