import React, { useState } from 'react';
import { ReactComponent as CaretDownIcon } from 'assets/icons/caretDown.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import clsx from 'clsx';
import { EMPTY_ARRAY } from 'common/constants';

import { UserData } from '../../../../modules/programs/createupdate/useUsersDropDownOptions';
import Popup, { usePopupConfig } from '../../Popup';
import { MultiSelectEvent, OptionData } from '../types';

import { MultiSelectOptions } from './MultiSelectOptions';

export interface MultiSelectProps<T> {
  containerProps?: React.HTMLProps<HTMLDivElement>;
  className?: string;
  placeHolder?: string;
  options: OptionData<T>[];
  name: string;
  values: Array<string | number>;
  onChange: (event: MultiSelectEvent) => void;
  renderItem: (param: T, isSelected: boolean) => React.ReactNode;
  renderSelected: (param: OptionData<T>[]) => React.ReactNode;
  shouldAddOption?: (param: string) => void;
  shouldShowSearch?: (searchString: string) => void;
}

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const {
    options,
    name,
    values = EMPTY_ARRAY,
    placeHolder = 'Select option...',
    containerProps,
    className,
    onChange,
    renderItem,
    renderSelected,
    shouldAddOption,
    shouldShowSearch,
  } = props;

  const [myOptions, setMyOptions] = useState(options);

  const { anchorEl, showOptions, closeOptions } = usePopupConfig<HTMLDivElement>();
  const {
    anchorEl: openPopup,
    showOptions: showInputOptions,
    closeOptions: closeInputOptions,
  } = usePopupConfig<HTMLDivElement>();
  const {
    anchorEl: openPopupSearch,
    showOptions: showInputOptionsSearch,
    closeOptions: closeInputOptionSearch,
  } = usePopupConfig<HTMLDivElement>();
  const [selectedValues, setSelectedValues] = React.useState<OptionData<T>[]>(EMPTY_ARRAY);

  React.useEffect(() => {
    setSelectedValues(options.filter((option) => values.includes(option.id)));
  }, [values, options]);

  const openDropDownMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    // if (myOptions.length > 0) {
    showOptions(event);
    openInputMenuSearch(event);
    if (shouldShowSearch !== undefined) {
      setMyOptions(sortOptions(options));
    } else {
      setMyOptions(options);
    }
    // }
  };
  const openInputMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    console.log('openInputMenu');
    showInputOptions(event);
    setTimeout(() => {
      openPopupSearch?.focus();
      window.document.getElementById('popup-add-text')?.focus();
    }, 1);
  };
  const openInputMenuSearch = (event: React.MouseEvent<HTMLDivElement>) => {
    showInputOptionsSearch(event);
    setTimeout(() => {
      openPopup?.focus();
      window.document.getElementById('popup-search-text')?.focus();
    }, 1);
  };
  const handleOptionClick = (option: OptionData<T>, isSelected: boolean) => {
    const nextSelectedValues = isSelected
      ? selectedValues.filter((data) => data.id !== option.id)
      : [...selectedValues, option];
    setSelectedValues(nextSelectedValues);
    if (onChange) {
      onChange({ target: { name, value: nextSelectedValues.map((item) => item.id) } });
    }
  };
  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      const newOption = {
        id: event.currentTarget.value as string,
        data: event.currentTarget.value as string,
      } as OptionData<T>;
      shouldAddOption && shouldAddOption(event.currentTarget.value);
      const nextSelectedValues = [...selectedValues, newOption];
      setSelectedValues(nextSelectedValues);
      onChange({ target: { name, value: nextSelectedValues.map((item) => item.id) } });
      closeInputOptions();
    }
  }
  let searchString = '';
  function handleKeyPressSearch(event: React.KeyboardEvent<HTMLInputElement>) {
    searchString = event.currentTarget.value as string;
    if (shouldShowSearch !== undefined) {
      const newOptions = options
        // @ts-ignore
        .filter((x: OptionData<UserData>) => {
          const searchFiltered = searchString.toLowerCase();
          if (x.data.firstName.toLowerCase().includes(searchFiltered)) return true;
          if (x.data.lastName.toLowerCase().includes(searchFiltered)) return true;
          if (
            `${x.data.firstName.toLowerCase()} ${x.data.lastName.toLowerCase()} (${x.data.email.toLowerCase()})`.includes(
              searchFiltered
            )
          )
            return true;
          if (x.data.email.toLowerCase().includes(searchFiltered)) return true;
          return false;
        });
      setMyOptions(sortOptions(newOptions));
    }
    if (event.key === 'Enter') {
    }
  }
  function sortOptions(optionData: OptionData<T>[]) {
    const newOptions = [...optionData];

    newOptions.sort((a, b) => {
      if (values.includes(a.id)) return -1;
      if (values.includes(b.id)) return 1;
      // @ts-ignore
      const aVal = `${a.data.firstName.toLowerCase()} ${a.data.lastName.toLowerCase()} (${a.data.email.toLowerCase()})`;
      // @ts-ignore
      const bVal = `${b.data.firstName.toLowerCase()} ${b.data.lastName.toLowerCase()} (${b.data.email.toLowerCase()})`;

      return aVal.localeCompare(bVal);
    });

    return newOptions;
  }
  function closeSearchOptions() {
    closeOptions();
    closeInputOptionSearch();
    setMyOptions(options);
  }
  function closeDropOptions() {
    closeOptions();
    closeInputOptionSearch();
    setMyOptions(options);
  }

  return (
    <>
      <div
        className={clsx('selectContainer', className)}
        onClick={openDropDownMenu}
        {...containerProps}
      >
        <div className="mr-6 overflow-hidden">
          {selectedValues?.length > 0 ? (
            renderSelected(selectedValues)
          ) : (
            <span className="text-gray-500">{placeHolder}</span>
          )}
        </div>
        <div className="absolute right-1 top-1/2 -translate-y-1/2">
          <CaretDownIcon />
        </div>
      </div>
      {shouldAddOption && (
        <div
          onClick={openInputMenu}
          className="absolute -right-8 top-1/2 -translate-y-1/2 cursor-pointer"
        >
          <PlusIcon />
        </div>
      )}

      <Popup
        anchorEl={anchorEl}
        onClose={closeDropOptions}
        className="border border-gray-light_active z-50"
        placement="bottom-start"
      >
        <MultiSelectOptions
          renderItem={renderItem}
          optionsSet={myOptions}
          selectedValues={selectedValues}
          handleOptionClick={handleOptionClick}
        />
      </Popup>
      {shouldAddOption && (
        <Popup
          anchorEl={openPopup}
          onClose={closeInputOptions}
          className="border border-gray-light_active"
          placement="bottom-start"
        >
          <input id="popup-add-text" type="text" onKeyDown={handleKeyPress} />
        </Popup>
      )}
      {shouldShowSearch !== undefined && (
        <Popup
          anchorEl={openPopupSearch}
          onClose={closeSearchOptions}
          className="border border-gray-light_active"
          placement="top-start"
        >
          <input id="popup-search-text" type="text" onKeyUp={handleKeyPressSearch} />
        </Popup>
      )}
    </>
  );
}

export default MultiSelect;
